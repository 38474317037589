import React from 'react';
import {
  People,
  Business,
  List,
  Add,
  Receipt,
  SupervisedUserCircle,
  Save,
  Queue,
  LocalAtm,
  AttachMoney,
  Money,
  HomeOutlined,
  ExitToApp,
  AccountBox,
  PermIdentity,
  Visibility,
  Search
} from '@mui/icons-material';

export interface MenuItem {
  label: string;
  icon: React.ReactElement;
  url?: string;
  items?: MenuItem[];
}

export const DASHBOARD_MENU: MenuItem = {
  label: 'Dashboard',
  icon: <HomeOutlined />,
  url: '/dashboard'
};

export const LOGOUT_MENU: MenuItem = {
  label: 'Logout',
  icon: <ExitToApp />,
  url: '/logout'
};

export const PLATFORM_MANAGER_COMPANIES_MENU: MenuItem = {
  label: 'Companies',
  icon: <Business />,
  items: [
    {
      label: 'List',
      icon: <List />,
      url: '/companies'
    },
    {
      label: 'Create',
      icon: <Add />,
      url: '/companies/create'
    }
  ]
};

export const COMPANY_MANAGER_COMPANIES_MENU: MenuItem = {
  label: 'Companies',
  icon: <Business />,
  items: [
    {
      label: 'List',
      icon: <List />,
      url: '/companies'
    }
  ]
};

export const CASH_MANAGEMENT_MENU: MenuItem = {
  label: 'Cash management',
  icon: <AttachMoney />,
  items: [
    {
      label: 'Create file',
      icon: <Save />,
      url: '/cash-management'
    },
    {
      label: 'Process response',
      icon: <Money />,
      url: '/process-cash-management'
    }
  ]
};

export const EMPLOYEES_MENU: MenuItem = {
  label: 'Employees',
  icon: <People />,
  items: [
    {
      label: 'List',
      icon: <List />,
      url: '/employees'
    },
    {
      label: 'Create',
      icon: <Add />,
      url: '/employees/create'
    },
    {
      label: 'Upload users',
      icon: <Queue />,
      url: '/bulk-upload-users'
    }
  ]
};

export const MANAGERS_MENU: MenuItem = {
  label: 'Managers',
  icon: <SupervisedUserCircle />,
  items: [
    {
      label: 'List',
      icon: <List />,
      url: '/managers'
    },
    {
      label: 'Create',
      icon: <Add />,
      url: '/managers/create'
    }
  ]
};

export const ADVANCES_MENU: MenuItem = {
  label: 'Advances',
  icon: <LocalAtm />,
  items: [
    {
      label: 'List',
      icon: <List />,
      url: '/advances'
    }
  ]
};

export const EMPLOYEE_ADVANCES_MENU: MenuItem = {
  label: 'Advances',
  icon: <LocalAtm />,
  items: [
    {
      label: 'Search',
      icon: <Search />,
      url: '/my-advances'
    },
    {
      label: 'Request',
      icon: <Add />,
      url: '/request-advance'
    }
  ]
};

export const USER_PROFILE_MENU: MenuItem = {
  label: 'Profile',
  icon: <AccountBox />,
  items: [
    {
      label: 'User profile',
      icon: <PermIdentity />,
      url: '/user/profile'
    },
    {
      label: 'Update password',
      icon: <Visibility />,
      url: '/user/update-password'
    }
  ]
};

export const IMPERSONATE_USER_MENU: MenuItem = {
  label: 'Impersonate user',
  icon: <ExitToApp />,
  url: '/impersonate-user'
};
