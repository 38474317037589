import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import { useUserInfoContext } from '../context/user-info-context';
import { ErolUserRoles } from '../definitions.d';
import Unauthorized from '../components/navigation/unauthorized';

type ProtectedRouteProps = {
  allowedRoles?: ErolUserRoles[];
  element: any;
  isRedirect?: boolean;
  route?: string;
};

export default function ProtectedRoute({
  allowedRoles,
  element,
  isRedirect,
  route
}: ProtectedRouteProps) {
  const { isAuthenticated } = useAuth();
  const { userRole } = useUserInfoContext();

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login' }} replace />;
  }

  if (allowedRoles && userRole && allowedRoles.indexOf(userRole) === -1) {
    return <Unauthorized />;
  }

  if (isRedirect) {
    return <Navigate to={{ pathname: route }} />;
  }

  return element;
}
