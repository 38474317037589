import React from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { Formik, Field, Form } from 'formik';

import useStyles from '../forms/styles';
import AutocompleteInput from '../forms/autocomplete-input';
import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import { SelectItemType } from '../../definitions.d';

const INITIAL_VALUES = {
  company: null,
  search: ''
};

const VALIDATION_SCHEMA = yup.object({
  company: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required()
    })
    .nullable(),
  search: yup.string()
});

export interface FormProps {
  company: SelectItemType | null;
  search: string;
}

interface Props {
  onSubmit: (values: FormProps) => void;
  loading?: boolean;
  companies: SelectItemType[];
}

const EmployeesListFilterForm: React.FunctionComponent<Props> = ({
  onSubmit,
  loading,
  companies
}: Props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(): React.ReactNode => {
        return (
          <Form>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10} md={8}>
                <Grid
                  container
                  spacing={3}
                  className={classes.formContainer}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={8} md={5}>
                    <Field name="search" label="Search" component={TextInput} />
                  </Grid>
                  <Grid item xs={12} sm={8} md={5}>
                    <Field
                      name="company"
                      label="Company"
                      component={AutocompleteInput}
                      items={companies}
                    />
                  </Grid>
                  <Grid item xs={6} sm={5} md={2}>
                    <SubmitButton isLoading={loading} buttonText="Search" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmployeesListFilterForm;
