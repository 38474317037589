import CreateButton from '../forms/create-button';
import CompaniesSearchInput from './companies-search-input';
import React from 'react';
import './styles/companies-list-actions.css';
export function CompaniesListActions({
  handleSearch,
  navigate
}: {
  handleSearch: (value: string) => void;
  navigate: (url: string) => void;
}): JSX.Element {
  return (
    <div className={'action-container'}>
      <CreateButton
        onClick={(): void => {
          navigate('/companies/create');
        }}
      />
      <CompaniesSearchInput onSearch={handleSearch} />
    </div>
  );
}
