import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import { GRAPHQL_URI } from '../config/constants';
import { getToken } from '../utils/auth-client';
import { onError } from '@apollo/client/link/error';
import TimeoutLink from 'apollo-link-timeout';
import { RetryLink } from '@apollo/client/link/retry';

const cache = new InMemoryCache();

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    // Reintentar la operación si hay un error de red
    return forward(operation);
  }
);
const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  }
});

const timeoutLink = new TimeoutLink(240000); // Tiempo de espera en milisegundos

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : ''
    }
  };
});

const uploadLink = createUploadLink({
  uri: GRAPHQL_URI
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    retryLink,
    timeoutLink,
    errorLink,
    uploadLink
  ]),
  cache
});

export default client;
