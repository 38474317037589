import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { saveAs } from 'file-saver';
import { get } from 'lodash';

import useHandleError from './useHandleError';

const DOWNLOAD_ADVANCES_REPORT_MUTATION = loader(
  '../graphql/downloadAdvancesReport.graphql'
);

export interface DownloadAdvancesReportProps {
  startDate?: string;
  endDate?: string;
  statuses?: string[] | null;
  companyUuids?: string[] | null;
}

interface ReportFile {
  reportUrl: string;
}

interface Response {
  data: {
    downloadAdvancesReport: ReportFile;
  };
}

interface DownloadAdvancesReportResponse {
  createReport: (values: DownloadAdvancesReportProps) => void;
  loading?: boolean;
  error?: Error;
}

const useDownloadAdvancesReportMutation =
  (): DownloadAdvancesReportResponse => {
    const { handleError } = useHandleError();

    const [mutation, { loading, error }] = useMutation(
      DOWNLOAD_ADVANCES_REPORT_MUTATION,
      {
        onCompleted: (data: Response) => {
          const url = get(data, 'downloadAdvancesReport.reportUrl', '');
          saveAs(url, 'report.csv');
        },
        onError: (e) => {
          handleError(e);
        }
      }
    );

    return {
      createReport: (values: DownloadAdvancesReportProps): void => {
        mutation({ variables: values });
      },
      error,
      loading
    };
  };

export default useDownloadAdvancesReportMutation;
