import React from 'react';
import { get, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import SimpleTable, { ColumnProps, RowProps } from '../tables/simple-table';
import { PriceRangesGroupType } from '../../definitions.d';
import usePriceRangesGroupsQuery from '../../hooks/usePriceRangesGroupsQuery';
import useDeletePriceRangesGroupMutation from '../../hooks/useDeletePriceRangesGroupMutation';
import CreateButton from '../forms/create-button';
import PageTitle from '../navigation/page-title';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'startDay', label: 'Start day' },
  { id: 'endDay', label: 'End day' },
  { id: 'chargedNextMonth', label: 'Charged next month' }
];

const useStyles = makeStyles((theme) => ({
  returnButton: {
    margin: theme.spacing(2, 0),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(50%)'
  }
}));

export const PriceRangesList: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();

  const companyUuid = pathname
    .replace('/companies/', '')
    .replace('/price-ranges', '');

  const { priceRangesGroups, loading } = usePriceRangesGroupsQuery(companyUuid);
  const deletePriceRangesGroup = useDeletePriceRangesGroupMutation(companyUuid);

  const filterResults = (groups: PriceRangesGroupType[]): RowProps[] => {
    return sortBy(
      groups.map((group) => {
        const chargedNextMonth = get(group, 'chargedNextMonth');
        return {
          uuid: get(group, 'uuid') as string,
          startDay: get(group, 'startDay'),
          endDay: get(group, 'endDay'),
          chargedNextMonth: chargedNextMonth ? t('Yes') : t('No')
        };
      }),
      ['startDay']
    );
  };

  return (
    <>
      <PageTitle title="Price ranges groups" />
      <CreateButton
        onClick={(): void => {
          navigate(`/companies/${companyUuid}/price-ranges/create`);
        }}
      />
      <SimpleTable
        columns={TABLE_COLUMNS}
        isLoading={loading}
        rows={filterResults(priceRangesGroups)}
        handleEdit={(uuid: string): void => {
          navigate(`/companies/${companyUuid}/price-ranges/${uuid}/edit`);
        }}
        handleDelete={(uuid: string): void => {
          deletePriceRangesGroup(uuid);
        }}
      />
      <Box className={classes.returnButton}>
        <Fab
          variant="extended"
          aria-label="create"
          onClick={(): void => {
            navigate(`/companies/${companyUuid}/edit`);
          }}
        >
          <ArrowBack />
          {t('Go Back')}
        </Fab>
      </Box>
    </>
  );
};

export default PriceRangesList;
