import React from 'react';
import { get } from 'lodash';

import { convertToCents, convertToDollars } from '../../utils/formatters';
import PriceRangeDialogWrapper from './price-range-dialog-wrapper';
import { usePriceRangesContext } from './price-ranges-context';
import PriceRangeEditForm from './price-range-edit-form';
import PriceRangeCreateForm from './price-range-create-form';
import {
  getValuesForEditPriceRange,
  validationSchemaForEditPriceRange,
  validationSchemaForNewPriceRange
} from './utils';

const PriceRangeEdit: React.FunctionComponent = () => {
  const {
    openEditDialog,
    setOpenEditDialog,
    priceRanges,
    editPriceRange,
    selectedPriceRange
  } = usePriceRangesContext();

  if (!selectedPriceRange) return null;

  const uuid = get(selectedPriceRange, 'uuid') as string;
  const price = get(selectedPriceRange, 'price') as number;

  const { minimumValue, maximumValue } = getValuesForEditPriceRange(
    priceRanges,
    uuid
  );

  const handleCloseDialog = (): void => {
    setOpenEditDialog(false);
  };

  return (
    <PriceRangeDialogWrapper
      title="Edit price range"
      open={openEditDialog}
      handleClose={handleCloseDialog}
    >
      {maximumValue ? (
        <PriceRangeEditForm
          initialValues={{
            minimum: convertToDollars(selectedPriceRange.rangeStart),
            cost: convertToDollars(price)
          }}
          validationSchema={validationSchemaForEditPriceRange(
            minimumValue,
            maximumValue
          )}
          onSubmit={(values): void => {
            editPriceRange({
              ...selectedPriceRange,
              rangeStart: convertToCents(values.minimum),
              price: convertToCents(values.cost)
            });
            handleCloseDialog();
          }}
        />
      ) : (
        <PriceRangeCreateForm
          initialValues={{
            minimum: convertToDollars(selectedPriceRange.rangeStart),
            maximum: convertToDollars(selectedPriceRange.rangeEnd),
            cost: convertToDollars(price)
          }}
          validationSchema={validationSchemaForNewPriceRange(minimumValue)}
          onSubmit={(values): void => {
            editPriceRange({
              ...selectedPriceRange,
              rangeStart: convertToCents(values.minimum),
              rangeEnd: convertToCents(values.maximum),
              price: convertToCents(values.cost)
            });
            handleCloseDialog();
          }}
          isEdit
        />
      )}
    </PriceRangeDialogWrapper>
  );
};

export default PriceRangeEdit;
