import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import { get, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { UserType, ErolUserRoles } from '../definitions.d';
import useMeQuery from '../hooks/useMeQuery';
import { removeToken } from '../utils/auth-client';
import useCompanyTermsAndConditionsQuery, {
  CompanyTermsAndConditionsType
} from '../hooks/useCompanyTermsAndConditions';

export interface UserInfoContextProps {
  userInfo: UserType | null;
  userRole?: ErolUserRoles;
  getUserInfo: () => void;
  resetUserInfo: () => void;
  termsAndConditions: CompanyTermsAndConditionsType | null;
  fetchTermsAndConditions: (companyUuid: string) => void;
}

export interface UserInfoProviderProps {
  children: React.ReactNode;
}

export const UserInfoContext = createContext<UserInfoContextProps>(
  {} as UserInfoContextProps
);

export const UserInfoProvider: React.FunctionComponent<
  UserInfoProviderProps
> = ({ children }: UserInfoProviderProps) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserType | null>(null);
  const [termsAndConditions, setTermsAndConditions] =
    useState<CompanyTermsAndConditionsType | null>(null);

  const { userInfo: userData, refetch, error } = useMeQuery();
  const {
    companyTermsAndConditions,
    loading: termsLoading,
    error: termsError
  } = useCompanyTermsAndConditionsQuery(get(userInfo, 'company.uuid', ''));

  const resetUserInfo = useCallback((): void => {
    setUserInfo(null);
    setTermsAndConditions(null); // Restablece los términos y condiciones también si es necesario
  }, []);

  const getUserInfo = async () => {
    setUserInfo(await refetch());
  };
  const fetchTermsAndConditions = useCallback(
    (companyUuid: string) => {
      if (companyUuid && !termsAndConditions) {
        // This function should be triggered only if terms are not already fetched
        setTermsAndConditions(companyTermsAndConditions);
      }
    },
    [companyTermsAndConditions, termsAndConditions]
  );

  useEffect(() => {
    if (error) {
      removeToken();
      resetUserInfo(); // Resetea la información del usuario al desloguearse
      navigate('/login');
    }
  }, [error, navigate, resetUserInfo]);

  useEffect(() => {
    if (userData) {
      setUserInfo(userData);
      if (!isEmpty(userData?.company)) {
        fetchTermsAndConditions(userData.company.uuid);
      }
    }
  }, [userData, navigate, fetchTermsAndConditions]);

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        userRole: get(userInfo, 'userRole'),
        getUserInfo,
        resetUserInfo,
        termsAndConditions,
        fetchTermsAndConditions
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfoContext = (): UserInfoContextProps =>
  useContext(UserInfoContext);
