import React from 'react';
import { Grid } from '@mui/material';

import { SelectItemType, CompanyBankType, BankType } from '../../definitions.d';
import CompanyBankForm, { FormValues } from './company-bank-form';
import EditCompanyBankButtons from './edit-company-bank-buttons';

export enum SelectCompanyBankActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export interface SelectCompanyBankState {
  uuid: string;
  action: SelectCompanyBankActions;
}

interface Props {
  companyBank: CompanyBankType;
  onUpdate: (values: FormValues) => void;
  onClose: () => void;
  onSelect: (uuid: string, action: SelectCompanyBankActions) => void;
  banks: SelectItemType[];
  selectedState: SelectCompanyBankState | null;
  loading?: boolean;
}

const formatBank = (bank: BankType): SelectItemType => ({
  label: bank.name,
  value: bank.uuid
});

const EditCompanyBank: React.FunctionComponent<Props> = ({
  companyBank,
  onUpdate,
  banks,
  selectedState,
  onClose,
  onSelect,
  loading
}: Props) => {
  const { uuid, bank, code } = companyBank;

  const initialValues = {
    bank: formatBank(bank),
    code
  };

  const selected = selectedState
    ? selectedState.uuid === uuid &&
      selectedState.action === SelectCompanyBankActions.EDIT
    : false;

  return (
    <>
      <CompanyBankForm
        initialValues={initialValues}
        onSubmit={onUpdate}
        onClose={onClose}
        banks={banks}
        disabled={!selected}
        loading={loading}
      />
      {selected ? null : (
        <Grid item xs={12} sm={2}>
          <EditCompanyBankButtons
            onEdit={(): void => onSelect(uuid, SelectCompanyBankActions.EDIT)}
            onRemove={(): void =>
              onSelect(uuid, SelectCompanyBankActions.DELETE)
            }
            disabled={loading}
          />
        </Grid>
      )}
    </>
  );
};

export default EditCompanyBank;
