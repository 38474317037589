import React, { useCallback, useState } from 'react';
import { debounce, get } from 'lodash';

export default function ManagersSearchInput({
  onSearch
}: {
  onSearch: (value: string) => void;
}) {
  const [inputValue, setInputValue] = useState('');

  // Función debounced para manejar la búsqueda
  const debouncedSearch = useCallback(debounce(onSearch, 500), [onSearch]);

  const handleChange = (event: unknown) => {
    setInputValue(get(event, 'target.value', ''));
    debouncedSearch(get(event, 'target.value', ''));
  };
  return (
    <input
      className={'search-input'}
      type="text"
      value={inputValue}
      onChange={handleChange}
      placeholder="Buscar..."
    />
  );
}
