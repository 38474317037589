import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { CompanyType } from '../definitions.d';

const DELETE_COMPANY_MUTATION = loader('../graphql/deleteCompany.graphql');
const COMPANIES_QUERY = loader('../graphql/companies.graphql');

const useDeleteCompanyMutation = (): ((uuid?: string) => void) => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [deleteCompany] = useMutation(DELETE_COMPANY_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return (uuid?: string): void => {
    deleteCompany({
      variables: { uuid },
      update(cache, { data: updateData }) {
        const success = get(updateData, 'deleteCompany.success');
        if (success) {
          const cachedData: {
            companies: CompanyType[];
          } | null = cache.readQuery({
            query: COMPANIES_QUERY
          });
          if (cachedData) {
            const cachedCompanies: CompanyType[] = get(cachedData, 'companies');
            cache.writeQuery({
              query: COMPANIES_QUERY,
              data: {
                companies: cachedCompanies.filter(
                  (company) => company.uuid !== uuid
                )
              }
            });
            showNotification(t('Company deleted successfully'), 'success');
          }
        } else {
          showNotification(t('Company could not be deleted'), 'warning');
        }
      }
    });
  };
};

export default useDeleteCompanyMutation;
