import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  formControl: {
    width: '100%'
  },
  submitButton: {
    margin: theme.spacing(3, 0),
    padding: '13px 25px'
  },
  checkbox: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0),
    maxWidth: '173px'
  },
  checkboxLabel: {
    margin: '0 auto'
  },
  autocompleteMultiple: {
    height: '100%'
  },
  boxCreateButton: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }
}));

export default useStyles;
