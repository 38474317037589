import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { UserTypeConnection, ChangePageArgs } from '../definitions.d';
import { PAGE_SIZE } from '../config/constants';
import { getFetchMoreVariables } from '../utils/utils';
import useHandleError from './useHandleError';

const MANAGERS_PAGED = loader('../graphql/managersPaged.graphql');

interface ManagersQueryResponse {
  managersPaged: UserTypeConnection;
  loading?: boolean;
  error?: Error;
  fetchMore: (values: ChangePageArgs | null) => void;
}

const useManagersPagedQuery = (
  searchTerm: string | null = null
): ManagersQueryResponse => {
  const { handleError } = useHandleError();

  const { data, loading, error, fetchMore } = useQuery(MANAGERS_PAGED, {
    variables: { first: PAGE_SIZE, search: searchTerm },
    onError: (e) => {
      handleError(e);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  return {
    managersPaged: get(data, 'managersPaged'),
    loading,
    error,
    fetchMore: (values: ChangePageArgs | null) => {
      fetchMore({
        variables: getFetchMoreVariables(values),
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          return fetchMoreResult;
        }
      });
    }
  };
};

export default useManagersPagedQuery;
