import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const BANK_ACCOUNTS_QUERY = loader('../graphql/bankAccounts.graphql');

export interface BankAccount {
  uuid: string;
  accountNumber: string;
  default: boolean;
  isActive: boolean;
  bank: {
    name: string;
  };
}

interface BankAccountsQueryResponse {
  bankAccounts?: BankAccount[];
  loading?: boolean;
  error?: Error;
}

const useBankAccountsQuery = (): BankAccountsQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(BANK_ACCOUNTS_QUERY, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });
  return { bankAccounts: get(data, 'bankAccounts'), loading, error };
};

export default useBankAccountsQuery;
