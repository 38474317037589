import React from 'react';

import { ErolUserRoles } from '../definitions.d';
import LoginPage from '../components/login/login';
import ChangePasswordWithToken from '../components/user/change-password-with-token';
import ForgotPassword from '../components/login/forgot-password';
import RequestUser from '../components/login/request-user';
import Logout from '../components/login/logout';
import Dashboard from '../components/dashboard/dashboard';
import CompaniesListPaged from '../components/companies/companies-list-paged';
import CompanyEdit from '../components/companies/company-edit';
import CompanyCreate from '../components/companies/company-create';
import UpdateCurrentUserForm from '../components/user/update-current-user-form';
import EmployeesList from '../components/employees/employees-list';
import EmployeeCreate from '../components/employees/employee-create';
import EmployeeUpdate from '../components/employees/employee-update';
import PriceRangesList from '../components/companies-price-ranges/price-ranges-group-list';
import ManagersList from '../components/managers/managers-list';
import ManagerCreate from '../components/managers/manager-create';
import ManagerUpdate from '../components/managers/manager-update';
import PriceRangesGroupCreate from '../components/companies-price-ranges/price-ranges-group-create';
import PriceRangesGroupEdit from '../components/companies-price-ranges/price-ranges-group-edit';
import AdvancesList from '../components/advances/advances-list';
import CashManagement from '../components/cash-management/cash-management';
import ProcessCashManagement from '../components/cash-management/process-cash-management';
import BulkUsersCreate from '../components/companies/bulk-upload-users';
import UpdateCurrentUserPassword from '../components/user/update-current-user-password';
import QueryAdvances from '../components/employee/query-advances';
import ManageCompanyBanks from '../components/companies-banks/manage-company-banks';
import RequestAdvance from '../components/employee/request-advance';
import UserProfile from '../components/user/user-profile';
import ImpersonateUser from '../components/impersonate/impersonate-user';

export interface Route {
  route: string;
  element: React.ReactNode;
  isRedirect?: boolean;
  isIndex?: boolean;
  allowedRoles?: ErolUserRoles[];
}

export const PUBLIC_ROUTES: Route[] = [
  {
    route: '/login',
    element: <LoginPage />
  },
  {
    route: '/user/invite',
    element: <ChangePasswordWithToken />
  },
  {
    route: '/user/reset-password',
    element: <ChangePasswordWithToken />
  },
  {
    route: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    route: '/request-user',
    element: <RequestUser />
  },
  {
    route: '/logout',
    element: <Logout />
  }
];

export const PROTECTED_ROUTES: Route[] = [
  {
    route: '/',
    isIndex: true,
    element: <Dashboard />
  },
  {
    route: '/dashboard',
    element: <Dashboard />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER,
      ErolUserRoles.EMPLOYEE
    ]
  },
  {
    route: '/companies',
    element: <CompaniesListPaged />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/companies/create',
    element: <CompanyCreate />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/companies/:uuid/edit',
    element: <CompanyEdit />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/companies/:uuid/price-ranges',
    element: <PriceRangesList />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/companies/:uuid/price-ranges/create',
    element: <PriceRangesGroupCreate />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/companies/:companyUuid/price-ranges/:groupUuid/edit',
    element: <PriceRangesGroupEdit />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/companies/:uuid/banks',
    element: <ManageCompanyBanks />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/user/update-user',
    element: <UpdateCurrentUserForm />
  },
  {
    route: '/user/profile',
    element: <UserProfile />
  },
  {
    route: '/user/update-password',
    element: <UpdateCurrentUserPassword />
  },
  {
    route: '/employees',
    element: <EmployeesList />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/employees/create',
    element: <EmployeeCreate />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/employees/:uuid/edit',
    element: <EmployeeUpdate />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },

  {
    route: '/managers',
    element: <ManagersList />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/managers/create',
    element: <ManagerCreate />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/managers/:uuid/edit',
    element: <ManagerUpdate />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/advances',
    element: <AdvancesList />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/cash-management',
    element: <CashManagement />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/process-cash-management',
    element: <ProcessCashManagement />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  },
  {
    route: '/bulk-upload-users',
    element: <BulkUsersCreate />,
    allowedRoles: [
      ErolUserRoles.PLATFORM_MANAGER,
      ErolUserRoles.COMPANY_MANAGER
    ]
  },
  {
    route: '/my-advances',
    element: <QueryAdvances />,
    allowedRoles: [ErolUserRoles.EMPLOYEE]
  },
  {
    route: '/request-advance',
    element: <RequestAdvance />,
    allowedRoles: [ErolUserRoles.EMPLOYEE]
  },
  {
    route: '/impersonate-user',
    element: <ImpersonateUser />,
    allowedRoles: [ErolUserRoles.PLATFORM_MANAGER]
  }
];
