import React from 'react';

import { useTranslation } from 'react-i18next';
import AlertDialog from '../dialogs/alert-dialog';
import { usePriceRangesContext } from './price-ranges-context';

const PriceRangeRemove: React.FunctionComponent = () => {
  const {
    openRemoveDialog,
    setOpenRemoveDialog,
    removePriceRange,
    selectedPriceRange
  } = usePriceRangesContext();
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={openRemoveDialog}
      title={t('Remove')}
      content={t('Are you sure you want to remove?')}
      handleClose={(): void => {
        setOpenRemoveDialog(false);
      }}
      handleConfirm={(): void => {
        if (selectedPriceRange) {
          removePriceRange(selectedPriceRange);
          setOpenRemoveDialog(false);
        }
      }}
    />
  );
};

export default PriceRangeRemove;
