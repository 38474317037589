import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { EmployeeCompanyType } from '../definitions.d';

const EMPLOYEE_COMPANIES_QUERY = loader('../graphql/employeeCompanies.graphql');

interface EmployeeCompaniesQueryResponse {
  employeeCompanies?: EmployeeCompanyType[];
  loading?: boolean;
  error?: Error;
}

const useEmployeeCompaniesQuery = (): EmployeeCompaniesQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(EMPLOYEE_COMPANIES_QUERY, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });
  return {
    employeeCompanies: get(data, 'companies'),
    loading,
    error
  };
};

export default useEmployeeCompaniesQuery;
