import React, { useEffect } from 'react';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';

import PasswordInput from '../forms/password-input';
import SubmitButton from '../forms/submit-button';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import useQueryParameters from '../../hooks/useQueryParameters';
import useUpdateUserPasswordWithTokenMutation from '../../hooks/useUpdateUserPasswordWithTokenMutation';
import { useNotificationContext } from '../../context/notification-context';
import { removeToken } from '../../utils/auth-client';
import client from '../../services/apollo-client';
import PasswordRules from './password-rules';

interface FormProps {
  password: string;
  passwordConfirm: string;
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formContainer: {
    padding: theme.spacing(1)
  }
}));

const VALIDATION_SCHEMA = yup.object({
  password: yup.string().required(REQUIRED_FIELD_TEXT),
  passwordConfirm: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf([yup.ref('password')], 'Passwords must match')
});

const INITIAL_VALUES = {
  password: '',
  passwordConfirm: ''
};

const ChangePasswordWithToken: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    removeToken();
    client.clearStore();
  }, []);

  const { showNotification } = useNotificationContext();
  const queryParameters = useQueryParameters();
  const uuid = queryParameters.get('uuid');
  const token = queryParameters.get('token');

  const { updateUserPasswordWithToken, loading, error } =
    useUpdateUserPasswordWithTokenMutation();

  useEffect(() => {
    if (!uuid || !token) {
      showNotification(t('Invalid parameters'), 'error');
    }
  }, [uuid, token, showNotification, t]);

  useEffect(() => {
    if (error) {
      showNotification(error.message, 'error');
    }
  }, [error, showNotification]);

  const onSubmit = (values: FormProps): void => {
    if (uuid && token) {
      updateUserPasswordWithToken({ ...values, uuid, token });
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Typography component="h3" variant="h5">
          {t('Update user password')}
        </Typography>
        <Container maxWidth="xs">
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(values, actions): void => {
              onSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ handleSubmit }): React.ReactNode => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} className={classes.formContainer}>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        label="Password"
                        component={PasswordInput}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="passwordConfirm"
                        label="Password confirmation"
                        component={PasswordInput}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PasswordRules />
                    </Grid>
                    <Grid item xs={12}>
                      <SubmitButton
                        isLoading={loading}
                        buttonText="Update password"
                        disabled={!uuid || !token}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Container>
      </div>
    </Container>
  );
};

export default ChangePasswordWithToken;
