import React, { FC, ImgHTMLAttributes } from 'react';
import { Grid } from '@mui/material';

import bookIcon from '../../assets/images/bookIcon.png';
import homeIcon from '../../assets/images/homeIcon.png';
import hospitalIcon from '../../assets/images/hospitalIcon.png';
import burgerIcon from '../../assets/images/burgerIcon.png';
import writeIcon from '../../assets/images/writeIcon.png';
import joystickIcon from '../../assets/images/joysticIcon.png';

import { ReasonOptions } from '../../definitions.d';
import SelectionContainer from './selection-container';
import ContinueButton from './continue-button';
import ReasonItem from './reason-item';
interface CustomIconProps extends ImgHTMLAttributes<HTMLImageElement> {
  iconSrc: string;
  name: string;
}

const CustomIcon: FC<CustomIconProps> = ({ iconSrc, name, ...props }) => {
  return <img src={iconSrc} alt={name} {...props} />;
};

const REASON_VALUES = [
  {
    text: ReasonOptions.Entertainment,
    icon: CustomIcon({ iconSrc: joystickIcon, name: 'joystick' })
  },
  {
    text: ReasonOptions.Health,
    icon: CustomIcon({ iconSrc: hospitalIcon, name: 'hospital' })
  },
  {
    text: ReasonOptions.Education,
    icon: CustomIcon({ iconSrc: bookIcon, name: 'book' })
  },
  {
    text: ReasonOptions.Food,
    icon: CustomIcon({ iconSrc: burgerIcon, name: 'burger' })
  },
  {
    text: ReasonOptions.Housing,
    icon: CustomIcon({ iconSrc: homeIcon, name: 'home' })
  }
];

interface Props {
  setSelectedValue: (reason: ReasonOptions) => void;
  selectedValue: string | null;
  handleSelectCustomValue: () => void;
  handleContinue: () => void;
}

const ReasonDefault: React.FunctionComponent<Props> = ({
  setSelectedValue,
  selectedValue,
  handleSelectCustomValue,
  handleContinue
}: Props) => {
  return (
    <Grid container spacing={1}>
      {REASON_VALUES.map((value) => (
        <Grid
          item
          xs={6}
          key={value.text}
          style={{ textAlign: 'center', display: 'ruby', marginTop: '15px' }}
        >
          <SelectionContainer
            handleClick={() => setSelectedValue(value.text)}
            selected={selectedValue === value.text}
          >
            <ReasonItem icon={value.icon} text={value.text} />
          </SelectionContainer>
        </Grid>
      ))}
      <Grid
        item
        xs={6}
        style={{ textAlign: 'center', display: 'ruby', marginTop: '15px' }}
      >
        <SelectionContainer
          handleClick={handleSelectCustomValue}
          selected={
            !!selectedValue &&
            Object.keys(ReasonOptions).indexOf(selectedValue) === -1
          }
        >
          <ReasonItem
            icon={CustomIcon({ iconSrc: writeIcon, name: 'home' })}
            text="Custom value"
          />
        </SelectionContainer>
      </Grid>
      <Grid item xs={12}>
        <ContinueButton
          disabled={!selectedValue}
          handleContinue={handleContinue}
        />
      </Grid>
    </Grid>
  );
};

export default ReasonDefault;
