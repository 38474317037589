import React, { useEffect } from 'react';

import { useRequestContext } from '../../context/request/request-context';
import {
  RequestAction,
  RequestStep,
  RequestType
} from '../../context/request/reducer';
import RequestStepWrapper from './request-step-wrapper';
import ReasonDefault from './reason-default';
import ReasonCustomForm, { FormProps } from './reason-custom-form';

interface Props {
  type: string | null;
  onUpdateRouterHistory: (step: string, type: string) => void;
}

const Reason: React.FunctionComponent<Props> = ({
  type,
  onUpdateRouterHistory
}: Props) => {
  const { state, dispatch } = useRequestContext();

  const updateHistory = (
    newStep = RequestStep.REASON,
    newType = RequestType.CUSTOM
  ) => {
    onUpdateRouterHistory(newStep, newType);
  };

  useEffect(() => {
    if (!state.amount) {
      onUpdateRouterHistory(RequestStep.AMOUNT, RequestType.DEFAULT);
    }
  }, [onUpdateRouterHistory, state.amount]);

  return (
    <RequestStepWrapper title="What is the reason?">
      {type === RequestType.CUSTOM ? (
        <ReasonCustomForm
          handleSubmit={({ reason }: FormProps) => {
            dispatch({
              type: RequestAction.SET_REASON,
              payload: reason
            });
            updateHistory(RequestStep.BANK, RequestType.DEFAULT);
          }}
        />
      ) : (
        <ReasonDefault
          selectedValue={state.reason}
          setSelectedValue={(reason: string) =>
            dispatch({ type: RequestAction.SET_REASON, payload: reason })
          }
          handleSelectCustomValue={() => {
            dispatch({ type: RequestAction.SET_REASON, payload: null });
            updateHistory();
          }}
          handleContinue={() =>
            updateHistory(RequestStep.BANK, RequestType.DEFAULT)
          }
        />
      )}
    </RequestStepWrapper>
  );
};

export default Reason;
