import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { getToken, saveToken } from '../utils/auth-client';
import useHandleError from './useHandleError';

const REFRESH_TOKEN_MUTATION = loader('../graphql/refreshToken.graphql');

interface Response {
  refreshToken: () => void;
  loading?: boolean;
  error?: Error;
}

const useRefreshTokenMutation = (): Response => {
  const { handleError } = useHandleError();

  const [mutation, { loading, error }] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (e) => {
      handleError(e);
    },
    onCompleted: (data) => {
      const token = get(data, 'refreshToken.token');
      if (token) {
        saveToken(token);
      }
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    error,
    refreshToken: (): void => {
      mutation({
        variables: { token: getToken() }
      });
    }
  };
};

export default useRefreshTokenMutation;
