import React from 'react';
import { get } from 'lodash';

import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import useBanksQuery from '../../hooks/useBanksQuery';
import useLocationsQuery from '../../hooks/useLocationsQuery';
import LoadingNotification from '../notifications/loading-notification';
import useCreateEmployeeMutation, {
  CreateEmployeeProps
} from '../../hooks/useCreateEmployeeMutation';
import PageTitle from '../navigation/page-title';
import {
  formatBanks,
  formatLocations,
  convertToCents,
  formatDate
} from '../../utils/formatters';
import { LocationTypes } from '../../definitions.d';
import EmployeeCreateForm, { FormValues } from './employee-create-form';

const formatFormValues = (values: FormValues): CreateEmployeeProps => {
  const birthDate = get(values, 'birthDate');
  return {
    availableAmount: convertToCents(get(values, 'availableAmount', '0')),
    bankAccountNumber: get(values, 'bankAccountNumber'),
    bankAccountType: get(values, 'bankAccountType'),
    bankUuid: get(values, 'bankUuid'),
    birthDate: birthDate ? formatDate(birthDate) : undefined,
    buildingNumber: get(values, 'buildingNumber'),
    cityUuid: get(values, 'city.value', '') as unknown as string,
    companyDepartment: get(values, 'companyDepartment'),
    companyUuid: get(values, 'companyUuid'),
    countryUuid: get(values, 'country.value', '') as unknown as string,
    email: get(values, 'email'),
    familyDependents: parseInt(get(values, 'familyDependents', '0'), 10),
    firstName: get(values, 'firstName'),
    idType: get(values, 'idType'),
    lastName: get(values, 'lastName'),
    mainStreet: get(values, 'mainStreet'),
    maritalStatus: get(values, 'maritalStatus'),
    neighborhoodUuid: get(values, 'neighborhoodUuid'),
    phone: get(values, 'phone'),
    salary: convertToCents(get(values, 'salary', '0')),
    secondaryStreet: get(values, 'secondaryStreet'),
    sexType: get(values, 'sexType'),
    stateUuid: get(values, 'state.value', '') as unknown as string,
    taxId: get(values, 'taxId'),
    username: get(values, 'username'),
    zipCode: get(values, 'zipCode')
  };
};

const EmployeeCreate: React.FunctionComponent = () => {
  const { companies } = useCompaniesQuery();
  const { banks } = useBanksQuery();
  const { locations } = useLocationsQuery(LocationTypes.COUNTRY);

  const {
    mutation: createEmployeeMutation,
    loading,
    fieldErrors
  } = useCreateEmployeeMutation();

  const handleCreateEmployee = (values: FormValues): void => {
    createEmployeeMutation(formatFormValues(values));
  };

  if (!companies || !banks || !locations) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Create company employee" />
      <EmployeeCreateForm
        isLoading={loading}
        companies={companies}
        banks={formatBanks(banks)}
        locations={formatLocations(locations)}
        onSubmit={handleCreateEmployee}
        fieldErrors={fieldErrors}
      />
    </>
  );
};

export default EmployeeCreate;
