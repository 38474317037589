import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import { saveToken } from '../../utils/auth-client';
import useAuth from '../../hooks/useAuth';
import { useUserInfoContext } from '../../context/user-info-context';
import useTokenAuthMutation from '../../hooks/useTokenAuthMutation';
import LoginForm, { FormValues } from './login-form';
import LoginWrapper from './login-wrapper';

const DASHBOARD_URL = '/dashboard';

const useStyles = makeStyles((theme) => ({
  forgotLink: {
    textAlign: 'center',
    color: '#00CEAD'
  },
  linkText: {
    color: '#00CEAD',
    textDecorationColor: '#00CEAD'
  }
}));

const Login: React.FunctionComponent = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();
  const { getUserInfo, userInfo } = useUserInfoContext();

  const { tokenAuth, loading } = useTokenAuthMutation();

  const [requireOtp, setRequire2fa] = useState(false);

  useEffect(() => {
    if (isAuthenticated && userInfo) {
      navigate(DASHBOARD_URL);
    }
  }, [isAuthenticated, userInfo, navigate]);

  const handleLogin = async (values: FormValues): Promise<void> => {
    const { token, require2fa } = await tokenAuth(values);

    if (require2fa) {
      setRequire2fa(true);
    }

    if (token && token !== 'invalid-token') {
      saveToken(token);
      getUserInfo();
      window.location.href = DASHBOARD_URL;
    }
  };

  const handleForgotPasswordClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    event.preventDefault();
    navigate('/forgot-password');
  };

  const handleRequestUserClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    event.preventDefault();
    navigate('/request-user');
  };

  return (
    <LoginWrapper>
      <LoginForm
        isLoading={!!loading}
        requireOtp={requireOtp}
        onSubmit={handleLogin}
      />
      <Typography className={classes.forgotLink}>
        <Link
          href="#"
          onClick={handleForgotPasswordClick}
          className={classes.linkText}
        >
          {t('Forgot you password?')}
        </Link>
      </Typography>
      <Typography className={classes.forgotLink}>
        <Link
          href="#"
          onClick={handleRequestUserClick}
          className={classes.linkText}
        >
          {t('Request your user')}
        </Link>
      </Typography>
    </LoginWrapper>
  );
};

export default Login;
