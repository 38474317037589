import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { AdvanceAvailabilityType } from '../definitions.d';
import useHandleError from './useHandleError';

const ADVANCE_AVAILABILITY_QUERY = loader(
  '../graphql/advanceAvailability.graphql'
);

interface AdvanceAvailabilityQueryResponse {
  advanceAvailability?: AdvanceAvailabilityType;
  loading?: boolean;
  error?: Error;
}

const useAdvanceAvailabilityQuery = (
  amount: number | null
): AdvanceAvailabilityQueryResponse => {
  const { handleError } = useHandleError();

  const { data, loading, error } = useQuery(ADVANCE_AVAILABILITY_QUERY, {
    variables: { amount },
    skip: !amount,
    onError: (e) => {
      handleError(e);
    },
    fetchPolicy: 'network-only'
  });

  return {
    advanceAvailability: get(data, 'advanceAvailability'),
    loading,
    error
  };
};

export default useAdvanceAvailabilityQuery;
