import React, { useEffect, ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { SelectProps } from 'formik-mui';
import { get, find } from 'lodash';

import useLocation from '../../hooks/useLocationQuery';
import { SelectItemType } from '../../definitions.d';
import useStyles from './styles';

interface SelectInputProps extends SelectProps {
  label: string;
  parentLocation: SelectItemType;
}

const LocationAutocompleteInput: React.FunctionComponent<SelectInputProps> = (
  props: SelectInputProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getLocation, locations } = useLocation();

  const initialValue = get(props, `form.initialValues.${props.field.name}`);
  const touched = get(props, `form.touched.${props.field.name}`, false);
  const error = !!get(props, `form.errors.${props.field.name}`);

  const options =
    props.parentLocation && locations
      ? locations.map((location) => ({
          value: location.uuid,
          label: location.name
        }))
      : [];

  useEffect(() => {
    if (props.parentLocation) {
      getLocation(get(props.parentLocation, 'value') as string);
    }
  }, [props.parentLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.parentLocation &&
      locations &&
      initialValue &&
      find(locations, ['uuid', get(initialValue, 'value')])
    ) {
      props.form.setFieldValue(props.field.name, initialValue);
    } else {
      props.form.setFieldValue(props.field.name, null);
    }
  }, [props.parentLocation, locations]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl className={classes.formControl}>
      {options.length ? (
        <Autocomplete
          id={`autocomplete-${props.field.name}-input`}
          onChange={(
            event: ChangeEvent<unknown>,
            value: SelectItemType | null
          ): void => {
            props.form.setFieldValue(props.field.name, value);
          }}
          options={options}
          getOptionLabel={(option: SelectItemType): string => option.label}
          isOptionEqualToValue={(
            option: SelectItemType,
            value: SelectItemType
          ): boolean => option.value === value.value}
          value={get(props, 'field.value')}
          renderInput={(params: any): React.ReactNode => (
            <TextField
              {...params}
              label={t(props.label)}
              fullWidth
              error={(error && touched) as boolean}
            />
          )}
        />
      ) : (
        <TextField label={t(props.label)} fullWidth disabled />
      )}
    </FormControl>
  );
};

export default LocationAutocompleteInput;
