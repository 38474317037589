import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface PriceRangeDialogWrapperProps {
  open: boolean;
  title: string;
  handleClose: () => void;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '450px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const PriceRangeDialogWrapper: React.FunctionComponent<
  PriceRangeDialogWrapperProps
> = ({ open, title, children, handleClose }: PriceRangeDialogWrapperProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="price-range-title"
      aria-describedby="price-range-description"
      classes={{
        paperScrollPaper: classes.dialog
      }}
      TransitionProps={{
        onEntering: (): void => {
          document.body.style.paddingRight = '0px';
        }
      }}
    >
      <DialogTitle id="price-range-title">
        {t(title)}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default PriceRangeDialogWrapper;
