import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AdvanceType } from '../../definitions.d';
import { formatDate, convertToDollars } from '../../utils/formatters';

const useStyles = makeStyles(() => ({
  tableCellLabel: {
    fontWeight: 'bold'
  }
}));

interface Props {
  advance?: AdvanceType;
  open: boolean;
  handleClose: () => void;
}

const AdvanceDetailsDialog: React.FunctionComponent<Props> = ({
  advance,
  open,
  handleClose
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const firstName = get(advance, 'erolUser.firstName');
  const lastName = get(advance, 'erolUser.lastName');

  const requestDate = get(advance, 'requestDate');
  const processDate = get(advance, 'processDate');

  const rows = [
    { label: 'Identifier', value: get(advance, 'identifier') },
    {
      label: 'Company',
      value: get(advance, 'erolUser.company.commercialName')
    },
    { label: 'User', value: `${firstName} ${lastName}` },
    { label: 'Status', value: t(get(advance, 'status') || '') },
    {
      label: 'Request date',
      value: requestDate ? formatDate(requestDate) : ''
    },
    {
      label: 'Process date',
      value: processDate ? formatDate(processDate) : ''
    },
    {
      label: 'Origin account type',
      value: get(advance, 'originBankAccount.accountType')
    },
    {
      label: 'Origin bank name',
      value: get(advance, 'originBankAccount.bank.name')
    },
    {
      label: 'Origin account number',
      value: get(advance, 'originBankAccount.accountNumber')
    },
    {
      label: 'Destination account type',
      value: get(advance, 'bankAccountType')
    },
    {
      label: 'Destination bank name',
      value: get(advance, 'bank.name')
    },
    {
      label: 'Destination account number',
      value: get(advance, 'bankAccountNumber')
    },
    {
      label: 'Platform fee',
      value: `$${convertToDollars(get(advance, 'platformFee', 0))}`
    },
    {
      label: 'Service fee',
      value: `$${convertToDollars(get(advance, 'serviceFee', 0))}`
    },
    { label: 'Tax', value: `$${convertToDollars(get(advance, 'tax', 0))}` },
    { label: 'Price', value: `$${convertToDollars(get(advance, 'price', 0))}` },
    {
      label: 'Transfer fee',
      value: `$${convertToDollars(get(advance, 'transferFee', 0))}`
    },
    {
      label: 'Requested amount',
      value: `$${convertToDollars(get(advance, 'advanceAmount', 0))}`
    },
    {
      label: 'Effective transfer amount',
      value: `$${convertToDollars(get(advance, 'effectiveTransferAmount', 0))}`
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="advance-details"
      aria-describedby="detailed-view-of-an-advance"
    >
      <DialogTitle id="advance-details">{t('Advance details')}</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table aria-label="advance-details-table">
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableCellLabel}
                  >
                    {t(row.label)}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvanceDetailsDialog;
