import React from 'react';
import { get } from 'lodash';

import useLocationsQuery from '../../hooks/useLocationsQuery';
import useIndustrySegmentsQuery from '../../hooks/useIndustrySegmentsQuery';
import LoadingNotification from '../notifications/loading-notification';
import useCreateCompanyMutation, {
  CreateCompanyProps
} from '../../hooks/useCreateCompanyMutation';
import PageTitle from '../navigation/page-title';
import { LocationTypes } from '../../definitions.d';
import {
  formatIndustrySegments,
  formatLocations,
  convertToCents
} from '../../utils/formatters';
import CompanyForm, { FormValues } from './company-form';

const formatCompany = (company: FormValues): CreateCompanyProps => {
  return {
    buildingNumber: get(company, 'buildingNumber'),
    cityUuid: get(company, 'city.value', '') as string,
    commercialName: get(company, 'commercialName'),
    contractEndDate: get(company, 'contractEndDate'),
    contractStartDate: get(company, 'contractStartDate'),
    payDay: get(company, 'payDay', 1),
    countryUuid: get(company, 'country.value', '') as string,
    echekExecutive: get(company, 'echekExecutive'),
    hasApiConnection: get(company, 'hasApiConnection'),
    identifier: get(company, 'identifier'),
    industrySegmentUuid: get(company, 'industrySegmentUuid'),
    isAutoRenew: get(company, 'isAutoRenew'),
    legalName: get(company, 'legalName'),
    mainStreet: get(company, 'mainStreet'),
    maxWithdrawalPercent: parseInt(
      get(company, 'maxWithdrawalPercent', '0'),
      10
    ),
    maxWithdrawalsPerMonth: parseInt(
      get(company, 'maxWithdrawalsPerMonth', '0'),
      10
    ),
    waitingDays: parseInt(get(company, 'waitingDays', '0'), 10),
    minMonths: parseInt(get(company, 'minMonths', '0'), 10),
    minWithdrawalAmount: parseInt(get(company, 'minWithdrawalAmount', '0'), 10),
    neighborhoodUuid: get(company, 'neighborhood.value', '') as string,
    onDemandApi: get(company, 'onDemandApi', false),
    onDemandApiAuthUrl: get(company, 'onDemandApiAuthUrl'),
    onDemandApiInfoUrl: get(company, 'onDemandApiInfoUrl'),
    onDemandApiUser: get(company, 'onDemandApiUser'),
    onDemandApiPassword: get(company, 'onDemandApiPassword'),
    onDemandApiCompanyCode: get(company, 'onDemandApiCompanyCode'),
    platformFee: convertToCents(get(company, 'platformFee', '0')),
    require2fa: get(company, 'require2fa'),
    secondaryStreet: get(company, 'secondaryStreet'),
    serviceFee: convertToCents(get(company, 'serviceFee', '0')),
    stateUuid: get(company, 'state.value', '') as string,
    taxId: get(company, 'taxId'),
    taxPercent: parseInt(get(company, 'taxPercent', '15')),
    transferFee: convertToCents(get(company, 'transferFee', '0')),
    zipCode: get(company, 'zipCode')
  };
};

const CompanyCreate: React.FunctionComponent = () => {
  const { locations } = useLocationsQuery(LocationTypes.COUNTRY);
  const { industrySegments } = useIndustrySegmentsQuery();
  const { createCompany, loading } = useCreateCompanyMutation();

  if (!industrySegments || !locations) {
    return <LoadingNotification />;
  }

  const initialValues: FormValues = {
    commercialName: '',
    contractEndDate: new Date(
      new Date(Date.now()).setFullYear(new Date().getFullYear() + 1)
    ).toISOString(),
    contractStartDate: new Date(Date.now()).toISOString(),
    payDay: 1,
    industrySegmentUuid: '',
    legalName: '',
    identifier: '',
    taxId: '',
    mainStreet: '',
    secondaryStreet: '',
    buildingNumber: '',
    zipCode: '',
    isAutoRenew: false,
    hasApiConnection: false,
    require2fa: false,
    country: null,
    echekExecutive: '',
    onDemandApi: false,
    onDemandApiAuthUrl: '',
    onDemandApiInfoUrl: '',
    onDemandApiUser: '',
    onDemandApiPassword: '',
    onDemandApiCompanyCode: '',
    state: null,
    city: null,
    neighborhood: null
  };

  return (
    <>
      <PageTitle title="Create company" />
      <CompanyForm
        initialValues={initialValues}
        locations={formatLocations(locations)}
        industrySegments={formatIndustrySegments(industrySegments)}
        onSubmit={(values: FormValues): void => {
          createCompany(formatCompany(values));
        }}
        isCreateForm
        isLoading={loading}
      />
    </>
  );
};

export default CompanyCreate;
