import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import useUserQuery from '../../hooks/useUserQuery';
import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import useBanksQuery from '../../hooks/useBanksQuery';
import useLocationsQuery from '../../hooks/useLocationsQuery';
import LoadingNotification from '../notifications/loading-notification';
import {
  UserType,
  LocationTypes,
  ErolUserMaritalStatusTypes
} from '../../definitions.d';
import useUserPasswordResetByEmailMutation from '../../hooks/useUserPasswordResetByEmailMutation';
import useUpdateEmployeeMutation, {
  UpdateEmployeeProps
} from '../../hooks/useUpdateEmployeeMutation';
import PageTitle from '../navigation/page-title';
import {
  formatBanks,
  convertToDollars,
  formatLocations,
  convertToCents,
  formatLocation,
  formatDate
} from '../../utils/formatters';
import EmployeeUpdateForm, { FormValues } from './employee-update-form';

const getUserUuid = (pathname: string): string => {
  const partialResult = pathname.replace('/employees/', '');
  return partialResult.replace('/edit', '');
};

const getInitialValues = (employee: UserType): FormValues => {
  const country = get(employee, 'country');
  const state = get(employee, 'state');
  const city = get(employee, 'city');
  const neighborhood = get(employee, 'neighborhood');
  const nextAdvanceDate = get(employee, 'nextAdvanceDate');

  return {
    availableAmount: convertToDollars(get(employee, 'availableAmount') || 0),
    username: get(employee, 'username') as string,
    bankAccountNumber: get(employee, 'bankAccountNumber'),
    bankAccountType: get(employee, 'bankAccountType'),
    bankUuid: get(employee, 'bank.uuid'),
    birthDate: get(employee, 'birthDate'),
    buildingNumber: get(employee, 'buildingNumber'),
    city: city ? formatLocation(city) : null,
    companyUuid: get(employee, 'company.uuid'),
    country: country ? formatLocation(country) : null,
    email: get(employee, 'email'),
    familyDependents: `${get(employee, 'familyDependents') || 0}`,
    firstName: get(employee, 'firstName', ''),
    idType: get(employee, 'idType'),
    lastName: get(employee, 'lastName', ''),
    mainStreet: get(employee, 'mainStreet'),
    maritalStatus: get(employee, 'maritalStatus') || null,
    neighborhood: neighborhood ? formatLocation(neighborhood) : null,
    phone: get(employee, 'phone', ''),
    salary: convertToDollars(get(employee, 'salary', 0)),
    secondaryStreet: get(employee, 'secondaryStreet'),
    sexType: get(employee, 'sexType'),
    state: state ? formatLocation(state) : null,
    taxId: get(employee, 'taxId'),
    zipCode: get(employee, 'zipCode'),
    companyDepartment: get(employee, 'companyDepartment'),
    nextAdvanceDate: nextAdvanceDate ? formatDate(nextAdvanceDate) : ' ',
    informativeMessage: get(employee, 'informativeMessage')
  };
};

const formatFormValues = (
  values: FormValues
): Omit<UpdateEmployeeProps, 'uuid'> => {
  const birthDate = get(values, 'birthDate');

  return {
    availableAmount: parseInt(get(values, 'availableAmount', '0'), 10),
    bankAccountNumber: get(values, 'bankAccountNumber'),
    bankAccountType: get(values, 'bankAccountType'),
    bankUuid: get(values, 'bankUuid'),
    birthDate: birthDate ? formatDate(birthDate) : undefined,
    buildingNumber: get(values, 'buildingNumber') as unknown as string,
    cityUuid: get(values, 'city.value') as unknown as string,
    companyDepartment: get(values, 'companyDepartment'),
    companyUuid: get(values, 'companyUuid') as unknown as string,
    countryUuid: get(values, 'country.value') as unknown as string,
    email: get(values, 'email'),
    familyDependents: parseInt(get(values, 'familyDependents', '0'), 10),
    firstName: get(values, 'firstName'),
    idType: get(values, 'idType'),
    lastName: get(values, 'lastName'),
    mainStreet: get(values, 'mainStreet'),
    maritalStatus: get(values, 'maritalStatus') as ErolUserMaritalStatusTypes,
    neighborhoodUuid: get(values, 'neighborhood.value') as unknown as string,
    phone: get(values, 'phone'),
    salary: convertToCents(get(values, 'salary', '0')),
    secondaryStreet: get(values, 'secondaryStreet'),
    sexType: get(values, 'sexType'),
    stateUuid: get(values, 'state.value') as unknown as string,
    taxId: get(values, 'taxId'),
    zipCode: get(values, 'zipCode')
  };
};

const EmployeeUpdate: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const uuid = getUserUuid(location.pathname);
  const {
    user: employee,
    loading: employeeDataLoading,
    error
  } = useUserQuery(uuid);
  const { companies } = useCompaniesQuery();
  const { banks } = useBanksQuery();
  const { locations } = useLocationsQuery(LocationTypes.COUNTRY);

  const { updateEmployee, loading: updateEmployeeLoading } =
    useUpdateEmployeeMutation();

  const { sendEmail, loading: sendEmailLoading } =
    useUserPasswordResetByEmailMutation();

  useEffect(() => {
    if (error) {
      navigate('/employees');
    }
  }, [error, history]);

  if (!employee || !companies || !banks || !locations) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Edit employee" />
      <EmployeeUpdateForm
        initialValues={getInitialValues(employee)}
        isLoading={
          employeeDataLoading || sendEmailLoading || updateEmployeeLoading
        }
        companies={companies}
        banks={formatBanks(banks)}
        locations={formatLocations(locations)}
        onSubmit={(values): void => {
          updateEmployee({ uuid, ...formatFormValues(values) });
        }}
        onPasswordReset={(email: string): void => {
          sendEmail({ email });
        }}
      />
    </>
  );
};

export default EmployeeUpdate;
