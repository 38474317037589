import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/SaveAlt';

import useCreateCashManagementFileMutation from '../../hooks/useCreateCashManagementFileMutation';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import LoadingNotification from '../notifications/loading-notification';

const VALIDATION_SCHEMA = yup.object({
  identifier: yup.string().min(3).required(REQUIRED_FIELD_TEXT)
});

interface Props {
  open: boolean;
  handleClose: () => void;
  bankAccountUuid: string;
  advanceUuids: string[];
}

const CreateFileDialog: React.FunctionComponent<Props> = ({
  open,
  handleClose,
  bankAccountUuid,
  advanceUuids
}: Props) => {
  const { t } = useTranslation();

  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [identifier, setIdentifier] = useState<string>('');

  const { createCashManagementFile, cashManagementFiles, loading } =
    useCreateCashManagementFileMutation();

  useEffect(() => {
    if (open) {
      setIdentifier('');
      setShowFiles(false);
    }
  }, [open]);

  const handleCreateFiles = (): void => {
    setShowFiles(true);
    createCashManagementFile({
      bankAccountUuid,
      advanceUuids,
      identifier
    });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
        setIdentifier(event.target.value);
      }}
    >
      <DialogTitle id="scroll-dialog-title">{t('Create files')}</DialogTitle>
      <DialogContent dividers>
        {showFiles ? (
          <>
            {loading ? <LoadingNotification /> : null}
            <List dense>
              {cashManagementFiles.map((file, index) => (
                <ListItem key={file.url}>
                  <IconButton
                    href={file.url}
                    download={file.identifier}
                    target="_blank"
                    size="large"
                  >
                    <SaveIcon />
                  </IconButton>
                  <ListItemText
                    primary={`${t('File')} ${index + 1}`}
                    secondary={file.identifier}
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              id="identifier"
              label={t('Identifier')}
              type="text"
              fullWidth
            />
            <Button
              onClick={handleCreateFiles}
              color="primary"
              variant="contained"
              data-testid="create-files-button"
              disabled={!VALIDATION_SCHEMA.isValidSync({ identifier })}
            >
              {t('Create')}
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} data-testid="close-button">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFileDialog;
