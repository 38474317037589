import React from 'react';
import { get } from 'lodash';

import { useUserInfoContext } from '../../context/user-info-context';
import useUpdateCurrentUserMutation, {
  UpdateCurrentUserProps
} from '../../hooks/useUpdateCurrentUserMutation';
import PageTitle from '../navigation/page-title';
import useLocationsQuery from '../../hooks/useLocationsQuery';
import { LocationTypes, UserType } from '../../definitions.d';
import {
  formatLocations,
  formatLocation,
  formatDate
} from '../../utils/formatters';
import LoadingNotification from '../notifications/loading-notification';
import UserProfileForm, { FormValues } from './user-profile-form';

const getInitialValues = (userInfo: UserType): FormValues => {
  const country = get(userInfo, 'country');
  const state = get(userInfo, 'state');
  const city = get(userInfo, 'city');
  const neighborhood = get(userInfo, 'neighborhood');

  return {
    birthDate: get(userInfo, 'birthDate'),
    buildingNumber: get(userInfo, 'buildingNumber'),
    city: city ? formatLocation(city) : null,
    companyDepartment: get(userInfo, 'companyDepartment'),
    country: country ? formatLocation(country) : null,
    firstName: get(userInfo, 'firstName'),
    lastName: get(userInfo, 'lastName'),
    mainStreet: get(userInfo, 'mainStreet'),
    maritalStatus: get(userInfo, 'maritalStatus'),
    neighborhood: neighborhood ? formatLocation(neighborhood) : null,
    secondaryStreet: get(userInfo, 'secondaryStreet'),
    sexType: get(userInfo, 'sexType'),
    state: state ? formatLocation(state) : null,
    zipCode: get(userInfo, 'zipCode')
  };
};

const formatFormValues = (values: FormValues): UpdateCurrentUserProps => {
  const birthDate = get(values, 'birthDate');

  return {
    birthDate: birthDate ? formatDate(birthDate) : undefined,
    buildingNumber: get(values, 'buildingNumber'),
    cityUuid: get(values, 'city.value') as unknown as string,
    companyDepartment: get(values, 'companyDepartment'),
    countryUuid: get(values, 'country.value') as unknown as string,
    firstName: get(values, 'firstName'),
    lastName: get(values, 'lastName'),
    mainStreet: get(values, 'mainStreet'),
    maritalStatus: get(values, 'maritalStatus'),
    neighborhoodUuid: get(values, 'neighborhood.value') as unknown as string,
    secondaryStreet: get(values, 'secondaryStreet'),
    sexType: get(values, 'sexType'),
    stateUuid: get(values, 'state.value') as unknown as string,
    zipCode: get(values, 'zipCode')
  };
};

const UpdateCurrentUserForm: React.FunctionComponent = () => {
  const { userInfo } = useUserInfoContext();
  const { locations } = useLocationsQuery(LocationTypes.COUNTRY);

  const { loading, updateCurrentUser } = useUpdateCurrentUserMutation();

  const handleSubmit = (values: FormValues): void => {
    updateCurrentUser(formatFormValues(values));
  };

  if (!userInfo || !locations) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="User profile" />
      <UserProfileForm
        initialValues={getInitialValues(userInfo)}
        onSubmit={handleSubmit}
        isLoading={loading}
        locations={formatLocations(locations)}
      />
    </>
  );
};

export default UpdateCurrentUserForm;
