import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { get, sortBy } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import LoadingNotification from '../notifications/loading-notification';
import PageTitle from '../navigation/page-title';
import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import useBulkCreateUserMutation, {
  BulkOperationsUserRejectProps
} from '../../hooks/useBulkCreateUserMutation';
import SimpleTable, { ColumnProps, RowProps } from '../tables/simple-table';
import { UserType } from '../../definitions.d';
import BulkUsersFilterForm, {
  FormProps
} from './bulk-upload-users-filter-form';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    padding: theme.spacing(1)
  }
}));

export type ColumnTypes =
  | 'username'
  | 'fullName'
  | 'company'
  | 'email'
  | 'taxId'
  | 'confirmed';

export const USERS_TABLE_COLUMNS: ColumnProps[] = [
  { id: 'fullName', label: 'Full Name' },
  { id: 'email', label: 'Email' },
  { id: 'taxId', label: 'ID Card' }
];

export const ERRORS_TABLE_COLUMNS: ColumnProps[] = [
  { id: 'rowNumber', label: 'Row number' },
  { id: 'taxId', label: 'ID Card' },
  { id: 'rowReason', label: 'Row reason' }
];

const formatErrors = (errors: BulkOperationsUserRejectProps[]): RowProps[] => {
  return errors.map((error) => {
    return {
      uuid: `${get(error, 'rowNumber')}`,
      rowNumber: get(error, 'rowNumber'),
      taxId: get(error, 'rowData[1]'),
      rowReason: get(error, 'rowReason') || ''
    };
  });
};

const filterUserResults = (
  employees: UserType[],
  columns: ColumnProps[]
): RowProps[] => {
  return sortBy(
    employees.map((user) => {
      return columns.reduce(
        (filteredColumns, column) => {
          const id = get(column, 'id') as ColumnTypes;
          switch (id) {
            case 'fullName':
              filteredColumns[id] = `${user.firstName} ${user.lastName}`;
              break;
            case 'company':
              if (user.company) {
                filteredColumns[id] = `${user.company.commercialName}`;
              } else {
                filteredColumns[id] = 'N/A';
              }
              break;
            case 'confirmed':
              filteredColumns[id] = user.confirmed ? (
                <CheckIcon color="primary" />
              ) : (
                <NotInterestedIcon color="action" />
              );
              break;
            default:
              filteredColumns[id] = user[id];
              break;
          }

          return filteredColumns;
        },
        { uuid: user.uuid } as RowProps
      );
    }),
    ['fullName']
  );
};

const BulkUploadUsers: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { companies } = useCompaniesQuery();

  const { createUsers, users, ignored, failed, loading } =
    useBulkCreateUserMutation();

  const handleSubmit = (values: FormProps): void => {
    createUsers({
      companyUuid: get(values, 'company.value', '') as string,
      file: get(values, 'file')
    });
  };

  if (!companies) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Bulk upload users" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <BulkUsersFilterForm
            companies={companies}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </Grid>
      </Grid>
      {Array.isArray(users) && users.length ? (
        <>
          <Typography variant="h6" className={classes.subtitle}>
            {t('Created')}
          </Typography>
          <SimpleTable
            columns={USERS_TABLE_COLUMNS}
            rows={filterUserResults(users, USERS_TABLE_COLUMNS)}
          />
        </>
      ) : null}
      {Array.isArray(ignored) && ignored.length ? (
        <>
          <Typography variant="h6" className={classes.subtitle}>
            {t('Ignored')}
          </Typography>
          <SimpleTable
            columns={ERRORS_TABLE_COLUMNS}
            rows={formatErrors(ignored)}
          />
        </>
      ) : null}
      {Array.isArray(failed) && failed.length ? (
        <>
          <Typography variant="h6" className={classes.subtitle}>
            {t('Errors')}
          </Typography>
          <SimpleTable
            columns={ERRORS_TABLE_COLUMNS}
            rows={formatErrors(failed)}
          />
        </>
      ) : null}
    </>
  );
};

export default BulkUploadUsers;
