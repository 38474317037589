import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import useCompanyQuery from '../../hooks/useCompanyQuery';
import useLocationsQuery from '../../hooks/useLocationsQuery';
import useIndustrySegmentsQuery from '../../hooks/useIndustrySegmentsQuery';
import LoadingNotification from '../notifications/loading-notification';
import { CompanyType, LocationTypes } from '../../definitions.d';
import useUpdateCompanyMutation, {
  UpdateCompanyProps
} from '../../hooks/useUpdateCompanyMutation';
import PageTitle from '../navigation/page-title';
import {
  convertToDollars,
  convertFloatToString,
  formatIndustrySegments,
  formatLocation,
  formatLocations,
  convertToCents
} from '../../utils/formatters';
import CompanyForm, { FormValues, CompanyManager } from './company-form';

interface CompanyManagerInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const getCompanyUuid = (pathname: string): string => {
  const partialResult = pathname.replace('/companies/', '');
  return partialResult.replace('/edit', '');
};

const getInitialValues = (company: CompanyType): FormValues => {
  const country = get(company, 'country');
  const state = get(company, 'state');
  const city = get(company, 'city');
  const neighborhood = get(company, 'neighborhood');

  return {
    buildingNumber: get(company, 'buildingNumber'),
    city: city ? formatLocation(get(company, 'city')) : null,
    commercialName: get(company, 'commercialName'),
    contract: null,
    contractEndDate: get(company, 'contractEndDate'),
    contractStartDate: get(company, 'contractStartDate'),
    payDay: get(company, 'payDay', 1),
    country: country ? formatLocation(get(company, 'country')) : null,
    echekExecutive: get(company, 'echekExecutive'),
    hasApiConnection: get(company, 'hasApiConnection', false),
    identifier: get(company, 'identifier'),
    industrySegmentUuid: get(company, 'industrySegment.uuid'),
    isAutoRenew: get(company, 'isAutoRenew', false),
    legalName: get(company, 'legalName'),
    mainStreet: get(company, 'mainStreet'),
    maxWithdrawalPercent: `${get(company, 'maxWithdrawalPercent')}`,
    maxWithdrawalsPerMonth: `${get(company, 'maxWithdrawalsPerMonth')}`,
    waitingDays: `${get(company, 'waitingDays', 0)}`,
    minMonths: `${get(company, 'minMonths')}`,
    minWithdrawalAmount: `${get(company, 'minWithdrawalAmount')}`,
    neighborhood: neighborhood
      ? formatLocation(get(company, 'neighborhood'))
      : null,
    onDemandApi: get(company, 'onDemandApi', false),
    onDemandApiAuthUrl: get(company, 'onDemandApiAuthUrl'),
    onDemandApiInfoUrl: get(company, 'onDemandApiInfoUrl'),
    onDemandApiUser: get(company, 'onDemandApiUser'),
    onDemandApiPassword: get(company, 'onDemandApiPassword'),
    onDemandApiCompanyCode: get(company, 'onDemandApiCompanyCode'),
    platformFee: convertToDollars(get(company, 'platformFee', 0)),
    require2fa: get(company, 'require2fa', false),
    secondaryStreet: get(company, 'secondaryStreet'),
    serviceFee: convertToDollars(get(company, 'serviceFee', 0)),
    state: state ? formatLocation(get(company, 'state')) : null,
    taxId: get(company, 'taxId'),
    taxPercent: convertFloatToString(get(company, 'taxPercent', 0)),
    transferFee: convertToDollars(get(company, 'transferFee', 0)),
    zipCode: get(company, 'zipCode'),
    uuid: get(company, 'uuid')
  };
};

const formatCompany = (
  company: FormValues
): Omit<UpdateCompanyProps, 'uuid'> => {
  const contract = get(company, 'contract');
  return {
    buildingNumber: get(company, 'buildingNumber'),
    cityUuid: get(company, 'city.value') as unknown as string,
    commercialName: get(company, 'commercialName'),
    contract: contract ? contract : undefined,
    contractEndDate: get(company, 'contractEndDate'),
    contractStartDate: get(company, 'contractStartDate'),
    payDay: get(company, 'payDay', 1),
    countryUuid: get(company, 'country.value') as unknown as string,
    echekExecutive: get(company, 'echekExecutive'),
    hasApiConnection: get(company, 'hasApiConnection'),
    industrySegmentUuid: get(company, 'industrySegmentUuid'),
    isAutoRenew: get(company, 'isAutoRenew'),
    legalName: get(company, 'legalName'),
    mainStreet: get(company, 'mainStreet'),
    maxWithdrawalPercent: parseInt(
      get(company, 'maxWithdrawalPercent', '0'),
      10
    ),
    maxWithdrawalsPerMonth: parseInt(
      get(company, 'maxWithdrawalsPerMonth', '0'),
      10
    ),
    waitingDays: parseInt(get(company, 'waitingDays', '0'), 10),
    minMonths: parseInt(get(company, 'minMonths', '0'), 10),
    minWithdrawalAmount: parseInt(get(company, 'minWithdrawalAmount', '0'), 10),
    neighborhoodUuid: get(company, 'neighborhood.value') as unknown as string,
    onDemandApi: get(company, 'onDemandApi'),
    onDemandApiAuthUrl: get(company, 'onDemandApiAuthUrl'),
    onDemandApiInfoUrl: get(company, 'onDemandApiInfoUrl'),
    onDemandApiUser: get(company, 'onDemandApiUser'),
    onDemandApiPassword: get(company, 'onDemandApiPassword'),
    onDemandApiCompanyCode: get(company, 'onDemandApiCompanyCode'),
    platformFee: convertToCents(get(company, 'platformFee', '0')),
    require2fa: get(company, 'require2fa'),
    secondaryStreet: get(company, 'secondaryStreet'),
    serviceFee: convertToCents(get(company, 'serviceFee', '0')),
    stateUuid: get(company, 'state.value') as unknown as string,
    taxId: get(company, 'taxId'),
    taxPercent: parseInt(get(company, 'taxPercent', '0'), 10),
    transferFee: convertToCents(get(company, 'transferFee', '0')),
    zipCode: get(company, 'zipCode')
  };
};

const formatManagers = (managers: CompanyManagerInput[]): CompanyManager[] =>
  managers.map((manager) => ({
    contactName: `${get(manager, 'firstName')} ${get(manager, 'lastName')}`,
    contactEmail: get(manager, 'email'),
    contactPhone: get(manager, 'phone')
  }));

const CompanyEdit: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const uuid = getCompanyUuid(location.pathname);
  const { company, error } = useCompanyQuery(uuid);
  const { locations } = useLocationsQuery(LocationTypes.COUNTRY);
  const { industrySegments } = useIndustrySegmentsQuery();
  const { updateCompany, loading } = useUpdateCompanyMutation();

  useEffect(() => {
    if (error) {
      navigate('/companies');
    }
  }, [error, history]);

  if (!company || !locations || !industrySegments) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Edit company" />
      <CompanyForm
        initialValues={getInitialValues(company)}
        locations={formatLocations(locations)}
        industrySegments={formatIndustrySegments(industrySegments)}
        onSubmit={(values: FormValues): void => {
          updateCompany({ uuid, ...formatCompany(values) });
        }}
        companyUuid={uuid}
        managers={formatManagers(get(company, 'managers') || [])}
        isLoading={loading}
        isCreateForm={false}
      />
    </>
  );
};

export default CompanyEdit;
