import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0),
    padding: '13px 25px',
    display: 'flex', // Use flex layout
    justifyContent: 'center', // Center the items horizontally
    alignItems: 'center' // Center the items vertically
  },
  progress: {
    color: '#fff',
    marginRight: theme.spacing(1) // Add some space to the right of the spinner
  },
  buttonText: {
    flexGrow: 1 // This makes the text take up the available space
  }
}));

interface Props {
  isLoading?: boolean;
  buttonText: string;
  disabled?: boolean;
  size?: 'medium' | 'small';
}

const SubmitButton: React.FunctionComponent<Props> = ({
  isLoading,
  buttonText,
  disabled,
  size
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.button}
      disabled={isLoading || disabled}
      data-testid="submit-button"
      size={size}
    >
      {isLoading ? (
        <CircularProgress size={24} className={classes.progress} />
      ) : (
        <span className={classes.buttonText}>{t(buttonText)}</span>
      )}
    </Button>
  );
};

export default SubmitButton;
