import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  flagContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  flag: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.error.main}`,
    padding: '8px 14px 4px',
    borderRadius: '30px',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%'
    }
  },
  flagText: {
    color: theme.palette.error.main
  }
}));

interface Props {
  title: string;
  key: number;
}

const Flag: React.FunctionComponent<Props> = ({ title, key }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div key={key} className={classes.flagContainer}>
      <div className={classes.flag}>
        <Typography className={classes.flagText}>{t(title)}</Typography>
      </div>
    </div>
  );
};

export default Flag;
