import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    };

    let id: ReturnType<typeof setTimeout>;
    if (delay > 0) {
      id = setInterval(tick, delay);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [delay]);
};

export default useInterval;
