import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { LocationType } from '../definitions.d';

const LOCATION_QUERY = loader('../graphql/location.graphql');

interface LocationQueryResponse {
  locations?: LocationType[];
  loading?: boolean;
  error?: Error;
  getLocation: (uuid: string) => void;
}

const useLocationQuery = (): LocationQueryResponse => {
  const { showNotification } = useNotificationContext();
  const [executeQuery, { data, loading, error }] = useLazyQuery(
    LOCATION_QUERY,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      fetchPolicy: 'network-only'
    }
  );

  return {
    getLocation: (uuid: string): void => {
      executeQuery({ variables: { uuid } });
    },
    locations: get(data, 'location.children'),
    loading,
    error
  };
};

export default useLocationQuery;
