import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SelectionContainer from './selection-container';
import ContinueButton from './continue-button';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const DEFAULT_VALUES = [5000, 10000, 15000, 20000, 25000, 30000];

interface Props {
  setSelectedValue: (value: number) => void;
  selectedValue: number | null;
  handleSelectCustomValue: () => void;
  handleContinue: () => void;
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: 'var(--Neutral-600, #374151)'
  },
  textSelected: {
    fontSize: '16px',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: 'white'
  },
  customValueButton: {
    width: '100%',
    border: 'none',
    background: 'none',
    marginTop: '15px'
  },
  customValueText: {
    fontSize: '16px',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#00CEAD',
    padding: '0',
    margin: '0',
    textDecoration: 'underline'
  },
  container: {
    textAlign: 'center',
    display: 'ruby'
  }
}));

const AmountDefault: React.FunctionComponent<Props> = ({
  setSelectedValue,
  selectedValue,
  handleSelectCustomValue,
  handleContinue
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      {DEFAULT_VALUES.map((value) => (
        <Grid item xs={6} key={value} className={classes.container}>
          <SelectionContainer
            handleClick={() => setSelectedValue(value)}
            selected={selectedValue === value}
          >
            <Typography
              className={clsx(
                selectedValue === value ? classes.textSelected : classes.text
              )}
            >
              ${value / 100}
            </Typography>
          </SelectionContainer>
        </Grid>
      ))}
      <Grid item xs={12}>
        <button
          onClick={handleSelectCustomValue}
          className={classes.customValueButton}
        >
          <Typography className={classes.customValueText}>
            {t('Custom value')}
          </Typography>
        </button>
      </Grid>

      <Grid item xs={12}>
        <ContinueButton
          disabled={!selectedValue}
          handleContinue={handleContinue}
        />
      </Grid>
    </Grid>
  );
};

export default AmountDefault;
