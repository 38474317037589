import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { get, find } from 'lodash';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import SelectInput from '../forms/select-input';
import AutocompleteInput from '../forms/autocomplete-input';
import LocationAutocompleteInput from '../forms/location-autocomplete-input';
import SelectInputWithTranslation from '../forms/select-input-with-translation';
import DateInput from '../forms/date-input';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import {
  REQUIRED_FIELD_TEXT,
  BANK_ACCOUNT_ITEMS,
  SEX_TYPE_ITEMS,
  IDTYPE_ITEMS,
  REGULAR_EXPRESSION_DECIMAL,
  REGULAR_EXPRESSION_PHONE_NUMBER,
  DECIMAL_VALUE_VALIDATION_MESSAGE,
  PHONE_NUMBER_VALIDATION_MESSAGE,
  MARITAL_STATUS_ITEMS,
  COMPANY_DEPARTMENT_ITEMS
} from '../../config/constants';
import FormSectionTitle from '../forms/form-section-title';
import {
  BankAccountTypes,
  ErolUserIdTypes,
  ErolUserSexTypes,
  SelectItemType,
  ErolUserCompanyDepartmentTypes,
  ErolUserMaritalStatusTypes,
  CompanyType
} from '../../definitions.d';
import { formatCompanies } from '../../utils/formatters';
import { FieldErrors } from '../../hooks/useCreateEmployeeMutation';

export interface FormValues {
  availableAmount?: string;
  bankAccountNumber: string;
  bankAccountType: BankAccountTypes;
  bankUuid: string;
  birthDate?: string;
  buildingNumber: string;
  city: SelectItemType | null;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  companyUuid: string;
  country: SelectItemType | null;
  email: string;
  familyDependents?: string;
  firstName: string;
  idType: ErolUserIdTypes;
  lastName: string;
  mainStreet: string;
  maritalStatus: ErolUserMaritalStatusTypes;
  neighborhood?: SelectItemType | null;
  phone: string;
  salary?: string;
  secondaryStreet?: string;
  sexType: ErolUserSexTypes;
  state: SelectItemType | null;
  taxId: string;
  username: string;
  zipCode?: string;
}

const validationSchema = yup.object({
  availableAmount: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  bankAccountNumber: yup.string().required(REQUIRED_FIELD_TEXT),
  bankAccountType: yup.string().required(REQUIRED_FIELD_TEXT),
  bankUuid: yup.string().required(REQUIRED_FIELD_TEXT),
  companyUuid: yup.string().required(REQUIRED_FIELD_TEXT),
  email: yup.string().email().required(REQUIRED_FIELD_TEXT),
  firstName: yup.string().required(REQUIRED_FIELD_TEXT),
  idType: yup
    .string()
    .oneOf(Object.keys(ErolUserIdTypes))
    .required(REQUIRED_FIELD_TEXT),
  lastName: yup.string().required(REQUIRED_FIELD_TEXT),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_PHONE_NUMBER, PHONE_NUMBER_VALIDATION_MESSAGE),
  salary: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  sexType: yup
    .string()
    .oneOf(Object.keys(ErolUserSexTypes))
    .required(REQUIRED_FIELD_TEXT),
  taxId: yup.string().required(REQUIRED_FIELD_TEXT),
  username: yup.string().required(REQUIRED_FIELD_TEXT),
  country: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  state: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  city: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  neighborhood: yup
    .object({
      value: yup.string(),
      label: yup.string()
    })
    .nullable(),
  mainStreet: yup.string().required(REQUIRED_FIELD_TEXT),
  secondaryStreet: yup.string(),
  buildingNumber: yup.string().required(REQUIRED_FIELD_TEXT),
  zipCode: yup.string(),
  companyDepartment: yup
    .string()
    .oneOf(Object.keys(ErolUserCompanyDepartmentTypes)),
  familyDependents: yup.number(),
  maritalStatus: yup.string().oneOf(Object.keys(ErolUserMaritalStatusTypes)),
  birthDate: yup.string()
});

interface Props {
  isLoading?: boolean;
  onSubmit: (values: FormValues) => void;
  companies: CompanyType[];
  banks: SelectItemType[];
  locations: SelectItemType[];
  fieldErrors?: FieldErrors;
}

const EmployeeCreateForm: React.FunctionComponent<Props> = ({
  isLoading,
  onSubmit,
  companies,
  banks,
  locations,
  fieldErrors
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const initialValues = {
    availableAmount: '',
    bankAccountNumber: '',
    bankAccountType: '' as BankAccountTypes,
    bankUuid: '',
    birthDate: new Date(
      new Date(Date.now()).setFullYear(new Date().getFullYear() - 20)
    ).toISOString(),
    companyUuid: '',
    email: '',
    firstName: '',
    idType: '' as ErolUserIdTypes,
    lastName: '',
    phone: '',
    salary: '0',
    sexType: '' as ErolUserSexTypes,
    taxId: '',
    username: '',
    country: null,
    state: null,
    city: null,
    neighborhood: null,
    mainStreet: '',
    secondaryStreet: '',
    buildingNumber: '',
    zipCode: '',
    companyDepartment: '' as ErolUserCompanyDepartmentTypes,
    familyDependents: '',
    maritalStatus: '' as ErolUserMaritalStatusTypes
  };
  const getErrorMessage = (fieldName: string) => {
    return fieldErrors && fieldErrors[fieldName] ? fieldErrors[fieldName] : '';
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: FormValues, actions): void => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {(props): React.ReactNode => {
        const companySelected = props.values.companyUuid
          ? find(companies, ['uuid', props.values.companyUuid])
          : undefined;

        const companyHasApiConnection = companySelected
          ? get(companySelected, 'hasApiConnection', false)
          : false;

        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="username"
                  label="Username"
                  component={TextInput}
                  error={Boolean(fieldErrors && fieldErrors.username)}
                  helperText={
                    fieldErrors && fieldErrors.username
                      ? fieldErrors.username
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="email"
                  label="Email"
                  component={TextInput}
                  error={Boolean(fieldErrors && fieldErrors.email)}
                  helperText={
                    fieldErrors && fieldErrors.email ? fieldErrors.email : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="idType"
                  label="Identification type"
                  component={SelectInputWithTranslation}
                  items={IDTYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="taxId"
                  label="ID Card Number"
                  component={TextInput}
                  error={Boolean(fieldErrors && fieldErrors.tax_id)}
                  helperText={
                    fieldErrors && fieldErrors.tax_id ? fieldErrors.tax_id : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  label="First name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  label="Last name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="phone" label="Phone" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="sexType"
                  label="Gender"
                  component={SelectInputWithTranslation}
                  items={SEX_TYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="familyDependents"
                  label="Family dependents"
                  component={TextInput}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="maritalStatus"
                  label="Marital status"
                  component={SelectInputWithTranslation}
                  items={MARITAL_STATUS_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="birthDate"
                  label="Birth date"
                  component={DateInput}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankUuid"
                  label="Bank"
                  component={SelectInput}
                  items={banks}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankAccountNumber"
                  label="Bank account number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankAccountType"
                  label="Bank account type"
                  component={SelectInputWithTranslation}
                  items={BANK_ACCOUNT_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyUuid"
                  label="Company"
                  component={SelectInput}
                  items={formatCompanies(companies)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyDepartment"
                  label="Company department"
                  component={SelectInputWithTranslation}
                  items={COMPANY_DEPARTMENT_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="salary" label="Salary" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="availableAmount"
                  label="Available amount"
                  component={TextInput}
                  disabled={!companyHasApiConnection}
                />
              </Grid>
              <FormSectionTitle text="Workplace location" />
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  label="Country"
                  component={AutocompleteInput}
                  items={locations}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="state"
                  label="State"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  label="City"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="neighborhood"
                  label="Neighborhood"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="mainStreet"
                  label="Main street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="secondaryStreet"
                  label="Secondary street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="buildingNumber"
                  label="Building number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="zipCode" label="Zip code" component={TextInput} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SubmitButton isLoading={isLoading} buttonText="Create" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SimpleButton
                  title="Go Back"
                  inheritColor={true}
                  buttonId="return-button"
                  onClick={(): void => {
                    navigate('/employees');
                  }}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default EmployeeCreateForm;
