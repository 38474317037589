import React from 'react';
// {{companyCommercialName}} {{companyLegalName}}
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SimpleButton from '../forms/simple-button';
import { useUserInfoContext } from '../../context/user-info-context';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsModalProps {
  open: boolean;
  onClose: () => void;
  termsText: string;
  onAccept: () => void;
  onReject: () => void;
  isLoading?: boolean;
}

const TermsAndConditionsDialog: React.FC<TermsAndConditionsModalProps> = ({
  open,
  onClose,
  termsText,
  onAccept,
  onReject,
  isLoading = false
}) => {
  const { userInfo } = useUserInfoContext();
  const getValueByPath = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const { t } = useTranslation();
  const filledHtml = termsText.replace(
    /\{\{(\w+(\.\w+)*)\}\}/g,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (_, key) => {
      const value = getValueByPath(userInfo, key);
      if (key === 'companyDepartment' && value !== undefined) {
        return t(value);
      }
      return value !== undefined ? value : `{{${key}}}`;
    }
  );

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        // Solo permitir que el diálogo se cierre si el motivo es 'escapeKeyDown'
        if (reason === 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="terms-and-conditions-title"
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="terms-and-conditions-title"
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center'
        }}
      >
        <IconButton
          onClick={onReject}
          color="primary"
          size="small"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: filledHtml }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
            marginRight: '25px'
          }}
        >
          <SimpleButton
            onClick={onAccept}
            buttonId={'submit-terms-and-conditions-dialog'}
            inheritColor={false}
            title={'Aceptar'}
            isDisabled={isLoading}
          />
          <SimpleButton
            onClick={onReject}
            buttonId={'reject-terms-and-conditions-dialog'}
            inheritColor={true}
            title={'Rechazar'}
            isDisabled={isLoading}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
