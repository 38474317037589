import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useEffect
} from 'react';
import { get } from 'lodash';

import { PriceRangeType } from '../../definitions.d';
import { formatPriceRanges } from './utils';
import {
  initialState,
  reducer,
  ExtendedPriceRangeType,
  ADD_PRICE_RANGES,
  ADD_COMPANY_UUID,
  ADD_NEW_PRICE_RANGE,
  OPEN_CREATE_DIALOG,
  OPEN_REMOVE_DIALOG,
  REMOVE_PRICE_RANGE,
  SET_SELECTED_PRICE_RANGE,
  OPEN_EDIT_DIALOG,
  EDIT_PRICE_RANGE,
  PriceRangesGroup,
  ADD_PRICE_RANGES_GROUP
} from './reducer';

export interface PriceRangesContextProps {
  priceRangesGroup: PriceRangesGroup;
  priceRanges: ExtendedPriceRangeType[];
  companyUuid: string;
  priceRangesToRemove: ExtendedPriceRangeType[];
  isDirty: boolean;
  selectedPriceRange?: ExtendedPriceRangeType;
  openRemoveDialog: boolean;
  openEditDialog: boolean;
  openCreateDialog: boolean;
  addPriceRanges: (priceRanges: PriceRangeType[]) => void;
  addCompanyUuid: (companyUuid: string) => void;
  addNewPriceRange: (priceRange: ExtendedPriceRangeType) => void;
  setOpenCreateDialog: (openCreateDialog: boolean) => void;
  setOpenRemoveDialog: (openRemoveDialog: boolean) => void;
  removePriceRange: (priceRange: ExtendedPriceRangeType) => void;
  setSelectedPriceRange: (priceRange: ExtendedPriceRangeType) => void;
  setOpenEditDialog: (openEditDialog: boolean) => void;
  editPriceRange: (priceRange: ExtendedPriceRangeType) => void;
  addPriceRangesGroup: (priceRangesGroup: PriceRangesGroup) => void;
}

export interface PriceRangesProviderProps {
  children: React.ReactNode;
  initialPriceRangesGroupValues?: PriceRangesGroup;
}

export const PriceRangesContext = createContext<PriceRangesContextProps>(
  {} as PriceRangesContextProps
);

export const PriceRangesProvider: React.FunctionComponent<
  PriceRangesProviderProps
> = ({ children, initialPriceRangesGroupValues }: PriceRangesProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (initialPriceRangesGroupValues) {
      dispatch({
        type: ADD_PRICE_RANGES_GROUP,
        priceRangesGroup: initialPriceRangesGroupValues
      });

      dispatch({
        type: ADD_PRICE_RANGES,
        priceRanges: get(initialPriceRangesGroupValues, 'priceRanges', [])
      });
    }
  }, [initialPriceRangesGroupValues]);

  const addPriceRanges = useCallback(
    (priceRanges: PriceRangeType[]): void => {
      dispatch({
        type: ADD_PRICE_RANGES,
        priceRanges: formatPriceRanges(priceRanges)
      });
    },
    [dispatch]
  );

  const addPriceRangesGroup = useCallback(
    (priceRangesGroup: PriceRangesGroup): void => {
      dispatch({
        type: ADD_PRICE_RANGES_GROUP,
        priceRangesGroup
      });
    },
    [dispatch]
  );

  const addCompanyUuid = useCallback(
    (companyUuid: string): void => {
      dispatch({ type: ADD_COMPANY_UUID, companyUuid });
    },
    [dispatch]
  );

  const addNewPriceRange = useCallback(
    (priceRange: ExtendedPriceRangeType): void => {
      dispatch({ type: ADD_NEW_PRICE_RANGE, priceRange });
    },
    [dispatch]
  );

  const setOpenCreateDialog = useCallback(
    (openCreateDialog: boolean): void => {
      dispatch({ type: OPEN_CREATE_DIALOG, openCreateDialog });
    },
    [dispatch]
  );

  const setOpenRemoveDialog = useCallback(
    (openRemoveDialog: boolean): void => {
      dispatch({ type: OPEN_REMOVE_DIALOG, openRemoveDialog });
    },
    [dispatch]
  );

  const removePriceRange = useCallback(
    (priceRange: ExtendedPriceRangeType): void => {
      dispatch({ type: REMOVE_PRICE_RANGE, priceRange });
    },
    [dispatch]
  );

  const setSelectedPriceRange = useCallback(
    (priceRange: ExtendedPriceRangeType): void => {
      dispatch({ type: SET_SELECTED_PRICE_RANGE, priceRange });
    },
    [dispatch]
  );

  const setOpenEditDialog = useCallback(
    (openEditDialog: boolean): void => {
      dispatch({ type: OPEN_EDIT_DIALOG, openEditDialog });
    },
    [dispatch]
  );

  const editPriceRange = useCallback(
    (priceRange: ExtendedPriceRangeType): void => {
      dispatch({ type: EDIT_PRICE_RANGE, priceRange });
    },
    [dispatch]
  );

  return (
    <PriceRangesContext.Provider
      value={{
        priceRangesGroup: state.priceRangesGroup,
        priceRanges: state.priceRanges,
        companyUuid: state.companyUuid,
        priceRangesToRemove: state.priceRangesToRemove,
        isDirty: state.isDirty,
        selectedPriceRange: state.selectedPriceRange,
        openRemoveDialog: state.openRemoveDialog,
        openEditDialog: state.openEditDialog,
        openCreateDialog: state.openCreateDialog,
        addPriceRanges,
        addCompanyUuid,
        addNewPriceRange,
        setOpenCreateDialog,
        setOpenRemoveDialog,
        removePriceRange,
        setSelectedPriceRange,
        setOpenEditDialog,
        editPriceRange,
        addPriceRangesGroup
      }}
    >
      {children}
    </PriceRangesContext.Provider>
  );
};

export const usePriceRangesContext = (): PriceRangesContextProps =>
  useContext(PriceRangesContext);
