import React from 'react';

import { useRequestContext } from '../../context/request/request-context';
import {
  RequestAction,
  RequestStep,
  RequestType
} from '../../context/request/reducer';
import { convertToCents } from '../../utils/formatters';
import RequestStepWrapper from './request-step-wrapper';
import AmountDefault from './amount-default';
import AmountCustomForm, { FormProps } from './amount-custom-form';

interface Props {
  type: string | null;
  onUpdateRouterHistory: (step: string, type: string) => void;
}

const Amount: React.FunctionComponent<Props> = ({
  type,
  onUpdateRouterHistory
}: Props) => {
  const { state, dispatch } = useRequestContext();

  const updateHistory = (
    newStep = RequestStep.AMOUNT,
    newType = RequestType.CUSTOM
  ) => {
    onUpdateRouterHistory(newStep, newType);
  };

  return (
    <RequestStepWrapper title="How much do you want to request?">
      {type === RequestType.CUSTOM ? (
        <AmountCustomForm
          handleSubmit={({ amount }: FormProps) => {
            dispatch({
              type: RequestAction.SET_AMOUNT,
              payload: convertToCents(amount)
            });
            updateHistory(RequestStep.REASON, RequestType.DEFAULT);
          }}
        />
      ) : (
        <AmountDefault
          selectedValue={state.amount}
          setSelectedValue={(value: number) =>
            dispatch({ type: RequestAction.SET_AMOUNT, payload: value })
          }
          handleSelectCustomValue={() => {
            dispatch({ type: RequestAction.SET_AMOUNT, payload: null });
            updateHistory();
          }}
          handleContinue={() =>
            updateHistory(RequestStep.REASON, RequestType.DEFAULT)
          }
        />
      )}
    </RequestStepWrapper>
  );
};

export default Amount;
