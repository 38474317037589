export type CountryType = {
  uuid: string;
  name: string;
};

export type IndustrySegmentType = {
  uuid: string;
  name: string;
  code: string;
};

export type CompanyType = {
  country: LocationType;
  state: LocationType;
  city: LocationType;
  neighborhood: LocationType;
  industrySegment: IndustrySegmentType;
  uuid: string;
  commercialName: string;
  identifier: string;
  legalName: string;
  taxId: string;
  maxWithdrawalPercent: number;
  minMonths: number;
  minWithdrawalAmount: number;
  maxWithdrawalsPerMonth: number;
  waitingDays: number;
  serviceFee: number;
  platformFee: number;
  transferFee: number;
  taxPercent: number;
  onDemandApi: boolean;
  onDemandApiAuthUrl: string;
  onDemandApiInfoUrl: string;
  onDemandApiUser: string;
  onDemandApiPassword: string;
  onDemandApiCompanyCode: string;
  contractStartDate: string;
  contractEndDate: string;
  payDay: number;
  isAutoRenew: boolean;
  require2fa: boolean;
  mainStreet: string;
  secondaryStreet: string;
  buildingNumber: string;
  zipCode: string;
  contract: string;
  echekExecutive: string;
  hasApiConnection: boolean;
  managers: UserType[];
  companyBanks: CompanyBankType[];
};

export type CompanyEdge = {
  cursor: string;
  node: CompanyType;
};

export type UserEdge = {
  cursor: string;
  node: UserType;
};

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
};

export type CompanyTypeConnection = {
  totalCount: number;
  edges: CompanyEdge[];
  pageInfo: PageInfo;
};

export type UserTypeConnection = {
  totalCount: number;
  edges: UserEdge[];
  pageInfo: PageInfo;
};

export type BankType = {
  uuid: string;
  name: string;
};

export enum ErolUserIdTypes {
  CEDULA = 'CEDULA',
  PASSPORT = 'PASSPORT'
}

export enum ErolUserRoles {
  ANONYMOUS_USER = 'ANONYMOUS_USER',
  DJANGO_USER = 'DJANGO_USER',
  COMPANY_MANAGER = 'COMPANY_MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  PLATFORM_MANAGER = 'PLATFORM_MANAGER'
}

export enum ErolUserSexTypes {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum ErolUserMaritalStatusTypes {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  FREE_UNION = 'FREE_UNION'
}

export enum ErolUserCompanyDepartmentTypes {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  ADVISORY = 'ADVISORY',
  COMMERCIAL = 'COMMERCIAL',
  PURCHASES = 'PURCHASES',
  ACCOUNTING = 'ACCOUNTING',
  DEVELOPMENT = 'DEVELOPMENT',
  FINANCE = 'FINANCE',
  MANAGEMENT = 'MANAGEMENT',
  IT = 'IT',
  LEGAL = 'LEGAL',
  CLEANING = 'CLEANING',
  LOGISTICS = 'LOGISTICS',
  MARKETING = 'MARKETING',
  OPERATIVE = 'OPERATIVE',
  PRODUCTION = 'PRODUCTION',
  SECURITY = 'SECURITY',
  HR = 'HR'
}

export type UserType = {
  availableAmount: number;
  bank: BankType;
  company: {
    uuid: string;
    commercialName: string;
    legalName: string;
    mainStreet: string;
    secondaryStreet: string;
    buildingNumber: string;
    city: LocationType;
  };
  uuid: string;
  email: string;
  bankAccountType: BankAccountTypes;
  bankAccountNumber: string;
  firstName: string;
  lastName: string;
  sexType: ErolUserSexTypes;
  userRole: ErolUserRoles;
  idType: ErolUserIdTypes;
  taxId: string;
  phone: string;
  salary: number;
  sessionTimeout: number;
  familyDependents: number;
  maritalStatus: ErolUserMaritalStatusTypes;
  birthDate: string;
  companyDepartment: ErolUserCompanyDepartmentTypes;
  mainStreet: string;
  secondaryStreet: string;
  buildingNumber: string;
  zipCode: string;
  country: LocationType;
  username: string;
  confirmed: boolean;
  apiOnly: boolean;
  notifyAdvances: boolean;
  isSuspended: boolean;
  acceptance: AcceptanceType;
};

export type AcceptanceType = {
  uuid?: string;
  acceptedOn?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PriceRangeType = {
  uuid?: string;
  rangeStart: number;
  rangeEnd: number;
  price?: number;
};

export type PriceRangesGroupType = {
  uuid?: string;
  startDay: number;
  endDay: number;
  chargedNextMonth: boolean;
  priceRanges: PriceRangeType[];
};

export enum AdvanceStatusTypes {
  REQUESTED = 'REQUESTED',
  SUCCESSFUL = 'SUCCESSFUL',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED'
}

export type AdvanceType = {
  erolUser: {
    firstName: string;
    lastName: string;
    taxId: string;
    company: CompanyType;
  };
  uuid: string;
  requestDate: string;
  processDate: string;
  advanceAmount: number;
  serviceFee: number;
  platformFee: number;
  transferFee: number;
  tax: number;
  price: number;
  status: AdvanceStatusTypes | null;
  effectiveTransferAmount: number;
  originBankAccount: {
    bank: BankType;
    accountNumber: string;
    accountType: BankAccountTypes;
  };
  bank: BankType;
  bankAccountType: BankAccountTypes;
  bankAccountNumber: string;
  identifier: string;
};

export type AdvanceTypeConnection = {
  totalCount: number;
  edges: AdvanceEdge[];
  pageInfo: PageInfo;
};
export type AdvanceEdge = {
  cursor: string;
  node: AdvanceType;
};

export type EmployeeCompanyType = {
  uuid: string;
  commercialName: string;
};

export type AdvanceReportItemType = {
  requestDate: string;
  processDate: string;
  advanceAmount: number;
  serviceFee: number;
  platformFee: number;
  transferFee: number;
  tax: number;
  price: number;
  status: AdvanceStatusTypes | null;
  companyName: string;
  userName: string;
  uuid: string;
};

export type AdvanceStatusType = {
  totalCount: number;
  currentCount: number;
  totalAmount: number;
  currentAmount: number;
  amountCompleted: number;
  amountInTransit: number;
  amountAvailable: number;
};

export type AdvanceAvailabilityType = {
  serviceFee: number;
  platformFee: number;
  transferFee: number;
  tax: number;
  price: number;
  effectiveTransferAmount: number;
};

export enum BankAccountTypes {
  AHO = 'AHO',
  CTE = 'CTE'
}

export enum LocationTypes {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  CITY = 'CITY',
  NEIGHBORHOOD = 'NEIGHBORHOOD'
}

export type LocationType = {
  uuid: string;
  name: string;
  code: string;
  locationType: LocationTypes;
};

export type SelectItemType = {
  value: string | number;
  label: string;
};

export type CompanyBankType = {
  uuid: string;
  bank: BankType;
  code: string;
};

export enum ReasonOptions {
  Education = 'Education',
  Housing = 'Housing',
  Health = 'Health',
  Food = 'Food',
  Entertainment = 'Entertainment'
}

export type ChangePageArgs = {
  after?: string;
  before?: string;
};

export type FetchMoreArgs = {
  after?: string;
  before?: string;
  first?: number | null;
  last?: number | null;
};
