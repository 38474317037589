import CreateButton from '../forms/create-button';
import React from 'react';
import ManagersSearchInput from './managers-search-input';

export default function ManagersListActions({
  handleSearch,
  navigate
}: {
  handleSearch: (value: string) => void;
  navigate: (url: string) => void;
}): JSX.Element {
  return (
    <div className={'action-container'}>
      <CreateButton
        onClick={(): void => {
          navigate('/managers/create');
        }}
      />
      <ManagersSearchInput onSearch={handleSearch} />
    </div>
  );
}
