import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: `1px solid var(--Neutral-Light-800, #D1D5DB)`,
    padding: '8px 14px 4px',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.up('md')]: {
      width: '90%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%'
    }
  },
  summaryText: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    marginTop: '15px',
    marginBottom: '15px',
    marginLeft: '13px'
  },
  summaryTextSubtitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '15px',
    marginBottom: '15px',
    marginLeft: '13px'
  }
}));

interface Props {
  title: string;
  subtitle: string;
}

const Summary: React.FunctionComponent<Props> = ({
  title,
  subtitle
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.summaryContainer}>
      <div className={classes.summary}>
        <Typography className={classes.summaryText}>{t(title)}</Typography>
        <Typography className={classes.summaryTextSubtitle}>
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default Summary;
