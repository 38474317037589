import React, { useState, useEffect, ChangeEvent } from 'react';
import { get } from 'lodash';
import { Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import useBankAccountsQuery, {
  BankAccount
} from '../../hooks/useBankAccountsQuery';
import { SelectItemType, AdvanceStatusTypes } from '../../definitions.d';
import LoadingNotification from '../notifications/loading-notification';
import useAdvancesQuery from '../../hooks/useAdvancesQuery';
import PageTitle from '../navigation/page-title';
import Table from './table';
import CreateFileDialog from './create-file-dialog';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(1)
  }
}));

const CashManagement: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const defaultBankAccount = { value: '', label: t('All') };

  const [bankAccount, setBankAccount] = useState<SelectItemType | null>(
    defaultBankAccount
  );
  const [advanceUuids, setAdvanceUuids] = useState<string[]>([]);
  const [openCreateFileDialog, setOpenCreateFileDialog] =
    useState<boolean>(false);

  const { bankAccounts } = useBankAccountsQuery();
  const { getAdvances, advances, loading } = useAdvancesQuery();

  useEffect(() => {
    getAdvances({
      statuses: [AdvanceStatusTypes.REQUESTED]
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateFile = (selectedUuids: string[]): void => {
    setAdvanceUuids(selectedUuids);
    if (bankAccount) {
      setOpenCreateFileDialog(true);
    }
  };

  const formatBankAccounts = (accounts: BankAccount[]): SelectItemType[] => {
    const formattedAccounts = accounts
      .filter((account) => account.isActive)
      .map((account) => ({
        value: get(account, 'uuid'),
        label: `${get(account, 'accountNumber')}, ${get(account, 'bank.name')}`
      }));

    formattedAccounts.unshift(defaultBankAccount);

    return formattedAccounts;
  };

  if (!bankAccounts) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Create CM file" />
      <Grid
        container
        spacing={3}
        className={classes.formContainer}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Autocomplete
            id="combo-box-bank-accounts"
            value={bankAccount}
            options={formatBankAccounts(bankAccounts)}
            getOptionLabel={(option: SelectItemType): string => option.label}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={t('Bank account')}
                fullWidth
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
            onChange={(
              event: ChangeEvent<unknown>,
              value: SelectItemType | null
            ): void => {
              setBankAccount(value);
            }}
            isOptionEqualToValue={(option: any, value: any): boolean => {
              return option.value === bankAccount?.value;
            }}
          />
        </Grid>
      </Grid>

      {loading && <LoadingNotification />}

      {Array.isArray(advances) && advances.length && bankAccounts.length ? (
        <Table advances={advances} onCreateFile={handleCreateFile} />
      ) : null}

      <CreateFileDialog
        open={openCreateFileDialog}
        handleClose={(): void => {
          setOpenCreateFileDialog(false);
        }}
        bankAccountUuid={get(bankAccount, 'value') as string}
        advanceUuids={advanceUuids}
      />
    </>
  );
};

export default CashManagement;
