import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export interface AlertDialogProps {
  open: boolean;
  title: string;
  content: string;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmButtonText?: string;
}

const AlertDialog: React.FunctionComponent<AlertDialogProps> = ({
  open,
  title,
  content,
  handleClose,
  handleConfirm
}: AlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{
        onEntering: (): void => {
          document.body.style.paddingRight = '0px';
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" data-testid="close-button">
          {t('Close')}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          data-testid="confirm-button"
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
