import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { omitBy } from 'lodash';

import { BankAccountTypes } from '../definitions.d';
import useHandleError from './useHandleError';

const REQUEST_ADVANCE_MUTATION = loader('../graphql/requestAdvance.graphql');

interface RequestAdvanceProps {
  amount: number | null;
  bankAccountNumber?: string;
  bankAccountType?: BankAccountTypes;
  bankUuid?: string;
  reason?: string | null;
}

interface Response {
  requestAdvance: (values: RequestAdvanceProps) => void;
  loading?: boolean;
  error?: Error;
}

interface Props {
  onCompleted: () => void;
}

const useRequestAdvanceMutation = ({ onCompleted }: Props): Response => {
  const { handleError } = useHandleError();

  const [mutation, { loading, error }] = useMutation(REQUEST_ADVANCE_MUTATION, {
    onError: (e) => {
      handleError(e);
    },
    onCompleted
  });

  return {
    loading,
    error,
    requestAdvance: (values: RequestAdvanceProps): void => {
      mutation({
        variables: omitBy(values, (value) => !value)
      });
    }
  };
};

export default useRequestAdvanceMutation;
