import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { get, omitBy } from 'lodash';

import { AdvanceType } from '../definitions.d';
import useHandleError from './useHandleError';

const ADVANCES_QUERY = loader('../graphql/advances.graphql');

interface AdvancesProps {
  startDate?: string;
  endDate?: string;
  statuses?: string[] | null;
  companyUuids?: string[] | null;
}

interface AdvancesResponse {
  advances?: AdvanceType[];
  loading?: boolean;
  error?: Error;
  getAdvances: (values: AdvancesProps) => void;
}

const useAdvancesQuery = (): AdvancesResponse => {
  const { handleError } = useHandleError();

  const [executeQuery, { data, loading, error }] = useLazyQuery(
    ADVANCES_QUERY,
    {
      onError: (e) => {
        handleError(e);
      },
      fetchPolicy: 'network-only'
    }
  );
  return {
    getAdvances: (values: AdvancesProps): void => {
      executeQuery({ variables: omitBy(values, (value) => !value) });
    },
    advances: get(data, 'advances'),
    loading,
    error
  };
};

export default useAdvancesQuery;
