import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import TermsOfService from './terms-of-service';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  termsOfServiceContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  open: boolean;
  handleClose: () => void;
}

const TermsOfServiceDialog: React.FunctionComponent<Props> = ({
  open,
  handleClose
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('Terms of service')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.termsOfServiceContainer}>
          <TermsOfService />
        </div>
      </Dialog>
    </div>
  );
};

export default TermsOfServiceDialog;
