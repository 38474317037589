import { DateTime } from 'luxon';

import {
  BankType,
  CompanyType,
  LocationType,
  SelectItemType,
  IndustrySegmentType
} from '../definitions.d';
import {
  DECIMAL_DIGITS,
  DEFAULT_DATE_FORMAT,
  TIME_ZONE
} from '../config/constants';

export const formatBank = (item: BankType): SelectItemType => ({
  label: item.name,
  value: item.uuid
});

export const formatBanks = (items: BankType[]): SelectItemType[] =>
  items.map((item) => ({
    label: item.name,
    value: item.uuid
  }));

export const formatCompanies = (items: CompanyType[]): SelectItemType[] =>
  items.map((item) => ({
    label: item.commercialName,
    value: item.uuid
  }));

export const formatLocations = (items: LocationType[]): SelectItemType[] =>
  items.map((item) => ({
    label: item.name,
    value: item.uuid
  }));

export const formatLocation = (item: LocationType): SelectItemType => ({
  label: item.name,
  value: item.uuid
});

export const formatIndustrySegments = (
  items: IndustrySegmentType[]
): SelectItemType[] =>
  items.map((item) => ({
    label: item.name,
    value: item.uuid
  }));

export const convertToDollars = (value: number): string =>
  (value / 100).toFixed(DECIMAL_DIGITS);

export const convertToCents = (value: string): number =>
  Math.round(parseFloat(value) * 100);

export const convertFloatToString = (value: number): string =>
  value.toFixed(DECIMAL_DIGITS);

export const convertToFloat = (value: string): number =>
  parseFloat(parseFloat(value).toFixed(DECIMAL_DIGITS));

export const formatDate = (date: string): string =>
  DateTime.fromISO(date).setZone(TIME_ZONE).toFormat(DEFAULT_DATE_FORMAT);

export const cleanErrorMessage = (error: string): string => {
  let cleanedError = error;

  if (error.match('GraphQL error:')) {
    cleanedError = cleanedError.replace('GraphQL error:', '').trim();
  }

  return cleanedError;
};
