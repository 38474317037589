import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { AdvanceType } from '../definitions.d';

const PROCESS_CASH_MANAGEMENT_MUTATION = loader(
  '../graphql/processCashManagementFile.graphql'
);

export interface ProcessCashManagementFileProps {
  bankUuid: string;
  file: File | null;
}

export interface CashManagementFile {
  identifier: string;
  url: string;
}

interface CreateCashManagementFileResponse {
  processCashManagementFile: (values: ProcessCashManagementFileProps) => void;
  advances?: AdvanceType[];
  loading?: boolean;
  error?: Error;
}

const useProcessCashManagementFileMutation =
  (): CreateCashManagementFileResponse => {
    const { showNotification } = useNotificationContext();

    const [mutation, { data, loading, error }] = useMutation(
      PROCESS_CASH_MANAGEMENT_MUTATION,
      {
        onError: (e) => {
          showNotification(e.message, 'error');
        }
      }
    );

    return {
      processCashManagementFile: (
        values: ProcessCashManagementFileProps
      ): void => {
        mutation({ variables: values });
      },
      advances: get(data, 'processCashManagementFile.advances'),
      error,
      loading
    };
  };

export default useProcessCashManagementFileMutation;
