import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import useStyles from './styles';

const PasswordInput: React.FunctionComponent<TextFieldProps> = (
  props: TextFieldProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const textInputProps = fieldToTextField(props);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
  };

  return (
    <FormControl className={classes.formControl}>
      <TextField
        {...textInputProps}
        label={t(`${textInputProps.label}`)}
        inputProps={{ 'data-testid': `input-${textInputProps.name}` }}
        helperText={
          textInputProps.helperText
            ? t(`${textInputProps.helperText}`)
            : undefined
        }
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                tabIndex={-1}
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default PasswordInput;
