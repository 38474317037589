import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { UserType } from '../definitions.d';

const CREATE_MANAGER_MUTATION = loader('../graphql/createManager.graphql');
const MANAGERS_QUERY = loader('../graphql/managers.graphql');

export interface CreateManagerProps {
  apiOnly?: boolean;
  email: string;
  firstName: string;
  idType?: string;
  lastName: string;
  notifyAdvances?: boolean;
  phone: string;
  taxId?: string;
  userRole: string;
  username: string;
  sexType?: string;
  companyUuids?: string[] | null;
}

interface Response {
  mutation: (values: CreateManagerProps) => void;
  loading?: boolean;
}

const useCreateManagerMutation = (): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [createManager, { loading }] = useMutation(CREATE_MANAGER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    onCompleted: () => {
      showNotification(t('Manager created successfully'), 'success');
      navigate('/managers');
    }
  });

  return {
    loading,
    mutation: (values: CreateManagerProps): void => {
      const manager = omitBy(
        values,
        (value) => typeof value === 'undefined' || value === null
      ) as CreateManagerProps;
      createManager({
        variables: { ...manager },
        update(cache, { data }) {
          const createdManager: UserType = get(data, 'createManager.user');
          let cachedValues;
          try {
            cachedValues = cache.readQuery({ query: MANAGERS_QUERY });
          } catch {
            cachedValues = {};
          }
          const cachedManagers: UserType[] = <UserType[]>(
            (<unknown>get(cachedValues, 'managers'))
          );
          if (cachedManagers) {
            cache.writeQuery({
              query: MANAGERS_QUERY,
              data: {
                managers: cachedManagers.concat(createdManager)
              }
            });
          }
        }
      });
    }
  };
};

export default useCreateManagerMutation;
