import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { UserType } from '../definitions.d';
import { getToken } from '../utils/auth-client';
import useHandleError from './useHandleError';

const ME_QUERY = loader('../graphql/me.graphql');

interface MeQueryResponse {
  userInfo?: UserType;
  loading?: boolean;
  error?: Error;
  refetch: () => Promise<UserType>;
}

const useMeQuery = (): MeQueryResponse => {
  const { handleError } = useHandleError();

  const { data, loading, error, refetch } = useQuery(ME_QUERY, {
    onError: (e) => {
      handleError(e);
    },
    skip: !getToken()
  });

  return {
    userInfo: get(data, 'me'),
    loading,
    error,
    refetch: async (): Promise<UserType> => {
      const response = await refetch();
      return get(response, 'me') as unknown as UserType;
    }
  };
};

export default useMeQuery;
