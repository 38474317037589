import { BankAccountTypes } from '../../definitions.d';

export enum RequestStep {
  AMOUNT = 'AMOUNT',
  REASON = 'REASON',
  BANK = 'BANK',
  SUMMARY = 'SUMMARY'
}

export enum RequestType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export enum RequestAction {
  SET_AMOUNT = 'SET_AMOUNT',
  SET_REASON = 'SET_REASON',
  SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT',
  SET_BANK_ACCOUNT_INFORMATION = 'SET_BANK_ACCOUNT_INFORMATION',
  RESET = 'RESET'
}

export enum BankAccountOptions {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export interface BankAccountInformation {
  bankAccountNumber: string;
  bankAccountType: BankAccountTypes;
  bankUuid: string;
}

interface SetAmountAction {
  type: typeof RequestAction.SET_AMOUNT;
  payload: number | null;
}

interface SetReasonAction {
  type: typeof RequestAction.SET_REASON;
  payload: string | null;
}

interface SetBankAccountAction {
  type: typeof RequestAction.SET_BANK_ACCOUNT;
  payload: BankAccountOptions;
}

interface SetBankAccountInformationAction {
  type: typeof RequestAction.SET_BANK_ACCOUNT_INFORMATION;
  payload: BankAccountInformation | null;
}

interface ResetAction {
  type: typeof RequestAction.RESET;
  payload: State;
}

export type ActionTypes =
  | SetAmountAction
  | SetReasonAction
  | ResetAction
  | SetBankAccountAction
  | SetBankAccountInformationAction;

export interface State {
  amount: number | null;
  reason: string | null;
  bankAccount: BankAccountOptions;
  bankAccountInformation: BankAccountInformation | null;
}

export const INITIAL_STATE: State = {
  amount: null,
  reason: null,
  bankAccount: BankAccountOptions.DEFAULT,
  bankAccountInformation: null
};

export const init = (initialState: State): State => {
  return initialState;
};

export const reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case RequestAction.SET_AMOUNT:
      return { ...state, amount: action.payload };

    case RequestAction.SET_REASON:
      return { ...state, reason: action.payload };

    case RequestAction.SET_BANK_ACCOUNT:
      return { ...state, bankAccount: action.payload };

    case RequestAction.SET_BANK_ACCOUNT_INFORMATION:
      return { ...state, bankAccountInformation: action.payload };

    case RequestAction.RESET:
      return init(action.payload);

    default:
      throw new Error();
  }
};
