import React from 'react';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Toolbar, Typography, IconButton, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.error.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}));

interface Props {
  numSelected: number;
  onCreateFile: () => void;
}

const TableToolbar: React.FunctionComponent<Props> = ({
  numSelected,
  onCreateFile
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {`${numSelected} ${t('selected')}`}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('Requested advances')}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title={t('Create files')}>
          <IconButton
            aria-label="create-cm-file"
            onClick={onCreateFile}
            size="large"
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

export default TableToolbar;
