import React, { ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';

import TextInput from '../forms/text-input';
import SelectInput from '../forms/select-input-employee';
import AutocompleteInput from '../forms/autocomplete-input-employee';
import SubmitButton from '../forms/submit-button';
import {
  REQUIRED_FIELD_TEXT,
  BANK_ACCOUNT_ITEMS
} from '../../config/constants';
import { BankAccountTypes, SelectItemType } from '../../definitions.d';
import {
  ActionTypes,
  BankAccountOptions,
  RequestAction
} from '../../context/request/reducer';
import { useRequestContext } from '../../context/request/request-context';

const VALIDATION_SCHEMA = yup.object().shape({
  bankAccount: yup
    .string()
    .oneOf(Object.keys(BankAccountOptions))
    .required(REQUIRED_FIELD_TEXT),
  bank: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  bankAccountNumber: yup.number().required(REQUIRED_FIELD_TEXT),
  bankAccountType: yup.string().required(REQUIRED_FIELD_TEXT)
});

const BANK_ACCOUNT_OPTIONS = [
  {
    label: 'Use default account',
    value: BankAccountOptions.DEFAULT
  },
  {
    label: 'Use custom account',
    value: BankAccountOptions.CUSTOM
  }
];

export interface FormProps {
  bankAccount: BankAccountOptions;
  bank: SelectItemType | null;
  bankAccountNumber: string;
  bankAccountType: BankAccountTypes;
}

interface Props {
  initialValues: FormProps;
  handleSubmit: (values: FormProps) => void;
  banks: SelectItemType[];
  loading?: boolean;
}

const AmountCustomForm: React.FunctionComponent<Props> = ({
  initialValues,
  handleSubmit,
  banks,
  loading
}: Props) => {
  const { dispatch } = useRequestContext();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        handleSubmit(values);
      }}
      enableReinitialize
    >
      {(props) => {
        const disabled =
          props.values.bankAccount === BankAccountOptions.DEFAULT;

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  name="bankAccount"
                  label="Bank account"
                  component={SelectInput}
                  items={BANK_ACCOUNT_OPTIONS}
                  field={{
                    value: props.initialValues.bankAccount,
                    onChange: (event: ChangeEvent) =>
                      dispatch({
                        type: RequestAction.SET_BANK_ACCOUNT,
                        payload: get(event, 'target.value') as any
                      })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="bankAccountNumber"
                  label="Bank account number"
                  variant="outlined"
                  component={TextInput}
                  size="small"
                  type="number"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="bankAccountType"
                  label="Bank account type"
                  component={SelectInput}
                  items={BANK_ACCOUNT_ITEMS}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="bank"
                  label="Bank"
                  component={AutocompleteInput}
                  items={banks}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SubmitButton
                isLoading={loading}
                buttonText="Continue"
                size="small"
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AmountCustomForm;
