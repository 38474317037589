import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FiberManualRecord } from '@mui/icons-material';

import { convertToDollars } from '../../utils/formatters';
import {
  AVAILABLE_AMOUNT_COLOR,
  USED_AMOUNT_COLOR,
  PENDING_AMOUNT_COLOR
} from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  label: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelText: {
    color: '#6B7280',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px'
  }
}));

interface Props {
  amountAvailable: number;
  amountCompleted: number;
  amountInTransit: number;
}

const LabelContainer: React.FunctionComponent<Props> = ({
  amountAvailable,
  amountCompleted,
  amountInTransit
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const availableText = t('Available');
  const completedText = t('Completed');
  const inTransitText = t('In transit');

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <FiberManualRecord style={{ color: USED_AMOUNT_COLOR }} />
        <Typography>${convertToDollars(amountCompleted)}</Typography>
        <Typography className={classes.labelText}>{completedText}</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ color: PENDING_AMOUNT_COLOR }} />
        <Typography>${convertToDollars(amountInTransit)}</Typography>
        <Typography className={classes.labelText}>{inTransitText}</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ color: AVAILABLE_AMOUNT_COLOR }} />
        <Typography>${convertToDollars(amountAvailable)}</Typography>
        <Typography className={classes.labelText}>{availableText}</Typography>
      </div>
    </div>
  );
};

export default LabelContainer;
