import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
}

const PageTitle: React.FunctionComponent<Props> = ({ title }: Props) => {
  const { t } = useTranslation();

  const text = t(title);

  useEffect(() => {
    document.title = `${text} | E-ROL`;
  }, [text]);

  return (
    <Typography component="h2" variant="h5" align="center">
      {text}
    </Typography>
  );
};

export default PageTitle;
