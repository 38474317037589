import React, { useMemo } from 'react';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  responsiveFontSizes
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { ErolUserRoles } from '../definitions.d';
import { MAIN_COLOR, WHITE_COLOR } from '../config/constants';
import { useUserInfoContext } from './user-info-context';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const FONT_FAMILY = 'AkzidenzGrotesk, Roboto, Arial';
const FONT_SIZE = 14;

export const BASE_THEME = {
  palette: {
    primary: {
      main: MAIN_COLOR,
      contrastText: WHITE_COLOR
    },
    background: {
      default: WHITE_COLOR
    }
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZE
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '10px 16px 6px 16px'
      }
    },
    MuiInputBase: {
      input: {
        height: '1em'
      }
    }
  }
};

const EMPLOYEE_THEME = {
  ...BASE_THEME,
  overrides: {
    ...BASE_THEME.overrides,
    MuiButton: {
      root: {
        padding: '12px 16px 8px 16px',
        borderRadius: '25px'
      }
    },
    MuiInputBase: {
      input: {
        height: '1em'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '25px'
      },
      input: {
        padding: '18px 18px'
      }
    },
    MuiInputLabel: {
      formControl: {
        left: '2px'
      }
    }
  }
};

interface Props {
  children: React.ReactNode;
}

const CustomThemeProvider = ({ children }: Props): React.ReactElement => {
  const { userRole } = useUserInfoContext();

  const theme = useMemo(() => {
    return responsiveFontSizes(
      createTheme(
        userRole === ErolUserRoles.PLATFORM_MANAGER ||
          userRole === ErolUserRoles.COMPANY_MANAGER
          ? BASE_THEME
          : EMPLOYEE_THEME
      )
    );
  }, [userRole]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default CustomThemeProvider;
