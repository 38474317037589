import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Button, CircularProgress } from '@mui/material';
import { size } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  onClick: () => void;
}

const CreateButton: React.FunctionComponent<Props> = ({ onClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.boxCreateButton}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        data-testid="submit-button"
        onClick={onClick}
        className={classes.createButton}
      >
        <AddIcon />
        {t('Create')}
      </Button>
    </Box>
  );
};

export default CreateButton;
