import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const SUSPEND_USER_MUTATION = loader('../graphql/suspendUser.graphql');

interface CachedData {
  [T: string]: unknown;
}

const useSuspendUserMutation = (): ((uuid: string, type: string) => void) => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [suspendUser] = useMutation(SUSPEND_USER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return (uuid: string, type: string): void => {
    suspendUser({
      variables: { uuid },
      update(cache, { data: updateData }) {
        const success = get(updateData, 'suspendUser.success');
        if (success) {
          showNotification(t('User suspended successfully'), 'success');
        } else {
          showNotification(t('User could not be suspended'), 'warning');
        }
      }
    });
  };
};

export default useSuspendUserMutation;
