import React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Unauthorized: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h2" component="h1" gutterBottom>
        {t('Unauthorized')}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {t("You don't have permission to view this page")}
      </Typography>
      <Button
        variant="contained"
        onClick={(): void => {
          navigate('/dashboard');
        }}
      >
        {t('Visit dashboard')}
      </Button>
    </>
  );
};

export default Unauthorized;
