import React from 'react';
import {
  Grid,
  Avatar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { get, first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../navigation/page-title';
import { useUserInfoContext } from '../../context/user-info-context';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    fontSize: '2.3rem',
    margin: 'auto',
    paddingTop: theme.spacing(0.5)
  },
  tableCellLabel: {
    fontWeight: 'bold'
  },
  avatarContainer: {
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    margin: theme.spacing(2, 0)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  }
}));

const UserProfile: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userInfo } = useUserInfoContext();

  const firstName = get(userInfo, 'firstName', 'F');
  const lastName = get(userInfo, 'lastName', 'L');
  const sexType = get(userInfo, 'sexType') || '';
  const idType = get(userInfo, 'idType') || '';
  const taxId = get(userInfo, 'taxId') || '';

  const initials = `${first(firstName)}${first(lastName)}`;

  const rows = [
    { label: 'Full name', value: `${firstName} ${lastName}` },
    { label: 'Gender', value: t(sexType) },
    { label: 'Identification type', value: t(idType) },
    { label: 'ID Card Number', value: taxId }
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} sm={9} md={5}>
        <Grid container spacing={1} justifyContent="center" direction="column">
          <Grid item xs>
            <PageTitle title="User profile" />
            <div className={classes.avatarContainer}>
              <Avatar className={classes.avatar}>
                {initials.toUpperCase()}
              </Avatar>
            </div>
          </Grid>
          <Grid item xs>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="user profile data summary">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.tableCellLabel}
                      >
                        {t(row.label)}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <div className={classes.buttonContainer}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="primary"
                data-testid="update-information-button"
                onClick={() => navigate('/user/update-user')}
              >
                {t('Update information')}
              </Button>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.buttonContainer}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="primary"
                data-testid="update-password-button"
                onClick={() => navigate('/user/update-password')}
              >
                {t('Update password')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
