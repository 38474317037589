import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import RequestImage from '../../assets/images/request.png';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative'
  },
  image: {
    [theme.breakpoints.down('md')]: {
      width: '85%'
    },
    [theme.breakpoints.up('md')]: {
      width: '90%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%'
    },
    marginBottom: '-20px',
    marginLeft: '20px'
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      bottom: '15%',
      height: '30px',
      width: '65%'
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '13%',
      height: '38px',
      width: '75%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '65%'
    }
  },
  text: {
    fontSize: '16px',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '15px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerWelcome: {
    position: 'relative',
    color: 'white',
    width: '253px',
    height: '67px',
    backgroundColor: '#111827',
    clipPath:
      'path("M253.5 0C140.3 20.4 37.3333 8.5 0 0C11.2 34 4.66667 59.1667 0 67.5H253.5C245.1 45.1 250 13.1667 253.5 0Z")'
  }
}));

interface Props {
  children: React.ReactNode;
  title: string;
}

const RequestStepWrapper: React.FunctionComponent<Props> = ({
  children,
  title
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={8} md={12}>
        <div className={classes.imageContainer}>
          <Typography align="center" className={classes.text}>
            {t(title)}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={12}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={10} sm={10} md={9} lg={8}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RequestStepWrapper;
