import React, { useCallback, useState } from 'react';
import { debounce, get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveAlt';

import PaginatedTable, {
  ColumnProps,
  RowProps
} from '../tables/paginated-table';
import LoadingNotification from '../notifications/loading-notification';
import useCompaniesPagedQuery from '../../hooks/useCompaniesPagedQuery';
import useDeleteCompanyMutation from '../../hooks/useDeleteCompanyMutation';
import PageTitle from '../navigation/page-title';
import { formatDate } from '../../utils/formatters';
import { CompaniesListActions } from './companies-list-actions';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'identifier', label: 'Identifier' },
  { id: 'taxId', label: 'Tax Id' },
  { id: 'legalName', label: 'Legal Name' },
  { id: 'contractStartDate', label: 'Contract Start Date' },
  { id: 'contractEndDate', label: 'Contract End Date' },
  { id: 'isAutoRenew', label: 'Auto Renew' },
  { id: 'hasApiConnection', label: 'Has Api connection' },
  { id: 'contract', label: 'Contract' }
];

const CompaniesListPaged: React.FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { companiesPaged, loading, fetchMore } =
    useCompaniesPagedQuery(searchTerm);

  const deleteCompanyMutation = useDeleteCompanyMutation();
  const totalCount = get(companiesPaged, 'totalCount');
  const pageInfo = get(companiesPaged, 'pageInfo');
  const companies = get(companiesPaged, 'edges');
  const handleSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  if (!companies) {
    return (
      <>
        <LoadingNotification />
        <CompaniesListActions handleSearch={handleSearch} navigate={navigate} />
      </>
    );
  }

  const filterResults = (): RowProps[] => {
    return companies.map((entry) => {
      const company = get(entry, 'node');
      const contract = get(company, 'contract');
      const contractStartDate = get(company, 'contractStartDate');
      const contractEndDate = get(company, 'contractEndDate');

      return {
        uuid: get(company, 'uuid'),
        identifier: get(company, 'identifier'),
        taxId: get(company, 'taxId'),
        legalName: get(company, 'legalName'),
        contractStartDate: contractStartDate
          ? formatDate(contractStartDate)
          : contractStartDate,
        contractEndDate: contractEndDate
          ? formatDate(contractEndDate)
          : contractEndDate,
        isAutoRenew: get(company, 'isAutoRenew') ? t('Yes') : t('No'),
        hasApiConnection: get(company, 'hasApiConnection') ? t('Yes') : t('No'),
        contract: contract ? (
          <IconButton
            href={contract}
            download={contract}
            target="_blank"
            size="large"
          >
            <SaveIcon />
          </IconButton>
        ) : (
          ''
        )
      };
    });
  };

  return (
    <>
      <PageTitle title="Companies" />
      <CompaniesListActions handleSearch={handleSearch} navigate={navigate} />

      <PaginatedTable
        columns={TABLE_COLUMNS}
        isLoading={loading}
        rows={filterResults()}
        count={totalCount}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        handleEdit={(uuid: string): void => {
          navigate(`/companies/${uuid}/edit`);
        }}
        handleDelete={(uuid: string): void => {
          deleteCompanyMutation(uuid);
        }}
      />
    </>
  );
};

export default CompaniesListPaged;
