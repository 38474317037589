import React from 'react';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { Formik, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import useUserPasswordResetByTaxIdMutation from '../../hooks/useUserPasswordResetByTaxIdMutation';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import ForgotPasswordWrapper from './forgot-password-wrapper';

const VALIDATION_SCHEMA = yup.object({
  taxId: yup.string().required(REQUIRED_FIELD_TEXT)
});

const INITIAL_VALUES = {
  taxId: ''
};

interface Props {
  taxId: string;
}

const LoginForm: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { sendEmail, loading } = useUserPasswordResetByTaxIdMutation(true);

  const onReturn = (): void => {
    navigate('/login');
  };

  const onSubmit = (values: Props): void => {
    sendEmail(values);
  };

  return (
    <ForgotPasswordWrapper
      title="Request your user"
      subtitle="Please enter your tax id, we will send you an email with information to access our platform"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }): void => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }): React.ReactNode => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="taxId" label="Tax Id" component={TextInput} />
                </Grid>
                <Grid item xs={6}>
                  <SubmitButton isLoading={loading} buttonText="Send email" />
                </Grid>
                <Grid item xs={6}>
                  <SimpleButton
                    title="Go Back"
                    inheritColor={true}
                    buttonId="return-button"
                    onClick={onReturn}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </ForgotPasswordWrapper>
  );
};

export default LoginForm;
