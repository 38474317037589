import React from 'react';
import { v4 as uuid } from 'uuid';

import { convertToCents } from '../../utils/formatters';
import PriceRangeDialogWrapper from './price-range-dialog-wrapper';
import { usePriceRangesContext } from './price-ranges-context';
import PriceRangeCreateForm, { FormValues } from './price-range-create-form';
import {
  getMinimumValueForCreatePriceRange,
  validationSchemaForNewPriceRange
} from './utils';

const INITIAL_VALUES = {
  minimum: '',
  maximum: '',
  cost: ''
};

const PriceRangeCreate: React.FunctionComponent = () => {
  const {
    openCreateDialog,
    setOpenCreateDialog,
    priceRanges,
    addNewPriceRange
  } = usePriceRangesContext();

  const { minimumValue } = getMinimumValueForCreatePriceRange(priceRanges);
  const validationSchema = validationSchemaForNewPriceRange(minimumValue);

  const handleCloseDialog = (): void => {
    setOpenCreateDialog(false);
  };

  const handleAddNewPriceRange = (values: FormValues): void => {
    addNewPriceRange({
      uuid: uuid(),
      isNew: true,
      rangeStart: convertToCents(values.minimum),
      rangeEnd: convertToCents(values.maximum),
      price: convertToCents(values.cost)
    });
  };

  return (
    <PriceRangeDialogWrapper
      title="Create price range"
      open={openCreateDialog}
      handleClose={handleCloseDialog}
    >
      <PriceRangeCreateForm
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={handleAddNewPriceRange}
      />
    </PriceRangeDialogWrapper>
  );
};

export default PriceRangeCreate;
