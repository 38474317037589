import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { CompanyType } from '../definitions.d';

const CREATE_COMPANY_MUTATION = loader('../graphql/createCompany.graphql');
const COMPANIES_QUERY = loader('../graphql/companies.graphql');

export interface CreateCompanyProps {
  buildingNumber: string;
  cityUuid: string;
  commercialName: string;
  contractEndDate: string;
  contractStartDate: string;
  payDay: number;
  countryUuid: string;
  echekExecutive?: string;
  hasApiConnection?: boolean;
  identifier: string;
  industrySegmentUuid: string;
  isAutoRenew?: boolean;
  legalName: string;
  mainStreet: string;
  maxWithdrawalPercent?: number;
  maxWithdrawalsPerMonth?: number;
  waitingDays?: number;
  minMonths?: number;
  minWithdrawalAmount?: number;
  neighborhoodUuid?: string;
  onDemandApi?: boolean;
  onDemandApiAuthUrl?: string;
  onDemandApiInfoUrl?: string;
  onDemandApiUser?: string;
  onDemandApiPassword?: string;
  onDemandApiCompanyCode?: string;
  platformFee?: number;
  require2fa?: boolean;
  secondaryStreet?: string;
  serviceFee?: number;
  stateUuid: string;
  taxId: string;
  taxPercent?: number;
  transferFee?: number;
  zipCode?: string;
}

interface CreateCompanyResponse {
  createCompany: (values: CreateCompanyProps) => void;
  loading?: boolean;
  error?: Error;
}

const useCreateCompanyMutation = (): CreateCompanyResponse => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation(CREATE_COMPANY_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    onCompleted: () => {
      showNotification(t('Company created successfully'), 'success');
      navigate('/companies');
    }
  });

  return {
    createCompany: (values: CreateCompanyProps): void => {
      mutation({
        variables: omitBy(values, (value) => !value),
        update(cache, { data }) {
          const createdCompany: CompanyType = get(
            data,
            'createCompany.company'
          );
          let cachedValues;
          try {
            cachedValues = cache.readQuery({ query: COMPANIES_QUERY });
          } catch {
            cachedValues = {};
          }
          const cachedCompanies: CompanyType[] = get(
            cachedValues,
            'companies'
          ) as unknown as CompanyType[];
          if (cachedCompanies) {
            cache.writeQuery({
              query: COMPANIES_QUERY,
              data: {
                companies: cachedCompanies.concat(createdCompany)
              }
            });
          }
        }
      });
    },
    loading,
    error
  };
};

export default useCreateCompanyMutation;
