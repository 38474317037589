import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, blue } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';

import { VariantType } from './notification';
import { get } from 'lodash';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: blue[500],
    position: 'relative', // Ensure the SnackbarContent is positioned relatively
    paddingTop: theme.spacing(1) // Add padding to the top so that the close button doesn't overlap the message
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    position: 'relative', // Ensure the SnackbarContent is positioned relatively
    paddingTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '4fr 1fr'
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative', // Ensure the SnackbarContent is positioned relatively
    paddingTop: theme.spacing(1) // Add padding to the top so that the close button doesn't overlap the message
  },
  warning: {
    backgroundColor: amber[700],
    position: 'relative', // Ensure the SnackbarContent is positioned relatively
    paddingTop: theme.spacing(4) // Add padding to the top so that the close button doesn't overlap the message
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  close_error: {
    backgroundColor: theme.palette.error.dark,
    position: 'relative', // Ensure the SnackbarContent is positioned relatively
    paddingTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  }
}));

interface NotificationWrapperProps {
  message: string;
  onClose: (e: React.MouseEvent) => void;
  variant: VariantType;
}

const NotificationWrapper: React.FunctionComponent<NotificationWrapperProps> = (
  props: NotificationWrapperProps
) => {
  const classes = useStyles();
  const { message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const errorClass = get(classes, `close_${variant}`, '');

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-notification"
      message={
        <span id="client-notification" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          size="large"
          className={errorClass}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

export default NotificationWrapper;
