import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { MenuItem } from './menu-items-list';
import SimpleMenuItem from './simple-menu-item';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingLeft: '5px',
    backgroundColor: theme.palette.grey[200]
  },
  itemText: {
    fontSize: '12px'
  },
  icon: {
    minWidth: '40px'
  }
}));

const ComplexMenuItem: React.FunctionComponent<MenuItem> = ({
  label,
  icon,
  items
}: MenuItem) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItem
        button
        onClick={(): void => {
          setOpen(!open);
        }}
      >
        <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography className={classes.itemText}>{t(label)}</Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {Array.isArray(items) && items.length ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((item) => (
              <div className={classes.menuItem} key={item.label}>
                <SimpleMenuItem
                  label={item.label}
                  icon={item.icon}
                  url={item.url as string}
                />
              </div>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export default ComplexMenuItem;
