import React from 'react';

import SimpleMenuItem from './simple-menu-item';
import ComplexMenuItem from './complex-menu-item';
import {
  DASHBOARD_MENU,
  COMPANY_MANAGER_COMPANIES_MENU,
  ADVANCES_MENU,
  EMPLOYEES_MENU,
  LOGOUT_MENU,
  USER_PROFILE_MENU
} from './menu-items-list';

const CompanyManagerSidebarMenu: React.FunctionComponent = () => {
  return (
    <>
      <SimpleMenuItem {...DASHBOARD_MENU} />
      <ComplexMenuItem {...COMPANY_MANAGER_COMPANIES_MENU} />
      <ComplexMenuItem {...ADVANCES_MENU} />
      <ComplexMenuItem {...EMPLOYEES_MENU} />
      <ComplexMenuItem {...USER_PROFILE_MENU} />
      <SimpleMenuItem {...LOGOUT_MENU} />
    </>
  );
};

export default CompanyManagerSidebarMenu;
