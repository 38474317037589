import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';

import { useUserInfoContext } from '../../context/user-info-context';
import { NavigationDrawerProvider } from '../../context/navigation-drawer-context';
import AppBar from './app-bar';
import NavigationDrawer from './navigation-drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

interface Props {
  children: React.ReactNode;
}

const ApplicationWrapper: React.FunctionComponent<Props> = ({
  children
}: Props) => {
  const classes = useStyles();

  const { userInfo } = useUserInfoContext();

  return (
    <div className={classes.root}>
      <NavigationDrawerProvider>
        <AppBar />
        {userInfo ? <NavigationDrawer /> : null}
      </NavigationDrawerProvider>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container component="main" maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
};

export default ApplicationWrapper;
