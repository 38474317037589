/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  CircularProgress,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import AlertDialog from '../dialogs/alert-dialog';
import { PageInfo, ChangePageArgs } from '../../definitions.d';
import { PAGE_SIZE } from '../../config/constants';
import TableRowActions from './table-row-actions';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold'
  },
  noResults: {
    width: '100%',
    textAlign: 'center',
    padding: '20px'
  },
  noWrap: {
    textWrap: 'nowrap'
  }
});

export type AlignmentOptions =
  | 'inherit'
  | 'left'
  | 'center'
  | 'right'
  | 'justify';

export interface ColumnProps {
  id: string;
  label: string;
  minWidth?: number;
  align?: AlignmentOptions;
}

export interface RowProps {
  uuid: string;
  [T: string]: React.ReactNode;
}

export interface PaginatedTableProps {
  columns: ColumnProps[];
  rows?: RowProps[];
  count: number;
  pageInfo: PageInfo;
  fetchMore: (values: ChangePageArgs | null) => void;
  handleEdit?: (T: string) => void;
  handleDelete?: (T: string) => void;
  handleSuspend?: (T: string) => void;
  handleResume?: (T: string) => void;
  isLoading?: boolean;
  resetPage?: boolean;
}
const PaginatedTable: React.FunctionComponent<PaginatedTableProps> = ({
  columns,
  rows,
  count,
  pageInfo,
  fetchMore,
  handleEdit,
  handleDelete,
  handleSuspend,
  handleResume,
  isLoading = false,
  resetPage
}: PaginatedTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rowId, setRowId] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState<boolean>(false);
  const [openResumeDialog, setOpenResumeDialog] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const { startCursor, endCursor } = pageInfo;

    if (newPage === 0) {
      fetchMore(null);
    } else if (newPage > page) {
      fetchMore({ after: endCursor });
    } else if (newPage < page) {
      fetchMore({ before: startCursor });
    }

    setPage(newPage);
  };

  useEffect(() => {
    if (resetPage && page !== 0) {
      setPage(0);
    }
  }, [page, resetPage]);

  return (
    <>
      {handleEdit && handleDelete ? (
        <AlertDialog
          open={openDeleteDialog}
          title={t('Remove')}
          content={t('Are you sure you want to remove?')}
          handleClose={(): void => {
            setOpenDeleteDialog(false);
          }}
          handleConfirm={(): void => {
            if (rowId) {
              setOpenDeleteDialog(false);
              handleDelete(rowId);
            }
          }}
        />
      ) : null}
      {handleSuspend ? (
        <AlertDialog
          open={openSuspendDialog}
          title={t('Suspend')}
          content={t('Are you sure you want to suspend?')}
          handleClose={(): void => {
            setOpenSuspendDialog(false);
          }}
          handleConfirm={(): void => {
            if (rowId) {
              setOpenSuspendDialog(false);
              handleSuspend(rowId);
            }
          }}
        />
      ) : null}
      {handleResume ? (
        <AlertDialog
          open={openResumeDialog}
          title={t('Resume')}
          content={t('Are you sure you want to resume?')}
          handleClose={(): void => {
            setOpenResumeDialog(false);
          }}
          handleConfirm={(): void => {
            if (rowId) {
              setOpenResumeDialog(false);
              handleResume(rowId);
            }
          }}
        />
      ) : null}
      <>
        {rows && rows.length && !isLoading ? (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.header}
                        variant="head"
                        style={{ minWidth: column.minWidth }}
                      >
                        {t(column.label)}
                      </TableCell>
                    ))}
                    {handleEdit && handleDelete ? (
                      <TableCell
                        align="center"
                        className={classes.header}
                        variant="head"
                        size="small"
                      >
                        {t('Action')}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.uuid}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              variant="body"
                              style={{ minWidth: column.minWidth }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                        {handleEdit && handleDelete ? (
                          <TableCell
                            align="center"
                            variant="body"
                            size="medium"
                            className={classes.noWrap}
                          >
                            <TableRowActions
                              handleDelete={(): void => {
                                setRowId(row.uuid);
                                setOpenDeleteDialog(true);
                              }}
                              handleEdit={(): void => {
                                handleEdit(row.uuid);
                              }}
                              handleSuspend={
                                handleSuspend && !row.isSuspended
                                  ? (): void => {
                                      setRowId(row.uuid);
                                      setOpenSuspendDialog(true);
                                    }
                                  : undefined
                              }
                              handleResume={
                                handleResume && row.isSuspended
                                  ? (): void => {
                                      setRowId(row.uuid);
                                      setOpenResumeDialog(true);
                                    }
                                  : undefined
                              }
                              id={row.uuid}
                            />
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[PAGE_SIZE]}
              component="div"
              count={count}
              rowsPerPage={PAGE_SIZE}
              page={page}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ from, to, count: total }) =>
                `${from}-${to} ${t('of')} ${total > 0 ? total : 0}`
              }
            />
          </>
        ) : null}
        {isLoading ? (
          <Box className={classes.noResults}>
            <CircularProgress />
            <Box component="h4">{t('Loading')}</Box>
          </Box>
        ) : null}
        {Array.isArray(rows) && !rows.length && !isLoading ? (
          <Box className={classes.noResults}>
            <Box component="h4">{t('No results were found')}</Box>
          </Box>
        ) : null}
      </>
    </>
  );
};

export default PaginatedTable;
