import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { AdvanceType } from '../definitions.d';

const CREATE_CASH_MANAGEMENT_MUTATION = loader(
  '../graphql/createCashManagementFile.graphql'
);
const ADVANCES_QUERY = loader('../graphql/advances.graphql');

export interface CreateCashManagementFileProps {
  advanceUuids: string[];
  bankAccountUuid: string;
  identifier: string;
}

export interface CashManagementFile {
  identifier: string;
  url: string;
}

interface CreateCashManagementFileResponse {
  createCashManagementFile: (values: CreateCashManagementFileProps) => void;
  cashManagementFiles: CashManagementFile[];
  loading?: boolean;
  error?: Error;
}

const useCreateCashManagementFileMutation =
  (): CreateCashManagementFileResponse => {
    const { showNotification } = useNotificationContext();

    const [mutation, { data, loading, error }] = useMutation(
      CREATE_CASH_MANAGEMENT_MUTATION,
      {
        onError: (e) => {
          showNotification(e.message, 'error');
        }
      }
    );

    return {
      createCashManagementFile: (
        values: CreateCashManagementFileProps
      ): void => {
        mutation({
          variables: omitBy(values, (value) => !value),
          update(cache) {
            const query = {
              query: ADVANCES_QUERY,
              variables: { status: 'REQUESTED' }
            };
            const advances: AdvanceType[] = get(
              cache.readQuery(query) as AdvanceType[],
              'advances',
              []
            );
            console.log(advances);
            cache.writeQuery({
              ...query,
              data: {
                advances: advances?.filter(
                  (advance) => values.advanceUuids.indexOf(advance.uuid) === -1
                )
              }
            });
          }
        });
      },
      cashManagementFiles: get(
        data,
        'createCashManagementFile.cashManagementFiles',
        []
      ),
      error,
      loading
    };
  };

export default useCreateCashManagementFileMutation;
