import React from 'react';
import TextField from '@mui/material/TextField';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { get } from 'lodash';

const TextInput: React.FunctionComponent<TextFieldProps> = (
  props: TextFieldProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const textInputProps = fieldToTextField(props);
  return (
    <FormControl className={classes.formControl}>
      <TextField
        {...textInputProps}
        label={
          textInputProps.label
            ? t(`${textInputProps.label}`)
            : textInputProps.label
        }
        inputProps={{
          'data-testid': `input-${textInputProps.name}`,
          inputMode: get(textInputProps, 'inputMode', 'text')
        }}
        helperText={
          textInputProps.helperText
            ? t(`${textInputProps.helperText}`)
            : undefined
        }
      />
    </FormControl>
  );
};

export default TextInput;
