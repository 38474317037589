import React from 'react';
import { Grid, TextField, FormControl, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import FormSectionTitle from '../forms/form-section-title';
import { CompanyManager } from './company-form';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  managersMessage: {
    fontSize: '16px'
  }
}));

interface Props {
  managers: CompanyManager[];
}

const ManagersForm: React.FunctionComponent<Props> = ({ managers }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} />
      <FormSectionTitle text="Company administrators" />
      <Grid item xs={12}>
        {managers.length ? (
          managers.map((manager) => (
            <Grid container spacing={3} key={manager.contactEmail}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="contactName"
                    label={t('Contact name')}
                    disabled
                    value={manager.contactName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="contactEmail"
                    label={t('Contact email')}
                    disabled
                    value={manager.contactEmail}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="contactPhone"
                    label={t('Contact phone')}
                    disabled
                    value={manager.contactPhone}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              className={classes.managersMessage}
            >
              {t(
                'To have contact information please associate at least one manager to the company'
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManagersForm;
