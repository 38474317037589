import React from 'react';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { fieldToCheckbox, CheckboxProps } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface CheckboxInputProps extends CheckboxProps {
  label: string;
}

const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = (
  props: CheckboxInputProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const checkboxProps = fieldToCheckbox(props);

  return (
    <FormControl className={clsx(classes.formControl, classes.checkbox)}>
      <FormControlLabel
        control={
          <Checkbox
            {...checkboxProps}
            checked={!!checkboxProps.checked}
            onChange={(event): void => {
              const checked = event.target.checked;
              props.form.setFieldValue(props.field.name, checked);
            }}
          />
        }
        label={t(props.label)}
        className={classes.checkboxLabel}
      />
    </FormControl>
  );
};

export default CheckboxInput;
