import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik, Field, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import DateInput from '../forms/date-input';
import SelectInput from '../forms/select-input';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import CheckboxInput from '../forms/checkbox-input';
import {
  REQUIRED_FIELD_TEXT,
  DECIMAL_VALUE_VALIDATION_MESSAGE,
  REGULAR_EXPRESSION_DECIMAL,
  MAX_WAITING_DAYS_MESSAGE
} from '../../config/constants';
import AutocompleteInput from '../forms/autocomplete-input';
import LocationAutocompleteInput from '../forms/location-autocomplete-input';
import FormSectionTitle from '../forms/form-section-title';
import { SelectItemType, ErolUserRoles } from '../../definitions.d';
import { useUserInfoContext } from '../../context/user-info-context';
import UpdateFileInput from '../forms/upload-file-input';
import PasswordInput from '../forms/password-input';
import ManagersForm from './managers-form';

export type FormValues = {
  buildingNumber: string;
  city: SelectItemType | null;
  commercialName: string;
  contract?: File | null;
  contractEndDate: string;
  contractStartDate: string;
  payDay: number;
  country: SelectItemType | null;
  echekExecutive?: string;
  hasApiConnection?: boolean;
  identifier: string;
  industrySegmentUuid: string;
  isAutoRenew?: boolean;
  legalName: string;
  mainStreet: string;
  maxWithdrawalPercent?: string;
  maxWithdrawalsPerMonth?: string;
  waitingDays?: string;
  minMonths?: string;
  minWithdrawalAmount?: string;
  neighborhood?: SelectItemType | null;
  onDemandApi?: boolean;
  onDemandApiAuthUrl?: string;
  onDemandApiInfoUrl?: string;
  onDemandApiUser?: string;
  onDemandApiPassword?: string;
  onDemandApiCompanyCode?: string;
  platformFee?: string;
  require2fa?: boolean;
  secondaryStreet?: string;
  serviceFee?: string;
  state: SelectItemType | null;
  taxId: string;
  taxPercent?: string;
  transferFee?: string;
  zipCode?: string;
  uuid?: string;
};

const validationSchema = yup.object({
  buildingNumber: yup.string().required(REQUIRED_FIELD_TEXT),
  city: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  commercialName: yup.string().required(REQUIRED_FIELD_TEXT),
  contract: yup.mixed().nullable(),
  contractEndDate: yup.string().required(REQUIRED_FIELD_TEXT),
  contractStartDate: yup.string().required(REQUIRED_FIELD_TEXT),
  payDay: yup.number().min(1).required(),
  country: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  echekExecutive: yup.string().nullable(),
  hasApiConnection: yup.boolean(),
  identifier: yup.string().required(REQUIRED_FIELD_TEXT).max(6),
  industrySegmentUuid: yup.string().required(REQUIRED_FIELD_TEXT),
  isAutoRenew: yup.boolean(),
  legalName: yup.string().required(REQUIRED_FIELD_TEXT),
  mainStreet: yup.string().required(REQUIRED_FIELD_TEXT),
  maxWithdrawalPercent: yup.number(),
  maxWithdrawalsPerMonth: yup.number(),
  waitingDays: yup
    .number()
    .min(0)
    .max(180, MAX_WAITING_DAYS_MESSAGE)
    .nullable(),
  minMonths: yup.number(),
  minWithdrawalAmount: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  neighborhood: yup
    .object({
      value: yup.string(),
      label: yup.string()
    })
    .nullable(),
  onDemandApi: yup.boolean(),
  onDemandApiAuthUrl: yup.string().nullable(),
  onDemandApiInfoUrl: yup.string().nullable(),
  onDemandApiUser: yup.string().nullable(),
  onDemandApiPassword: yup.string().nullable(),
  onDemandApiCompanyCode: yup.string().nullable(),
  platformFee: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  require2fa: yup.boolean(),
  secondaryStreet: yup.string(),
  serviceFee: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  state: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  taxId: yup.number().required(REQUIRED_FIELD_TEXT),
  taxPercent: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  transferFee: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  zipCode: yup.string().nullable()
});

export interface CompanyManager {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

interface Props {
  initialValues: FormValues;
  isLoading?: boolean;
  onSubmit: (T: FormValues) => void;
  locations: SelectItemType[];
  industrySegments: SelectItemType[];
  isCreateForm?: boolean;
  companyUuid?: string;
  managers?: CompanyManager[];
}

const CompanyForm: React.FunctionComponent<Props> = ({
  initialValues,
  isLoading,
  onSubmit,
  locations,
  industrySegments,
  isCreateForm,
  companyUuid,
  managers
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userRole } = useUserInfoContext();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(
        values: FormValues,
        actions: FormikHelpers<FormValues>
      ): void => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      enableReinitialize
    >
      {(props): React.ReactNode => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <Field
                  name="legalName"
                  label="Legal Name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="commercialName"
                  label="Commercial Name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="taxId" label="Tax Id" component={TextInput} />
              </Grid>
              {companyUuid ? (
                <Grid item xs={12} sm={6}>
                  <Field
                    name="uuid"
                    label="UUID"
                    component={TextInput}
                    disabled={true}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}>
                <Field
                  name="industrySegmentUuid"
                  label="Industry Segment"
                  component={SelectInput}
                  items={industrySegments}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="require2fa"
                  label="Require 2FA"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="echekExecutive"
                  label="Echek Executive"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="hasApiConnection"
                  label="Has API connection"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="onDemandApi"
                  label="On demand queries"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="onDemandApiAuthUrl"
                  label="On demand API auth URL"
                  component={TextInput}
                  disabled={!props.values.onDemandApi}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="onDemandApiInfoUrl"
                  label="On demand API info URL"
                  component={TextInput}
                  disabled={!props.values.onDemandApi}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="onDemandApiUser"
                  label="On demand API user"
                  component={TextInput}
                  disabled={!props.values.onDemandApi}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="onDemandApiPassword"
                  label="On demand API password"
                  component={PasswordInput}
                  disabled={!props.values.onDemandApi}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="onDemandApiCompanyCode"
                  label="On demand API company code"
                  component={TextInput}
                  disabled={!props.values.onDemandApi}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="identifier"
                  label="Identifier"
                  component={TextInput}
                  disabled={!isCreateForm}
                />
              </Grid>
              <FormSectionTitle text="Company location" />
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  label="Country"
                  component={AutocompleteInput}
                  items={locations}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="state"
                  label="State"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  label="City"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="neighborhood"
                  label="Neighborhood"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="mainStreet"
                  label="Main street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="secondaryStreet"
                  label="Secondary street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="buildingNumber"
                  label="Building number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="zipCode" label="Zip code" component={TextInput} />
              </Grid>
              {!isCreateForm && managers ? (
                <ManagersForm managers={managers} />
              ) : null}
              <Grid item xs={12} />
              <FormSectionTitle text="Contract information" />
              <Grid item xs={12} sm={6}>
                <Field
                  name="contractStartDate"
                  label="Contract Start Date"
                  component={DateInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="contractEndDate"
                  label="Contract End Date"
                  component={DateInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="isAutoRenew"
                  label="Auto Renew"
                  component={CheckboxInput}
                  type="checkbox"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              {userRole === ErolUserRoles.PLATFORM_MANAGER && !isCreateForm ? (
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom align="left">
                    {t('Upload contract')}
                  </Typography>
                  <Field
                    name="contract"
                    component={UpdateFileInput}
                    disabled={
                      userRole !== ErolUserRoles.PLATFORM_MANAGER &&
                      !isCreateForm
                    }
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}>
                <Field
                  name="payDay"
                  label="Pay Day"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="maxWithdrawalPercent"
                  label="Max Withdrawal Percent"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="maxWithdrawalsPerMonth"
                  label="Max Withdrawals Per Month"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="waitingDays"
                  label="Waiting days"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="minMonths"
                  label="Min Months"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="minWithdrawalAmount"
                  label="Min Withdrawal Amount"
                  component={TextInput}
                  type="number"
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} />
              <Grid item xs={12} sm={6}>
                <Field
                  name="serviceFee"
                  label="Service Fee"
                  component={TextInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="platformFee"
                  label="Platform Fee"
                  component={TextInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="transferFee"
                  label="Transfer Fee"
                  component={TextInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="taxPercent"
                  label="Tax Percent"
                  component={TextInput}
                  disabled={
                    userRole !== ErolUserRoles.PLATFORM_MANAGER && !isCreateForm
                  }
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SubmitButton
                  isLoading={isLoading}
                  buttonText={isCreateForm ? 'Create' : 'Save'}
                />
              </Grid>
              {companyUuid ? (
                <Grid item xs={6} sm={3}>
                  <SimpleButton
                    title="Price ranges"
                    inheritColor={true}
                    buttonId="price-ranges-button"
                    onClick={(): void => {
                      navigate(`/companies/${companyUuid}/price-ranges`);
                    }}
                  />
                </Grid>
              ) : null}
              {initialValues.hasApiConnection ? (
                <Grid item xs={6} sm={3}>
                  <SimpleButton
                    title="Banks lookup"
                    inheritColor={true}
                    buttonId="banks-lookup-button"
                    onClick={(): void => {
                      navigate(`/companies/${companyUuid}/banks`);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6} sm={3}>
                <SimpleButton
                  title="Go Back"
                  inheritColor={true}
                  buttonId="return-button"
                  onClick={(): void => {
                    navigate('/companies');
                  }}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default CompanyForm;
