import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useNotificationContext } from '../context/notification-context';

const UPDATE_CURRENT_USER_PASSWORD_MUTATION = loader(
  '../graphql/updateCurrentUserPassword.graphql'
);

export interface UpdateCurrentUserPasswordMutationProps {
  password: string;
  passwordConfirm: string;
}

interface Response {
  updateCurrentUserPassword: (
    values: UpdateCurrentUserPasswordMutationProps
  ) => void;
  loading?: boolean;
  error?: Error;
}

const useUpdateCurrentUserPasswordMutation = (): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [mutation, { loading, error }] = useMutation(
    UPDATE_CURRENT_USER_PASSWORD_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      onCompleted: () => {
        showNotification(t('Password was changed successfully'), 'success');
      }
    }
  );

  return {
    loading,
    error,
    updateCurrentUserPassword: (
      values: UpdateCurrentUserPasswordMutationProps
    ): void => {
      mutation({
        variables: { ...values }
      });
    }
  };
};

export default useUpdateCurrentUserPasswordMutation;
