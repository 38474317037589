import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import RequestedImage from '../../assets/images/requested.png';
import { useRequestContext } from '../../context/request/request-context';
import { INITIAL_STATE, RequestAction } from '../../context/request/reducer';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative'
  },
  image: {
    [theme.breakpoints.down('md')]: {
      width: '85%'
    },
    [theme.breakpoints.up('md')]: {
      width: '90%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%'
    }
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      bottom: '18%',
      height: '30px',
      width: '65%'
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '16%',
      height: '38px',
      width: '75%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '65%'
    }
  },
  text: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '16px'
    }
  }
}));

const RequestedIndicator: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { dispatch } = useRequestContext();

  useEffect(() => {
    return () => {
      dispatch({ type: RequestAction.RESET, payload: INITIAL_STATE });
    };
  }, [dispatch]);

  return (
    <>
      <Grid item xs={12} sm={8} md={6}>
        <div className={classes.imageContainer}>
          <div className={classes.textContainer}>
            <Typography align="center" className={classes.text}>
              {t('Your request has been successfully processed')}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid xs={12} />
      <Grid item xs={6} sm={5} md={4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={(): void => {
            navigate('/dashboard');
          }}
        >
          {t('Dashboard')}
        </Button>
      </Grid>
    </>
  );
};

export default RequestedIndicator;
