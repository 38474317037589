import React, { createContext, useContext, useState, useCallback } from 'react';

export interface NavigationDrawerContextProps {
  openDrawer: boolean;
  toggleDrawer: () => void;
}

export interface NavigationDrawerProviderProps {
  children: React.ReactNode;
}

export const NavigationDrawerContext =
  createContext<NavigationDrawerContextProps>(
    {} as NavigationDrawerContextProps
  );

export const NavigationDrawerProvider: React.FunctionComponent<
  NavigationDrawerProviderProps
> = ({ children }: NavigationDrawerProviderProps) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const toggleDrawer = useCallback(() => {
    setOpenDrawer(!openDrawer);
  }, [openDrawer]);

  return (
    <NavigationDrawerContext.Provider
      value={{
        openDrawer,
        toggleDrawer
      }}
    >
      {children}
    </NavigationDrawerContext.Provider>
  );
};

export const useNavigationDrawerContext = (): NavigationDrawerContextProps =>
  useContext(NavigationDrawerContext);
