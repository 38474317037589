import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { UserTypeConnection, ChangePageArgs } from '../definitions.d';
import { PAGE_SIZE } from '../config/constants';
import { getFetchMoreVariables } from '../utils/utils';
import useHandleError from './useHandleError';

const EMPLOYEES_PAGED = loader('../graphql/employeesPaged.graphql');

export interface EmployeesQueryProps {
  companyUuid?: string | null;
  search?: string | null;
}
interface EmployeesQueryResponse {
  employeesPaged: UserTypeConnection;
  loading?: boolean;
  error?: Error;
  fetchMore: (values: ChangePageArgs | null) => void;
}

const useEmployeesPagedQuery = (
  props?: EmployeesQueryProps
): EmployeesQueryResponse => {
  const { handleError } = useHandleError();

  const companyUuid = get(props, 'companyUuid');
  const search = get(props, 'search');

  const { data, loading, error, fetchMore } = useQuery(EMPLOYEES_PAGED, {
    variables: {
      first: PAGE_SIZE,
      companyUuid,
      search
    },
    onError: (e) => {
      handleError(e);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  return {
    employeesPaged: get(data, 'employeesPaged'),
    loading,
    error,
    fetchMore: (values: ChangePageArgs | null) => {
      fetchMore({
        variables: {
          ...getFetchMoreVariables(values),
          companyUuid,
          search
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          return fetchMoreResult;
        }
      });
    }
  };
};

export default useEmployeesPagedQuery;
