import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { UserType } from '../definitions.d';

const DELETE_USER_MUTATION = loader('../graphql/deleteUser.graphql');
const EMPLOYEES_QUERY = loader('../graphql/employees.graphql');
const MANAGERS_QUERY = loader('../graphql/managers.graphql');

interface CachedData {
  [T: string]: unknown;
}

const useDeleteUserMutation = (): ((uuid: string, type: string) => void) => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return (uuid: string, type: string): void => {
    deleteUser({
      variables: { uuid },
      update(cache, { data: updateData }) {
        const success = get(updateData, 'deleteUser.success');
        const query = type === 'employees' ? EMPLOYEES_QUERY : MANAGERS_QUERY;
        if (success) {
          const cachedData: {
            users: UserType[];
          } | null = cache.readQuery({
            query
          });
          if (cachedData) {
            const cachedUsers: UserType[] = get(cachedData, type);
            const data = {} as CachedData;
            data[type] = cachedUsers.filter((user) => user.uuid !== uuid);
            cache.writeQuery({
              query,
              data
            });
            showNotification(t('User deleted successfully'), 'success');
          }
        } else {
          showNotification(t('User could not be deleted'), 'warning');
        }
      }
    });
  };
};

export default useDeleteUserMutation;
