import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import useBanksQuery from '../../hooks/useBanksQuery';
import { useUserInfoContext } from '../../context/user-info-context';
import { formatBanks, formatBank } from '../../utils/formatters';
import { useRequestContext } from '../../context/request/request-context';
import {
  RequestAction,
  RequestStep,
  RequestType,
  BankAccountOptions
} from '../../context/request/reducer';
import { BankAccountTypes } from '../../definitions.d';
import RequestStepWrapper from './request-step-wrapper';
import BankAccountForm, { FormProps } from './bank-account-form';

const INITIAL_VALUES = {
  bankAccount: BankAccountOptions.CUSTOM,
  bank: null,
  bankAccountNumber: '',
  bankAccountType: BankAccountTypes.AHO
};

interface Props {
  onUpdateRouterHistory: (step: string, type: string) => void;
}

const BankAccount: React.FunctionComponent<Props> = ({
  onUpdateRouterHistory
}: Props) => {
  const [initialValues, setInitialValues] = useState<FormProps>(INITIAL_VALUES);

  const { state, dispatch } = useRequestContext();

  const { banks, loading } = useBanksQuery();
  const { userInfo } = useUserInfoContext();

  const updateHistory = () => {
    onUpdateRouterHistory(RequestStep.SUMMARY, RequestType.CUSTOM);
  };

  useEffect(() => {
    if (state.bankAccount === BankAccountOptions.DEFAULT && userInfo) {
      const { bank, bankAccountNumber, bankAccountType } = userInfo;
      setInitialValues({
        bankAccount: BankAccountOptions.DEFAULT,
        bank: formatBank(bank),
        bankAccountNumber,
        bankAccountType
      });
    } else {
      setInitialValues(INITIAL_VALUES);
    }
  }, [state.bankAccount, userInfo]);

  useEffect(() => {
    if (!state.reason) {
      onUpdateRouterHistory(RequestStep.REASON, RequestType.DEFAULT);
    }
  }, [onUpdateRouterHistory, state.reason]);

  return (
    <RequestStepWrapper title="What account do we use to credit your request to?">
      <BankAccountForm
        banks={formatBanks(banks)}
        loading={loading}
        initialValues={initialValues}
        handleSubmit={(values: FormProps) => {
          dispatch({
            type: RequestAction.SET_BANK_ACCOUNT_INFORMATION,
            payload: {
              bankAccountNumber: get(values, 'bankAccountNumber'),
              bankAccountType: get(values, 'bankAccountType'),
              bankUuid: get(values, 'bank.value', '')
            }
          });
          updateHistory();
        }}
      />
    </RequestStepWrapper>
  );
};

export default BankAccount;
