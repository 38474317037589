import React from 'react';
import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';

import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import {
  REGULAR_EXPRESSION_DECIMAL,
  DECIMAL_VALUE_VALIDATION_MESSAGE,
  REQUIRED_FIELD_TEXT
} from '../../config/constants';

const VALIDATION_SCHEMA = yup.object().shape({
  amount: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
});

const INITIAL_VALUES = {
  amount: ''
};

export interface FormProps {
  amount: string;
}

interface Props {
  handleSubmit: (values: FormProps) => void;
  loading?: boolean;
}

const AmountCustomForm: React.FunctionComponent<Props> = ({
  handleSubmit,
  loading
}: Props) => {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        handleSubmit(values);
      }}
    >
      {() => (
        <Form>
          <Field
            name="amount"
            label="Amount"
            variant="outlined"
            component={TextInput}
            size="small"
            inputMode="decimal"
          />
          <SubmitButton
            isLoading={loading}
            buttonText="Continue"
            size="small"
          />
        </Form>
      )}
    </Formik>
  );
};

export default AmountCustomForm;
