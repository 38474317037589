import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitle from '../navigation/page-title';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  subtitle: {
    fontSize: '14px',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

interface Props {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

const ForgotPasswordWrapper: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  children
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <PageTitle title={title} />

        <Container maxWidth="xs">
          <Typography className={classes.subtitle}>{t(subtitle)}</Typography>
          {children}
        </Container>
      </div>
    </Container>
  );
};

export default ForgotPasswordWrapper;
