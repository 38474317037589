import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import {
  UserType,
  ErolUserSexTypes,
  ErolUserMaritalStatusTypes,
  ErolUserCompanyDepartmentTypes
} from '../definitions.d';

const UPDATE_CURRENT_USER_MUTATION = loader(
  '../graphql/updateCurrentUser.graphql'
);
const ME_QUERY = loader('../graphql/me.graphql');

export interface UpdateCurrentUserProps {
  birthDate?: string;
  buildingNumber?: string;
  cityUuid?: string;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  countryUuid?: string;
  firstName?: string;
  lastName?: string;
  mainStreet?: string;
  maritalStatus?: ErolUserMaritalStatusTypes;
  neighborhoodUuid?: string;
  secondaryStreet?: string;
  sexType?: ErolUserSexTypes;
  stateUuid?: string;
  zipCode?: string;
}

interface UpdateCurrentUserResponse {
  updateCurrentUser: (values: UpdateCurrentUserProps) => void;
  error?: Error;
  loading?: boolean;
}

const useUpdateCurrentUserMutation = (): UpdateCurrentUserResponse => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [mutation, { error, loading }] = useMutation(
    UPDATE_CURRENT_USER_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      onCompleted: () => {
        showNotification(t('User updated successfully'), 'success');
      },
      update(cache, { data: mutationResponse }) {
        const updatedUserInfo = get(mutationResponse, 'updateCurrentUser.user');
        const cachedData: {
          userInfo: UserType;
        } | null = cache.readQuery({
          query: ME_QUERY
        });
        if (cachedData) {
          cache.writeQuery({
            query: ME_QUERY,
            data: {
              me: updatedUserInfo
            }
          });
        }
      }
    }
  );

  return {
    updateCurrentUser: (values: UpdateCurrentUserProps): void => {
      mutation({ variables: omitBy(values, (value) => !value) });
    },
    loading,
    error
  };
};

export default useUpdateCurrentUserMutation;
