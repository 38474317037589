import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const MANAGE_COMPANY_BANK_MUTATION = loader(
  '../graphql/manageCompanyBank.graphql'
);
const COMPANY_BANKS_QUERY = loader('../graphql/companyBanks.graphql');

export interface CompanyBankTypeInput {
  uuid?: string;
  bankUuid: string | null;
  code: string;
}

interface Response {
  manageCompanyBank: (companyBanks: CompanyBankTypeInput[]) => void;
  loading?: boolean;
  error?: Error;
}

const useManageCompanyBankMutation = (companyUuid: string): Response => {
  const { showNotification } = useNotificationContext();

  const [mutation, { loading, error }] = useMutation(
    MANAGE_COMPANY_BANK_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      update(cache, { data }) {
        cache.writeQuery({
          query: COMPANY_BANKS_QUERY,
          variables: { uuid: companyUuid },
          data: {
            company: {
              uuid: companyUuid,
              hasApiConnection: true,
              companyBanks: get(data, 'manageCompanyBank.companyBanks'),
              __typename: 'CompanyType'
            }
          }
        });
      }
    }
  );

  return {
    loading,
    error,
    manageCompanyBank: (companyBanks: CompanyBankTypeInput[]): void => {
      mutation({
        variables: { companyUuid, companyBanks }
      });
    }
  };
};

export default useManageCompanyBankMutation;
