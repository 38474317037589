import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const RESUME_USER_MUTATION = loader('../graphql/resumeUser.graphql');

interface CachedData {
  [T: string]: unknown;
}

const useResumeUserMutation = (): ((uuid: string, type: string) => void) => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [resumeUser] = useMutation(RESUME_USER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return (uuid: string, type: string): void => {
    resumeUser({
      variables: { uuid },
      update(cache, { data: updateData }) {
        const success = get(updateData, 'resumeUser.success');
        if (success) {
          showNotification(t('User resumed successfully'), 'success');
        } else {
          showNotification(t('User could not be resumed'), 'warning');
        }
      }
    });
  };
};

export default useResumeUserMutation;
