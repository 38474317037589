import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '../context/notification-context';

const UPDATE_USER_PASSWORD_WITH_TOKEN_MUTATION = loader(
  '../graphql/updateUserPasswordWithToken.graphql'
);

export interface UpdateUserPasswordWithTokenProps {
  password: string;
  passwordConfirm: string;
  token: string;
  uuid: string;
}

interface Response {
  updateUserPasswordWithToken: (
    values: UpdateUserPasswordWithTokenProps
  ) => void;
  loading?: boolean;
  error?: Error;
}

const useUpdateUserPasswordWithTokenMutation = (): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation(
    UPDATE_USER_PASSWORD_WITH_TOKEN_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      onCompleted: () => {
        showNotification(t('Password was changed successfully'), 'success');
        navigate('/login');
      }
    }
  );

  return {
    loading,
    error,
    updateUserPasswordWithToken: (
      values: UpdateUserPasswordWithTokenProps
    ): void => {
      mutation({
        variables: { ...values }
      });
    }
  };
};

export default useUpdateUserPasswordWithTokenMutation;
