import React from 'react';

import SimpleMenuItem from './simple-menu-item';
import ComplexMenuItem from './complex-menu-item';
import {
  DASHBOARD_MENU,
  EMPLOYEE_ADVANCES_MENU,
  LOGOUT_MENU,
  USER_PROFILE_MENU
} from './menu-items-list';

const EmployeeSidebarMenu: React.FunctionComponent = () => {
  return (
    <>
      <SimpleMenuItem {...DASHBOARD_MENU} />
      <ComplexMenuItem {...EMPLOYEE_ADVANCES_MENU} />
      <ComplexMenuItem {...USER_PROFILE_MENU} />
      <SimpleMenuItem {...LOGOUT_MENU} />
    </>
  );
};

export default EmployeeSidebarMenu;
