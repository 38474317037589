import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  managersMessage: {
    fontSize: '18px',
    paddingTop: theme.spacing(2),
    color: theme.palette.grey[500]
  }
}));

interface Props {
  text: string;
}

const FormSectionTitle: React.FunctionComponent<Props> = ({ text }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Divider />
      <Typography
        variant="h5"
        gutterBottom
        align="left"
        className={classes.managersMessage}
      >
        {t(text)}
      </Typography>
    </Grid>
  );
};

export default FormSectionTitle;
