import React from 'react';

import { SelectItemType } from '../../definitions.d';
import CompanyBankForm, { FormValues } from './company-bank-form';

const INITIAL_VALUES = {
  bank: null,
  code: ''
};

interface Props {
  onCreate: (values: FormValues) => void;
  onClose: () => void;
  banks: SelectItemType[];
  loading?: boolean;
}

const CreateCompanyBank: React.FunctionComponent<Props> = ({
  onCreate,
  onClose,
  banks,
  loading
}: Props) => {
  return (
    <CompanyBankForm
      initialValues={INITIAL_VALUES}
      onSubmit={onCreate}
      onClose={onClose}
      banks={banks}
      disabled={false}
      loading={loading}
    />
  );
};

export default CreateCompanyBank;
