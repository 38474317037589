import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import SubmitButton from '../forms/submit-button';
import CheckboxInput from '../forms/checkbox-input';

const VALIDATION_SCHEMA = yup.object({
  accept: yup.boolean().required(REQUIRED_FIELD_TEXT)
});

const INITIAL_VALUES = {
  accept: false
};

interface Props {
  loading?: boolean;
  handleSubmit: () => void;
}

const AcceptConditionsForm: React.FunctionComponent<Props> = ({
  loading,
  handleSubmit
}: Props) => {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        handleSubmit();
        setSubmitting(false);
      }}
    >
      {({ values }): React.ReactNode => {
        return (
          <Form>
            <Field
              name="accept"
              label="I agree to the terms of service"
              component={CheckboxInput}
              type="checkbox"
            />
            <SubmitButton
              isLoading={loading}
              buttonText="Confirm"
              disabled={!values.accept || loading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AcceptConditionsForm;
