import React from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  END_DATE_VALIDATION_MESSAGE,
  REQUIRED_FIELD_TEXT,
  ADVANCE_STATUS_ITEMS
} from '../../config/constants';
import useStyles from '../forms/styles';
import DateInput from '../forms/date-input';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import AutocompleteInput from '../forms/autocomplete-multiple-input';
import { formatCompanies } from '../../utils/formatters';
import { CompanyType, SelectItemType } from '../../definitions.d';

const VALIDATION_SCHEMA = yup.object({
  startDate: yup.date().required(REQUIRED_FIELD_TEXT),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), END_DATE_VALIDATION_MESSAGE)
    .required(REQUIRED_FIELD_TEXT),
  statuses: yup.array().of(yup.string()).required(REQUIRED_FIELD_TEXT),
  companies: yup.array().of(
    yup.object({
      value: yup.string(),
      label: yup.string()
    })
  )
});

export interface FormProps {
  startDate: string;
  endDate: string;
  statuses: SelectItemType[];
  companies: SelectItemType[];
}

interface Props {
  onSubmit: (values: FormProps) => void;
  loading?: boolean;
  companies: CompanyType[];
  handleDownload: () => void;
  downloadDisabled: boolean;
}

const AdvancesFilterForm: React.FunctionComponent<Props> = ({
  onSubmit,
  loading,
  companies,
  handleDownload,
  downloadDisabled
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues = {
    startDate: new Date(Date.now()).toISOString(),
    endDate: new Date(Date.now()).toISOString(),
    statuses: [],
    companies: []
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        setSubmitting(false);
        onSubmit(values);
      }}
    >
      {({ handleSubmit }): React.ReactNode => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="startDate"
                  label="Start date"
                  component={DateInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="endDate" label="End date" component={DateInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="statuses"
                  label="Status"
                  component={AutocompleteInput}
                  items={ADVANCE_STATUS_ITEMS.map((status) => ({
                    value: status.value,
                    label: t(status.label)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companies"
                  label="Company"
                  component={AutocompleteInput}
                  items={formatCompanies(companies)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={10} sm={6}>
                    <SubmitButton isLoading={loading} buttonText="Search" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={10} sm={6}>
                    <SimpleButton
                      title="Download"
                      inheritColor={true}
                      buttonId="download-button"
                      onClick={handleDownload}
                      isDisabled={downloadDisabled || loading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default AdvancesFilterForm;
