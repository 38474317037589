import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';

import { useUserInfoContext } from '../../context/user-info-context';
import useAdvanceStatusQuery from '../../hooks/useAdvanceStatusQuery';
import LoadingNotification from '../notifications/loading-notification';
import { convertToDollars } from '../../utils/formatters';
import DashboardChart from './dashboard-chart';
import Summary from './summary';
import Flag from './flag';
import LabelContainer from './label-container';
import ActionContainer from './action-container';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1
  }
}));

const Dashboard: React.FunctionComponent = () => {
  const { userInfo } = useUserInfoContext();
  const classes = useStyles();

  const { loading, advanceStatus, error, refetch } = useAdvanceStatusQuery();

  const totalCount = get(advanceStatus, 'totalCount', 0);
  const currentCount = get(advanceStatus, 'currentCount', 0);
  const totalAmount = get(advanceStatus, 'totalAmount', 0);
  const amountAvailable = get(advanceStatus, 'amountAvailable', 0);
  const amountCompleted = get(advanceStatus, 'amountCompleted', 0);
  const amountInTransit = get(advanceStatus, 'amountInTransit', 0);

  if (loading) return <LoadingNotification />;

  const flags = get(userInfo, 'informativeMessage', '') as string;
  let flagsArr: string[] = [];
  if (flags && flags.trim() !== '') {
    flagsArr = flags.split('\\n');
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Grid item xs={12} sm={11} md={8}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12} sm={3} md={12}>
            <ActionContainer />
          </Grid>
          <Grid item xs={12} sm={9} md={12}>
            {flagsArr.map((flag: string, key: number) => (
              <Flag key={key} title={flag} />
            ))}

            <DashboardChart
              amountAvailable={amountAvailable}
              amountCompleted={amountCompleted}
              amountInTransit={amountInTransit}
            />
            <LabelContainer
              amountAvailable={amountAvailable}
              amountCompleted={amountCompleted}
              amountInTransit={amountInTransit}
            />

            <Summary
              title="Advances in use"
              subtitle={`${currentCount}/${totalCount}`}
            />
            <Summary
              title="Total amount"
              subtitle={`$${convertToDollars(totalAmount)}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
