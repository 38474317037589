import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Fab from '@mui/material/Fab';
import { useNavigate } from 'react-router-dom';

import { usePriceRangesContext } from './price-ranges-context';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(0, 1)
  }
}));

interface Props {
  handleSavePriceRange: () => void;
  companyUuid: string;
}

const PriceRangesGroupFooter: React.FunctionComponent<Props> = ({
  handleSavePriceRange,
  companyUuid
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDirty, setOpenCreateDialog } = usePriceRangesContext();

  const navigate = useNavigate();

  const handleOpenCreateDialog = (): void => {
    setOpenCreateDialog(true);
  };

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          className={classes.button}
          onClick={handleOpenCreateDialog}
        >
          <AddIcon />
          {t('Add price range')}
        </Fab>
        <Fab
          color="primary"
          aria-label="create"
          variant="extended"
          className={classes.button}
          disabled={!isDirty}
          onClick={handleSavePriceRange}
        >
          <Badge color="error" variant="dot" invisible={!isDirty}>
            <SaveIcon />
          </Badge>
          {t('Save')}
        </Fab>
        <Fab
          aria-label="return"
          variant="extended"
          className={classes.button}
          onClick={(): void => {
            navigate(`/companies/${companyUuid}/price-ranges`);
          }}
        >
          <ClearIcon />
          {t('Return')}
        </Fab>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default PriceRangesGroupFooter;
