import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { PriceRangesGroupType } from '../definitions.d';

const PRICE_RANGES_GROUPS_QUERY = loader(
  '../graphql/priceRangesGroups.graphql'
);

interface PriceRangesGroupsQueryResponse {
  priceRangesGroups: PriceRangesGroupType[];
  loading?: boolean;
  error?: Error;
}

const usePriceRangesGroupsQuery = (
  uuid: string
): PriceRangesGroupsQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(PRICE_RANGES_GROUPS_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return {
    priceRangesGroups: get(data, 'company.priceRangesGroups', []),
    loading,
    error
  };
};

export default usePriceRangesGroupsQuery;
