import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  TableContainer
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import AlertDialog from '../dialogs/alert-dialog';
import TableRowActions from './table-row-actions';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold'
  },
  noResults: {
    width: '100%',
    textAlign: 'center',
    padding: '20px'
  }
});

export type AlignmentOptions =
  | 'inherit'
  | 'left'
  | 'center'
  | 'right'
  | 'justify';

export interface ColumnProps {
  id: string;
  label: string;
  minWidth?: number;
  align?: AlignmentOptions;
}

export interface RowProps {
  uuid: string;
  [T: string]: React.ReactNode;
}

export interface SimpleTableProps {
  columns: ColumnProps[];
  rows?: RowProps[];
  handleEdit?: (T: string) => void;
  handleDelete?: (T: string) => void;
  isLoading?: boolean;
}

const SimpleTable: React.FunctionComponent<SimpleTableProps> = ({
  columns,
  rows,
  handleEdit,
  handleDelete,
  isLoading = false
}: SimpleTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rowId, setRowId] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  return (
    <>
      {handleEdit && handleDelete ? (
        <AlertDialog
          open={openDeleteDialog}
          title={t('Remove')}
          content={t('Are you sure you want to remove?')}
          handleClose={(): void => {
            setOpenDeleteDialog(false);
          }}
          handleConfirm={(): void => {
            if (rowId) {
              setOpenDeleteDialog(false);
              handleDelete(rowId);
            }
          }}
        />
      ) : null}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.header}
                  variant="head"
                  style={{ minWidth: column.minWidth }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
              {handleEdit && handleDelete ? (
                <TableCell
                  align="center"
                  className={classes.header}
                  variant="head"
                  size="small"
                >
                  {t('Action')}
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          {rows && rows.length && !isLoading ? (
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.uuid}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          variant="body"
                          style={{ minWidth: column.minWidth }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                    {handleEdit && handleDelete ? (
                      <TableCell align="center" variant="body" size="small">
                        <TableRowActions
                          handleDelete={(): void => {
                            setRowId(row.uuid);
                            setOpenDeleteDialog(true);
                          }}
                          handleEdit={(): void => {
                            handleEdit(row.uuid);
                          }}
                          id={row.uuid}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
        {isLoading ? (
          <Box className={classes.noResults}>
            <CircularProgress />
            <Box component="h4">{t('Loading')}</Box>
          </Box>
        ) : null}
        {Array.isArray(rows) && !rows.length && !isLoading ? (
          <Box className={classes.noResults}>
            <Box component="h4">{t('No results were found')}</Box>
          </Box>
        ) : null}
      </TableContainer>
    </>
  );
};

export default SimpleTable;
