import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PUBLIC_ROUTES, PROTECTED_ROUTES } from './routes/routes-list';
import Notification from './components/notifications/notification';
import ProtectedRoute from './routes/protected-route';
import NoMatch from './components/navigation/not-found';
import './assets/styles/base.css';
const App: React.FunctionComponent = () => (
  <>
    <Routes>
      {PUBLIC_ROUTES.map((route) => (
        <Route key={route.route} path={route.route} element={route.element} />
      ))}

      {PROTECTED_ROUTES.map((route) => (
        <Route
          key={route.route}
          path={route.route}
          index={route.isIndex ? true : false}
          element={
            <ProtectedRoute
              route={route.route}
              allowedRoles={route.allowedRoles}
              element={route.element}
              isRedirect={route.isRedirect}
            />
          }
        />
      ))}
      <Route path="*" element={<NoMatch />} />
    </Routes>
    <Notification />
  </>
);

export default App;
