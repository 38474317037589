import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const COMPANY_STATS_QUERY = loader('../graphql/companyStats.graphql');

interface CompanyStats {
  companyCount: number;
  userCount: number;
}

interface CompanyStatsQueryResponse {
  companyStats?: CompanyStats;
  loading?: boolean;
  error?: Error;
}

const useCompanyStatsQuery = (): CompanyStatsQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(COMPANY_STATS_QUERY, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    fetchPolicy: 'network-only'
  });
  return { companyStats: get(data, 'companyStats'), loading, error };
};

export default useCompanyStatsQuery;
