import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

const CreateCompanyBankButtons: React.FunctionComponent<Props> = ({
  onConfirm,
  onClose,
  loading
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Confirm')}>
        <span>
          <IconButton
            aria-label="confirm"
            onClick={onConfirm}
            disabled={loading}
            data-testid="confirm-button"
            size="large"
          >
            <Check />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('Close')}>
        <span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            disabled={loading}
            data-testid="close-button"
            size="large"
          >
            <Close />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default CreateCompanyBankButtons;
