import React from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import makeStyles from '@mui/styles/makeStyles';

import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import useStyles from '../forms/styles';
import SubmitButton from '../forms/submit-button';
import AutocompleteInput from '../forms/autocomplete-input';
import UpdateFileInput from '../forms/upload-file-input';
import { formatBanks } from '../../utils/formatters';
import { BankType, SelectItemType } from '../../definitions.d';

const componentStyles = makeStyles((theme) => ({
  inputComponent: {
    alignSelf: 'center'
  }
}));

const VALIDATION_SCHEMA = yup.object({
  file: yup.mixed().required(REQUIRED_FIELD_TEXT),
  bank: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  })
});

const INITIAL_VALUES = {
  bank: null,
  file: null
};

export interface FormProps {
  bank: SelectItemType | null;
  file: File | null;
}

interface Props {
  onSubmit: (values: FormProps) => void;
  loading?: boolean;
  banks: BankType[];
}

const ProcessCashManagementFilterForm: React.FunctionComponent<Props> = ({
  onSubmit,
  loading,
  banks
}: Props) => {
  const classes = useStyles();
  const formClasses = componentStyles();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit }): React.ReactNode => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              className={classes.formContainer}
              direction="row"
              justifyContent="center"
            >
              <Grid item xs={6} className={formClasses.inputComponent}>
                <Field name="file" component={UpdateFileInput} />
              </Grid>
              <Grid item xs={6} className={formClasses.inputComponent}>
                <Field
                  name="bank"
                  label="Bank"
                  component={AutocompleteInput}
                  items={formatBanks(banks)}
                />
                <SubmitButton isLoading={loading} buttonText="Process" />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProcessCashManagementFilterForm;
