import { trim } from 'lodash';

import { FetchMoreArgs, ChangePageArgs } from '../definitions.d';
import { PAGE_SIZE } from '../config/constants';

export const graphqlExp = new RegExp('GraphQL error:*');

export const formatGraphQlError = (message: string): string =>
  trim(message.replace(graphqlExp, ''));

export const signatureExpiredEnglish = new RegExp('Signature has expired');
export const signatureExpiredSpanish = new RegExp('Su sesión ha caducado');
export const errorDecodingSignature = new RegExp('Error decoding signature');

export const isSignatureExpired = (message: string): boolean =>
  signatureExpiredEnglish.test(message) ||
  signatureExpiredSpanish.test(message) ||
  errorDecodingSignature.test(message);

export const getFetchMoreVariables = (
  values: ChangePageArgs | null
): FetchMoreArgs => {
  if (values) {
    const { after, before } = values;
    if (after) {
      return { after, first: PAGE_SIZE, last: null };
    }

    if (before) {
      return { before, last: PAGE_SIZE, first: null };
    }
  }

  return { first: PAGE_SIZE, last: null };
};
