import {
  ErolUserSexTypes,
  ErolUserIdTypes,
  ErolUserRoles,
  BankAccountTypes,
  AdvanceStatusTypes,
  ErolUserMaritalStatusTypes,
  ErolUserCompanyDepartmentTypes
} from '../definitions.d';

export const LOCALSTORAGE_AUTH_KEY = 'AUTH_TOKEN';

export const GRAPHQL_URI =
  process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:8000/graphql/';

export const REQUIRED_FIELD_TEXT = 'Required field';
export const EMAIL_FIELD_TEXT = 'Must be a valid email';
export const URL_FIELD_TEXT = 'Must be a valid URL';
export const MAX_WAITING_DAYS_MESSAGE = 'Must be between 0 and 180 days';

export const BANK_ACCOUNT_ITEMS = [
  {
    label: 'Savings account',
    value: BankAccountTypes.AHO
  },
  {
    label: 'Checking account',
    value: BankAccountTypes.CTE
  }
];

export const SEX_TYPE_ITEMS = [
  {
    label: 'Male',
    value: ErolUserSexTypes.MALE
  },
  {
    label: 'Female',
    value: ErolUserSexTypes.FEMALE
  }
];

export const IDTYPE_ITEMS = [
  {
    label: 'ID Card',
    value: ErolUserIdTypes.CEDULA
  },
  {
    label: 'Passport',
    value: ErolUserIdTypes.PASSPORT
  }
];

export const USERROLE_ITEMS = [
  {
    label: 'Company manager',
    value: ErolUserRoles.COMPANY_MANAGER
  },
  {
    label: 'Platform manager',
    value: ErolUserRoles.PLATFORM_MANAGER
  }
];

export const REGULAR_EXPRESSION_DECIMAL = new RegExp('^\\d+\\.?\\d{0,2}$');

export const REGULAR_EXPRESSION_PHONE_NUMBER = new RegExp('^09[\\d]{8}$');

export const DECIMAL_DIGITS = 2;

export const DECIMAL_VALUE_VALIDATION_MESSAGE =
  'Only decimal values are allowed';

export const END_DATE_VALIDATION_MESSAGE =
  'End date must be later than start date';

export const PHONE_NUMBER_VALIDATION_MESSAGE = 'Phone number not valid';

export const ADVANCE_STATUS_ITEMS = [
  {
    label: 'REQUESTED',
    value: AdvanceStatusTypes.REQUESTED
  },
  {
    label: 'SUCCESSFUL',
    value: AdvanceStatusTypes.SUCCESSFUL
  },
  {
    label: 'PROCESSING',
    value: AdvanceStatusTypes.PROCESSING
  },
  {
    label: 'FAILED',
    value: AdvanceStatusTypes.FAILED
  }
];

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const TIME_ZONE = 'America/Guayaquil';

export const MONTHS_ITEMS = [
  {
    label: 'January',
    value: 1
  },
  {
    label: 'February',
    value: 2
  },
  {
    label: 'March',
    value: 3
  },
  {
    label: 'April',
    value: 4
  },
  {
    label: 'May',
    value: 5
  },
  {
    label: 'June',
    value: 6
  },
  {
    label: 'July',
    value: 7
  },
  {
    label: 'August',
    value: 8
  },
  {
    label: 'September',
    value: 9
  },
  {
    label: 'October',
    value: 10
  },
  {
    label: 'November',
    value: 11
  },
  {
    label: 'December',
    value: 12
  }
];

export const MARITAL_STATUS_ITEMS = [
  {
    label: 'Single',
    value: ErolUserMaritalStatusTypes.SINGLE
  },
  {
    label: 'Married',
    value: ErolUserMaritalStatusTypes.MARRIED
  },
  {
    label: 'Divorced',
    value: ErolUserMaritalStatusTypes.DIVORCED
  },
  {
    label: 'Widowed',
    value: ErolUserMaritalStatusTypes.WIDOWED
  },
  {
    label: 'Free union',
    value: ErolUserMaritalStatusTypes.FREE_UNION
  }
];

export const COMPANY_DEPARTMENT_ITEMS = [
  {
    label: 'Administrative',
    value: ErolUserCompanyDepartmentTypes.ADMINISTRATIVE
  },
  {
    label: 'Advisory',
    value: ErolUserCompanyDepartmentTypes.ADVISORY
  },
  {
    label: 'Commercial',
    value: ErolUserCompanyDepartmentTypes.COMMERCIAL
  },
  {
    label: 'Purchases',
    value: ErolUserCompanyDepartmentTypes.PURCHASES
  },
  {
    label: 'Accounting',
    value: ErolUserCompanyDepartmentTypes.ACCOUNTING
  },
  {
    label: 'Development',
    value: ErolUserCompanyDepartmentTypes.DEVELOPMENT
  },
  {
    label: 'Finance',
    value: ErolUserCompanyDepartmentTypes.FINANCE
  },
  {
    label: 'Management',
    value: ErolUserCompanyDepartmentTypes.MANAGEMENT
  },
  {
    label: 'IT',
    value: ErolUserCompanyDepartmentTypes.IT
  },
  {
    label: 'Legal',
    value: ErolUserCompanyDepartmentTypes.LEGAL
  },
  {
    label: 'Cleaning',
    value: ErolUserCompanyDepartmentTypes.CLEANING
  },
  {
    label: 'Logistics',
    value: ErolUserCompanyDepartmentTypes.LOGISTICS
  },
  {
    label: 'Marketing',
    value: ErolUserCompanyDepartmentTypes.MARKETING
  },
  {
    label: 'Operative',
    value: ErolUserCompanyDepartmentTypes.OPERATIVE
  },
  {
    label: 'Production',
    value: ErolUserCompanyDepartmentTypes.PRODUCTION
  },
  {
    label: 'Security',
    value: ErolUserCompanyDepartmentTypes.SECURITY
  },
  {
    label: 'HR',
    value: ErolUserCompanyDepartmentTypes.HR
  }
];

export const MAIN_COLOR = '#111827';
export const WHITE_COLOR = '#FFFFFF';
export const COMPLETED_COLOR = '#d8e5ca';
export const IN_TRANSIT_COLOR = '#D1D5DB';

export const AVAILABLE_AMOUNT_COLOR = '#27E672';
export const PENDING_AMOUNT_COLOR = '#5BDAE2';
export const USED_AMOUNT_COLOR = '#00CEAD';

export const PAGE_SIZE = 25;

export const INACTIVITY_TIMEOUT = 30000;
export const GAP_TIME = 500;
