import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '../context/notification-context';

const USER_PASSWORD_RESET_BY_TAX_ID_MUTATION = loader(
  '../graphql/userPasswordResetByTaxId.graphql'
);

export interface UserPasswordResetByTaxIdProps {
  taxId: string;
}

interface Response {
  userPasswordResetByTaxId: {
    emailSent: boolean;
  };
}

interface UserPasswordResetByTaxIdResponse {
  sendEmail: (values: UserPasswordResetByTaxIdProps) => void;
  loading?: boolean;
  error?: Error;
}

const useUserPasswordResetByTaxIdMutation = (
  redirect?: boolean
): UserPasswordResetByTaxIdResponse => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showNotification } = useNotificationContext();

  const [mutation, { loading, error }] = useMutation(
    USER_PASSWORD_RESET_BY_TAX_ID_MUTATION,
    {
      onCompleted: (data: Response): void => {
        const emailSent = get(data, 'userPasswordResetByTaxId.emailSent');

        if (emailSent) {
          showNotification(t('An email was sent'), 'success');
        }

        if (redirect) {
          navigate('/login');
        }
      },
      onError: (e) => {
        showNotification(e.message, 'error');
      }
    }
  );

  return {
    sendEmail: (values: UserPasswordResetByTaxIdProps): void => {
      mutation({ variables: values });
    },
    error,
    loading
  };
};

export default useUserPasswordResetByTaxIdMutation;
