import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import PageTitle from '../navigation/page-title';
import { PriceRangesProvider } from './price-ranges-context';
import PriceRanges from './price-ranges';
import PriceRangesGroupInformation from './price-ranges-group-information';
import PriceRangesGroupFooterCreate from './price-ranges-group-footer-create';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2)
  }
}));

const PriceRangesGroupCreate: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <PageTitle title="Create price ranges group" />
      <PriceRangesProvider>
        <>
          <Card className={classes.card}>
            <CardContent>
              <PriceRangesGroupInformation />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <PriceRanges />
            <PriceRangesGroupFooterCreate />
          </Card>
        </>
      </PriceRangesProvider>
    </>
  );
};

export default PriceRangesGroupCreate;
