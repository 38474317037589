import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { get } from 'lodash';

import TableRowActions from '../tables/table-row-actions';
import { convertToDollars } from '../../utils/formatters';
import { ExtendedPriceRangeType } from './reducer';
import { usePriceRangesContext } from './price-ranges-context';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 2)
  },
  paper: {
    alignItems: 'center',
    textAlign: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase'
  }
}));

interface Props {
  title: string;
  priceRange: ExtendedPriceRangeType;
}

const PriceRange: React.FunctionComponent<Props> = ({
  title,
  priceRange
}: Props) => {
  const classes = useStyles();
  const { setSelectedPriceRange, setOpenRemoveDialog, setOpenEditDialog } =
    usePriceRangesContext();

  const handleDelete = (): void => {
    setSelectedPriceRange(priceRange);
    setOpenRemoveDialog(true);
  };

  const handleEdit = (): void => {
    setSelectedPriceRange(priceRange);
    setOpenEditDialog(true);
  };

  return (
    <Grid container spacing={1} className={classes.paper}>
      <Grid item xs={2}>
        {priceRange.isNew ? (
          <Badge color="error" variant="dot">
            <Typography component="p" className={classes.title}>
              {title}
            </Typography>
          </Badge>
        ) : (
          <Typography component="p" className={classes.title}>
            {title}
          </Typography>
        )}
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.container}>
          <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={4}>
              <Typography component="p">
                {convertToDollars(priceRange.rangeStart)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="p">
                {convertToDollars(priceRange.rangeEnd)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="p">
                {convertToDollars(get(priceRange, 'price') as number)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <TableRowActions
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          id={get(priceRange, 'uuid') as string}
        />
      </Grid>
    </Grid>
  );
};

export default PriceRange;
