import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';

import client from '../services/apollo-client';
import ApplicationWrapper from '../components/navigation/application-wrapper';
import { NotificationProvider } from './notification-context';
import { UserInfoProvider } from './user-info-context';
import { RequestContextProvider } from './request/request-context';
import ThemeProvider from './theme-provider';
import SessionProvider from './session-provider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
interface Props {
  children: React.ReactNode;
}

const AppProviders: React.FunctionComponent<Props> = ({ children }: Props) => (
  <ApolloProvider client={client}>
    <Router>
      <NotificationProvider>
        <UserInfoProvider>
          <SessionProvider>
            <ThemeProvider>
              <ApplicationWrapper>
                <RequestContextProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    {children}
                  </LocalizationProvider>
                </RequestContextProvider>
              </ApplicationWrapper>
            </ThemeProvider>
          </SessionProvider>
        </UserInfoProvider>
      </NotificationProvider>
    </Router>
  </ApolloProvider>
);

export default AppProviders;
