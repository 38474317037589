import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface Props {
  title?: string;
  inheritColor?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  buttonId: string;
  onClick: () => void;
}

const SimpleButton: React.FunctionComponent<Props> = ({
  title,
  inheritColor,
  isLoading,
  isDisabled,
  buttonId,
  onClick
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      variant="contained"
      color={inheritColor ? 'inherit' : 'primary'}
      className={classes.submitButton}
      disabled={isLoading || isDisabled}
      data-testid={buttonId}
      onClick={onClick}
    >
      {title ? t(title) : t('Simple button')}
    </Button>
  );
};

export default SimpleButton;
