import { useEffect, useCallback, useState, useRef } from 'react';

import { ErolUserRoles } from '../definitions.d';
import { INACTIVITY_TIMEOUT } from '../config/constants';

const EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress'
];

interface Props {
  userRole?: ErolUserRoles;
}

const useDetectSessionActivity = ({ userRole }: Props): boolean => {
  const [isActive, setIsActive] = useState<boolean>(true);

  const activityTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const clearActivityTimeout = useCallback(() => {
    if (activityTimeoutRef && activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
    }

    setIsActive(true);
    activityTimeoutRef.current = setTimeout(
      () => setIsActive(false),
      INACTIVITY_TIMEOUT
    );
  }, []);

  useEffect(() => {
    if (userRole && userRole !== ErolUserRoles.PLATFORM_MANAGER) {
      EVENTS.forEach((event) =>
        window.addEventListener(event, clearActivityTimeout)
      );
    }
    return () => {
      EVENTS.forEach((event) =>
        window.removeEventListener(event, clearActivityTimeout)
      );
    };
  }, [clearActivityTimeout, userRole]);

  return isActive;
};

export default useDetectSessionActivity;
