import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { CompanyType } from '../definitions.d';
import useHandleError from './useHandleError';

const COMPANIES = loader('../graphql/companies.graphql');

interface CompaniesQueryResponse {
  companies: CompanyType[];
  loading?: boolean;
  error?: Error;
}

const useCompaniesQuery = (): CompaniesQueryResponse => {
  const { handleError } = useHandleError();

  const { data, loading, error } = useQuery(COMPANIES, {
    onError: (e) => {
      handleError(e);
    }
  });
  return { companies: get(data, 'companies', []), loading, error };
};

export default useCompaniesQuery;
