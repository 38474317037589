import React from 'react';

import SimpleMenuItem from './simple-menu-item';
import ComplexMenuItem from './complex-menu-item';
import {
  DASHBOARD_MENU,
  PLATFORM_MANAGER_COMPANIES_MENU,
  ADVANCES_MENU,
  CASH_MANAGEMENT_MENU,
  EMPLOYEES_MENU,
  MANAGERS_MENU,
  USER_PROFILE_MENU,
  LOGOUT_MENU,
  IMPERSONATE_USER_MENU
} from './menu-items-list';

const PlatformManagerSidebarMenu: React.FunctionComponent = () => {
  return (
    <>
      <SimpleMenuItem {...DASHBOARD_MENU} />
      <ComplexMenuItem {...PLATFORM_MANAGER_COMPANIES_MENU} />
      <ComplexMenuItem {...ADVANCES_MENU} />
      <ComplexMenuItem {...CASH_MANAGEMENT_MENU} />
      <ComplexMenuItem {...EMPLOYEES_MENU} />
      <ComplexMenuItem {...MANAGERS_MENU} />
      <ComplexMenuItem {...USER_PROFILE_MENU} />
      <SimpleMenuItem {...LOGOUT_MENU} />
      <SimpleMenuItem {...IMPERSONATE_USER_MENU} />
    </>
  );
};

export default PlatformManagerSidebarMenu;
