import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import loginImage from '../../assets/images/imagePersonWelcome.png';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  imageWelcome: {
    marginBottom: '-13px'
  },
  titleContainer: {
    position: 'absolute',
    bottom: '0',
    width: '270px',
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 600
  },
  containerWelcome: {
    position: 'relative',
    color: 'white',
    width: '253px',
    height: '67px',
    backgroundColor: '#111827',
    clipPath:
      'path("M253.5 0C140.3 20.4 37.3333 8.5 0 0C11.2 34 4.66667 59.1667 0 67.5H253.5C245.1 45.1 250 13.1667 253.5 0Z")'
  },
  textWelcome: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '15px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface Props {
  children: React.ReactNode;
}

const LoginWrapper: React.FunctionComponent<Props> = ({ children }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const title = t('Welcome to E-ROL');

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={8} sm={5} md={3}>
        {children}
      </Grid>
    </Grid>
  );
};

export default LoginWrapper;
