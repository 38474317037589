import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import useUserQuery from '../../hooks/useUserQuery';
import LoadingNotification from '../notifications/loading-notification';
import { UserType, ErolUserRoles } from '../../definitions.d';
import useUpdateManagerMutation, {
  UpdateManagerProps
} from '../../hooks/useUpdateManagerMutation';
import PageTitle from '../navigation/page-title';
import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import { formatCompanies } from '../../utils/formatters';
import ManagerUpdateForm, { FormValues } from './manager-update-form';

const getUserUuid = (pathname: string): string => {
  const partialResult = pathname.replace('/managers/', '');
  return partialResult.replace('/edit', '');
};

const formatUser = (user: UserType): FormValues => {
  const companyUuids = formatCompanies(get(user, 'managedCompanies') || []);
  return {
    apiOnly: get(user, 'apiOnly'),
    email: get(user, 'email'),
    companyUuids,
    firstName: get(user, 'firstName'),
    idType: get(user, 'idType'),
    lastName: get(user, 'lastName'),
    notifyAdvances: get(user, 'notifyAdvances'),
    phone: get(user, 'phone'),
    sexType: get(user, 'sexType'),
    taxId: get(user, 'taxId'),
    userRole: get(user, 'userRole'),
    username: get(user, 'username')
  };
};

const formatFormValues = (values: FormValues): UpdateManagerProps => {
  const apiOnly = get(values, 'apiOnly');
  const notifyAdvances = get(values, 'notifyAdvances');

  return {
    apiOnly,
    email: get(values, 'email'),
    companyUuids: (get(values, 'companyUuids') || []).map(
      (company) => company.value as string
    ),
    firstName: get(values, 'firstName'),
    idType: get(values, 'idType'),
    lastName: get(values, 'lastName'),
    notifyAdvances: apiOnly ? false : notifyAdvances,
    phone: get(values, 'phone'),
    sexType: get(values, 'sexType'),
    taxId: get(values, 'taxId'),
    userRole: get(values, 'userRole') as ErolUserRoles
  };
};

const ManagerUpdate: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companies } = useCompaniesQuery();

  const userUuid = getUserUuid(location.pathname);
  const { user, error } = useUserQuery(userUuid);
  const { updateManager, loading } = useUpdateManagerMutation(userUuid);

  useEffect(() => {
    if (error) {
      navigate('/managers');
    }
  }, [error, history]);

  if (!user || !companies) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Edit manager" />
      <ManagerUpdateForm
        initialValues={formatUser(user)}
        isLoading={loading}
        companies={formatCompanies(companies)}
        onSubmit={(values): void => {
          updateManager(formatFormValues(values));
        }}
      />
    </>
  );
};

export default ManagerUpdate;
