import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

interface TableActionProps {
  handleEdit: () => void;
  handleDelete: () => void;
  handleSuspend?: () => void;
  handleResume?: () => void;
  id: string;
}

const TableRowActions: React.FunctionComponent<TableActionProps> = ({
  handleEdit,
  handleDelete,
  handleSuspend,
  handleResume,
  id
}: TableActionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Edit')}>
        <IconButton
          aria-label="edit"
          onClick={handleEdit}
          data-testid={`edit-row-${id}`}
          size="large"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Delete')}>
        <IconButton
          aria-label="delete"
          onClick={handleDelete}
          data-testid={`delete-row-${id}`}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {handleSuspend ? (
        <Tooltip title={t('Suspend')}>
          <IconButton
            aria-label="suspend"
            onClick={handleSuspend}
            data-testid={`suspend-row-${id}`}
            size="large"
          >
            <StopRoundedIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {handleResume ? (
        <Tooltip title={t('Resume')}>
          <IconButton
            aria-label="resume"
            onClick={handleResume}
            data-testid={`resume-row-${id}`}
            size="large"
          >
            <PlayCircleFilledIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

export default TableRowActions;
