import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SimpleButton from '../forms/simple-button';
import { useNavigate } from 'react-router-dom';

interface TermsAndConditionsModalProps {
  open: boolean;
  onClose: () => void;
}

const TermsAndConditionsRejectDialog: React.FC<
  TermsAndConditionsModalProps
> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        // Solo permitir que el diálogo se cierre si el motivo es 'escapeKeyDown'
        if (reason === 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="terms-and-conditions-title"
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="terms-and-conditions-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Advertencia
        <IconButton
          onClick={onClose}
          color="primary"
          size="small"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Los terminos y condiciones de la plataforma deben ser aceptados. Serás
          redirigido a la pantalla de inicio.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem'
          }}
        >
          <SimpleButton
            onClick={onClose}
            inheritColor={true}
            buttonId={'submit-button-terms-and-conditions-rejected'}
            title={'Aceptar'}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsRejectDialog;
