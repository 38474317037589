import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { VictoryPie, VictoryContainer } from 'victory';

import DashboardImage from '../../assets/images/dashboard.png';
import {
  MAIN_COLOR,
  COMPLETED_COLOR,
  IN_TRANSIT_COLOR,
  AVAILABLE_AMOUNT_COLOR,
  PENDING_AMOUNT_COLOR,
  USED_AMOUNT_COLOR
} from '../../config/constants';

const calculateValue = (value: number): number => value / 100;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    position: 'absolute',
    bottom: 90
  }
}));

interface Props {
  amountCompleted: number;
  amountInTransit: number;
  amountAvailable: number;
}

const DashboardChart: React.FunctionComponent<Props> = ({
  amountCompleted,
  amountInTransit,
  amountAvailable
}: Props) => {
  const classes = useStyles();

  const data = [
    { y: calculateValue(amountAvailable) },
    { y: calculateValue(amountInTransit) },
    { y: calculateValue(amountCompleted) }
  ];

  const colorScale = [
    AVAILABLE_AMOUNT_COLOR,
    PENDING_AMOUNT_COLOR,
    USED_AMOUNT_COLOR
  ];

  return (
    <div className={classes.container}>
      <VictoryPie
        width={400}
        height={400}
        innerRadius={110}
        labelRadius={({ innerRadius }) => innerRadius as number}
        animate={{
          duration: 1000
        }}
        containerComponent={<VictoryContainer responsive={false} />}
        data={data}
        style={{
          labels: {
            fontSize: 1,
            fill: 'transparent'
          }
        }}
        colorScale={colorScale}
      />
    </div>
  );
};

export default DashboardChart;
