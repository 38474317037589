import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '../context//notification-context';
import { isSignatureExpired, formatGraphQlError } from '../utils/utils';

interface Response {
  handleError: (error: ApolloError) => void;
}

const useHandleError = (): Response => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();

  const handleError = useCallback(
    (error: ApolloError) => {
      const { message } = error;
      showNotification(formatGraphQlError(message), 'error');
      if (isSignatureExpired(message)) {
        navigate('/logout');
      }
    },
    [history, showNotification]
  );

  return { handleError };
};

export default useHandleError;
