import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  Fab,
  Tooltip,
  IconButton,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldProps, getIn } from 'formik';
import { InputProps } from '@mui/material/Input';
import clsx from 'clsx';

import useStyles from './styles';

const componentStyles = makeStyles((theme) => ({
  dropZone: {
    backgroundColor: theme.palette.grey[200]
  },
  dropzoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '60px'
  },
  dropZoneTitle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  dropZoneFile: {
    fontSize: '12px'
  },
  dropzoneFileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1)
  },
  dropZoneError: {
    color: theme.palette.error.main
  }
}));

export interface UploadFileInputProps extends FieldProps {
  InputProps?: Omit<InputProps, 'name' | 'type' | 'onChange' | 'label'>;
}

const UploadFileInput: React.FunctionComponent<UploadFileInputProps> = ({
  field,
  form: { setFieldValue, touched, errors }
}: UploadFileInputProps) => {
  const formClasses = useStyles();
  const classes = componentStyles();
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (files.length) {
      setFieldValue(field.name, files[0]);
    }
  }, [field.name, files, setFieldValue]);

  const error = getIn(touched, field.name) && getIn(errors, field.name);

  return (
    <FormControl className={clsx(formClasses.formControl, classes.dropZone)}>
      <Dropzone
        onDrop={(values: File[]): void => {
          setFiles(values);
        }}
      >
        {({ getRootProps, getInputProps }): React.ReactElement => (
          <section>
            <div
              {...getRootProps({
                className: classes.dropzoneContainer
              })}
            >
              <input {...getInputProps()} />
              <Typography className={classes.dropZoneTitle}>
                {t('Drag and drop a file or click')}
              </Typography>
              {error ? (
                <Typography
                  className={clsx(classes.dropZoneTitle, classes.dropZoneError)}
                >
                  {t(error)}
                </Typography>
              ) : null}
            </div>
            {files.length ? (
              <div className={classes.dropzoneFileContainer}>
                <Tooltip title={t('Delete')}>
                  <IconButton
                    aria-label="delete"
                    onClick={(): void => {
                      setFiles([]);
                      setFieldValue(field.name, null);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={files[0].name} aria-label="selected">
                  <Fab>
                    <AttachFileIcon />
                  </Fab>
                </Tooltip>
              </div>
            ) : null}
          </section>
        )}
      </Dropzone>
    </FormControl>
  );
};

export default UploadFileInput;
