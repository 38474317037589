import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

const IMPERSONATE_EROL_USER_MUTATION = loader(
  '../graphql/impersonateErolUser.graphql'
);

const useImpersonateErolUserMutation = () => {
  const [mutation, { loading }] = useMutation(IMPERSONATE_EROL_USER_MUTATION);

  return {
    impersonateErolUser: async (uuid: string) => {
      return await mutation({
        variables: {
          erolUserId: uuid
        }
      });
    },
    loading
  };
};

export default useImpersonateErolUserMutation;
