import { getToken } from '../utils/auth-client';

interface UseAuthProps {
  isAuthenticated: boolean;
}

const useAuth = (): UseAuthProps => {
  const token = getToken();
  return {
    isAuthenticated: !!token
  };
};

export default useAuth;
