import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { fieldToSelect, SelectProps } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

import { SelectItemType } from '../../definitions.d';
import useStyles from './styles';

interface SelectInputProps extends SelectProps {
  label: string;
  items: SelectItemType[];
}

const SelectInput: React.FunctionComponent<SelectInputProps> = (
  props: SelectInputProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectProps = fieldToSelect(props);
  const customProps = {
    ...selectProps,
    value: selectProps.value ? selectProps.value : ''
  };

  const name = customProps.name as string;
  const error = props.form.errors[name] && props.form.touched[name];

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={customProps.name}>{t(props.label)}</InputLabel>
      <Select {...customProps} error={!!error}>
        {props.items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
