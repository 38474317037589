import { PriceRangeType } from '../../definitions.d';
import { addPriceRange, removePriceRange, editPriceRange } from './utils';

export const ADD_PRICE_RANGES = 'ADD_PRICE_RANGES';
export const ADD_COMPANY_UUID = 'ADD_COMPANY_UUID';
export const ADD_NEW_PRICE_RANGE = 'ADD_NEW_PRICE_RANGE';
export const REMOVE_PRICE_RANGE = 'REMOVE_PRICE_RANGE';
export const EDIT_PRICE_RANGE = 'EDIT_PRICE_RANGE';
export const OPEN_CREATE_DIALOG = 'OPEN_CREATE_DIALOG';
export const OPEN_REMOVE_DIALOG = 'OPEN_REMOVE_DIALOG';
export const SET_SELECTED_PRICE_RANGE = 'SET_SELECTED_PRICE_RANGE';
export const OPEN_EDIT_DIALOG = 'OPEN_EDIT_DIALOG';
export const ADD_PRICE_RANGES_GROUP = 'ADD_PRICE_RANGES_GROUP';

export interface ExtendedPriceRangeType extends PriceRangeType {
  isNew?: boolean;
}

export type PriceRangesGroup = {
  uuid?: string;
  startDay: number;
  endDay: number;
  chargedNextMonth: boolean;
};

interface AddPriceRanges {
  type: typeof ADD_PRICE_RANGES;
  priceRanges: ExtendedPriceRangeType[];
}

interface AddCompanyUuid {
  type: typeof ADD_COMPANY_UUID;
  companyUuid: string;
}

interface AddNewPriceRange {
  type: typeof ADD_NEW_PRICE_RANGE;
  priceRange: ExtendedPriceRangeType;
}

interface RemovePriceRange {
  type: typeof REMOVE_PRICE_RANGE;
  priceRange: ExtendedPriceRangeType;
}

interface EditPriceRange {
  type: typeof EDIT_PRICE_RANGE;
  priceRange: ExtendedPriceRangeType;
}

interface OpenCreateDialog {
  type: typeof OPEN_CREATE_DIALOG;
  openCreateDialog: boolean;
}

interface OpenRemoveDialog {
  type: typeof OPEN_REMOVE_DIALOG;
  openRemoveDialog: boolean;
}

interface SetSelectedPriceRange {
  type: typeof SET_SELECTED_PRICE_RANGE;
  priceRange: ExtendedPriceRangeType;
}

interface OpenEditDialog {
  type: typeof OPEN_EDIT_DIALOG;
  openEditDialog: boolean;
}

interface AddPriceRangesGroup {
  type: typeof ADD_PRICE_RANGES_GROUP;
  priceRangesGroup: PriceRangesGroup;
}

type ActionTypes =
  | AddPriceRanges
  | AddCompanyUuid
  | AddNewPriceRange
  | RemovePriceRange
  | EditPriceRange
  | OpenCreateDialog
  | OpenRemoveDialog
  | SetSelectedPriceRange
  | OpenEditDialog
  | AddPriceRangesGroup;

export interface PriceRangeState {
  priceRanges: ExtendedPriceRangeType[];
  priceRangesToRemove: ExtendedPriceRangeType[];
  isDirty: boolean;
  selectedPriceRange?: ExtendedPriceRangeType;
  openRemoveDialog: boolean;
  openEditDialog: boolean;
  openCreateDialog: boolean;
  companyUuid: string;
  priceRangesGroup: PriceRangesGroup;
}

export const initialState: PriceRangeState = {
  priceRanges: [],
  priceRangesToRemove: [],
  isDirty: false,
  openRemoveDialog: false,
  openEditDialog: false,
  openCreateDialog: false,
  companyUuid: '',
  priceRangesGroup: {
    startDay: 1,
    endDay: 1,
    chargedNextMonth: false
  }
};

export const reducer = (
  state = initialState,
  action: ActionTypes
): PriceRangeState => {
  switch (action.type) {
    case ADD_PRICE_RANGES:
      return { ...state, priceRanges: action.priceRanges };

    case ADD_COMPANY_UUID:
      return { ...state, companyUuid: action.companyUuid };

    case ADD_NEW_PRICE_RANGE:
      return {
        ...state,
        priceRanges: addPriceRange(state.priceRanges, action.priceRange),
        isDirty: true,
        openCreateDialog: false
      };

    case REMOVE_PRICE_RANGE:
      return {
        ...state,
        priceRanges: removePriceRange(state.priceRanges, action.priceRange),
        priceRangesToRemove: state.priceRangesToRemove.concat(
          action.priceRange
        ),
        isDirty: true
      };

    case EDIT_PRICE_RANGE:
      return {
        ...state,
        priceRanges: editPriceRange(state.priceRanges, action.priceRange),
        isDirty: true
      };

    case OPEN_CREATE_DIALOG:
      return {
        ...state,
        openCreateDialog: action.openCreateDialog
      };

    case OPEN_REMOVE_DIALOG:
      return {
        ...state,
        openRemoveDialog: action.openRemoveDialog
      };

    case SET_SELECTED_PRICE_RANGE:
      return {
        ...state,
        selectedPriceRange: action.priceRange
      };

    case OPEN_EDIT_DIALOG:
      return {
        ...state,
        openEditDialog: action.openEditDialog
      };

    case ADD_PRICE_RANGES_GROUP:
      return {
        ...state,
        priceRangesGroup: action.priceRangesGroup,
        isDirty: !!state.priceRanges.length
      };

    default:
      return state;
  }
};
