import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { AdvanceStatusType } from '../definitions.d';
import useHandleError from './useHandleError';

const ADVANCE_STATUS_QUERY = loader('../graphql/advanceStatus.graphql');

interface AdvanceStatusQueryResponse {
  advanceStatus?: AdvanceStatusType;
  loading?: boolean;
  error?: Error;
  refetch: () => Promise<AdvanceStatusType>;
}

const useAdvanceStatusQuery = (): AdvanceStatusQueryResponse => {
  const { handleError } = useHandleError();

  const { data, loading, error, refetch } = useQuery(ADVANCE_STATUS_QUERY, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      handleError(e);
    }
  });
  return {
    advanceStatus: get(data, 'advanceStatus'),
    loading,
    error,
    refetch: async (): Promise<AdvanceStatusType> => {
      const response = await refetch();
      return get(response, 'advanceStatus') as unknown as AdvanceStatusType;
    }
  };
};

export default useAdvanceStatusQuery;
