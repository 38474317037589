import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0),
    padding: '13px 25px'
  }
}));

interface Props {
  handleContinue: () => void;
  disabled: boolean;
}

const ContinueButton: React.FunctionComponent<Props> = ({
  handleContinue,
  disabled
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={classes.button}
      disabled={disabled}
      data-testid="continue-button"
      size="small"
      onClick={handleContinue}
    >
      {t('Continue')}
    </Button>
  );
};

export default ContinueButton;
