import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfoContext } from '../../context/user-info-context';
import { removeToken } from '../../utils/auth-client';
import client from '../../services/apollo-client';
import LoadingNotification from '../notifications/loading-notification';
import { useApolloClient } from '@apollo/client';

const Logout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const { resetUserInfo } = useUserInfoContext();

  useEffect(() => {
    removeToken();
    resetUserInfo();
    client.clearStore().then(() => null);
    navigate('/login');
  }, [client, navigate, resetUserInfo]);

  return <LoadingNotification />;
};

export default Logout;
