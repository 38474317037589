import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';

import PasswordInput from '../forms/password-input';
import SubmitButton from '../forms/submit-button';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import PasswordRules from './password-rules';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(3)
  }
}));

const VALIDATION_SCHEMA = yup.object({
  password: yup.string().required(REQUIRED_FIELD_TEXT),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords do not match')
});

const INITIAL_VALUES = {
  password: '',
  passwordConfirm: ''
};

export type FormValues = {
  password: string;
  passwordConfirm: string;
};

interface Props {
  isLoading?: boolean;
  onSubmit: (T: FormValues) => void;
}

const UpdateCurrentUserPasswordForm: React.FunctionComponent<Props> = ({
  isLoading,
  onSubmit
}: Props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(): React.ReactNode => {
        return (
          <Form>
            <Grid
              container
              justifyContent="center"
              className={classes.formContainer}
            >
              <Grid item xs={10} sm={6} md={4} lg={3}>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item xs>
                    <Field
                      name="password"
                      label="Password"
                      variant="outlined"
                      component={PasswordInput}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="passwordConfirm"
                      label="Password confirmation"
                      variant="outlined"
                      component={PasswordInput}
                    />
                  </Grid>
                  <Grid item xs>
                    <PasswordRules />
                  </Grid>
                  <Grid item xs>
                    <SubmitButton
                      isLoading={isLoading}
                      buttonText="Update password"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateCurrentUserPasswordForm;
