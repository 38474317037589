import React from 'react';
import useImpersonateErolUserMutation from '../../hooks/useImpersonateErolUserMutation';
import { Button, TextField } from '@mui/material';
import { get } from 'lodash';
import { saveToken } from '../../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

export default function ImpersonateUser() {
  const useImpersonateErolUser = useImpersonateErolUserMutation();
  const [uuid, setUuid] = React.useState('');
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUuid(e.target.value);
  }
  const navigate = useNavigate();

  const handleImpersonate = async () => {
    const result = await useImpersonateErolUser.impersonateErolUser(uuid);
    const token = get(result, 'data.impersonateErolUser.token');
    saveToken(token);
    navigate('/');
  };

  return (
    <Grid columns={1}>
      <h1>Impersonar usuario</h1>
      <TextField
        title={'Ingresa el ID'}
        type={'text'}
        onChange={handleOnChange}
        label={'Ingresa el ID'}
        variant={'outlined'}
      />
      <Button
        className={
          'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
        }
        onClick={handleImpersonate}
        title={'Impersonar'}
        variant={'contained'}
      >
        {' '}
        Impersonar
      </Button>
    </Grid>
  );
}
