import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation } from 'react-router-dom';

import PageTitle from '../navigation/page-title';
import usePriceRangesGroupQuery from '../../hooks/usePriceRangesGroupQuery';
import LoadingNotification from '../notifications/loading-notification';
import { PriceRangesProvider } from './price-ranges-context';
import PriceRanges from './price-ranges';
import PriceRangesGroupInformation from './price-ranges-group-information';
import PriceRangesGroupFooterEdit from './price-ranges-group-footer-edit';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2)
  }
}));

const PriceRangesGroupEdit: React.FunctionComponent = () => {
  const classes = useStyles();

  const location = useLocation();
  const editIndex = location.pathname.indexOf('/price-ranges');
  const groupUuid = location.pathname
    .slice(editIndex)
    .replace('/price-ranges/', '')
    .replace('/edit', '');

  const { priceRangesGroup } = usePriceRangesGroupQuery(groupUuid);

  if (!priceRangesGroup) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Edit price ranges group" />
      <PriceRangesProvider initialPriceRangesGroupValues={priceRangesGroup}>
        <>
          <Card className={classes.card}>
            <CardContent>
              <PriceRangesGroupInformation />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <PriceRanges />
            <PriceRangesGroupFooterEdit />
          </Card>
        </>
      </PriceRangesProvider>
    </>
  );
};

export default PriceRangesGroupEdit;
