import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: 'bold'
  }
}))(TableCell);

const HEAD_CELLS = [
  {
    id: 'employeeName',
    numeric: false,
    disablePadding: true,
    label: 'Employee name'
  },
  {
    id: 'dateRequested',
    numeric: true,
    disablePadding: false,
    label: 'Date requested'
  },
  {
    id: 'amountRequested',
    numeric: true,
    disablePadding: false,
    label: 'Amount requested'
  }
];

interface Props {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const CustomTableHead: React.FunctionComponent<Props> = ({
  onSelectAllClick,
  numSelected,
  rowCount
}: Props) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all advances' }}
          />
        </TableCell>
        {HEAD_CELLS.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {t(headCell.label)}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
