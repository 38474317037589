import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

import { useTranslation } from 'react-i18next';

const PASSWORD_RULES = [
  '1 upcase letter',
  '1 downcase letter',
  '1 number',
  '1 symbol',
  'At least 8 chars'
];

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(1, 1, 0, 1)
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  listItemIcon: {
    fontSize: '12px'
  },
  listItemText: {
    fontSize: '12px'
  }
}));

const PasswordRules: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} className={classes.formContainer}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          {t('The password must include')}
        </Typography>
        <div>
          <List>
            {PASSWORD_RULES.map((rule, index) => (
              <ListItem key={index} dense>
                <ListItemIcon style={{ minWidth: '20px' }}>
                  <FiberManualRecord style={{ fontSize: 10 }} />
                </ListItemIcon>
                <ListItemText
                  primary={t(rule)}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default PasswordRules;
