import React from 'react';

import useDetectSessionActivity from '../hooks/useDetectSessionActivity';
import useRefreshSessionToken from '../hooks/useRefreshSessionToken';
import { useUserInfoContext } from './user-info-context';

interface Props {
  children: React.ReactNode;
}

const SessionProvider: React.FunctionComponent<Props> = ({
  children
}: Props) => {
  const { userInfo, userRole } = useUserInfoContext();

  const isActive = useDetectSessionActivity({ userRole });
  useRefreshSessionToken({ userInfo, isActive });

  return <>{children}</>;
};

export default SessionProvider;
