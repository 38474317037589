import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import PriceRange from './price-range';
import PriceRangesHeader from './price-ranges-header';
import { usePriceRangesContext } from './price-ranges-context';
import PriceRangeCreate from './price-range-create';
import PriceRangeRemove from './price-range-remove';
import PriceRangeEdit from './price-range-edit';

const PriceRanges: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { priceRanges } = usePriceRangesContext();

  return (
    <Grid item xs={12}>
      <PriceRangesHeader />
      {priceRanges.map((range, key) => (
        <PriceRange
          key={range.uuid}
          priceRange={range}
          title={`${t('Range')} ${key + 1}`}
        />
      ))}
      <PriceRangeCreate />
      <PriceRangeRemove />
      <PriceRangeEdit />
    </Grid>
  );
};

export default PriceRanges;
