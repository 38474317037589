import React from 'react';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fieldToSelect, SelectProps } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { DEFAULT_DATE_FORMAT } from '../../config/constants';
import useStyles from './styles';
import LuxonAdapter from '@date-io/luxon';

const INVALID_DATE_MESSAGE = 'Invalid date format';

interface SelectInputProps extends SelectProps {
  label: string;
  value: string;
}

const DateInput: React.FunctionComponent<SelectInputProps> = (
  props: SelectInputProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectProps = fieldToSelect(props) as unknown as SelectInputProps;
  const name = get(selectProps, 'name', '');

  const luxon = new LuxonAdapter();
  return (
    <FormControl
      className={classes.formControl}
      error={!!props.form.errors[name] && !!props.form.touched[name]}
      variant="outlined"
      margin="normal"
    >
      <DatePicker
        label={t(selectProps.label)}
        disabled={selectProps.disabled}
        value={selectProps.value ? luxon.date(selectProps.value) : null}
        format={DEFAULT_DATE_FORMAT}
        slotProps={{
          textField: {
            helperText:
              !!props.form.errors[name] && !!props.form.touched[name]
                ? t(INVALID_DATE_MESSAGE)
                : '',
            error: !!props.form.errors[name] && !!props.form.touched[name]
          }
        }}
        onChange={(value, context): void => {
          props.form.setFieldValue(
            props.field.name,
            value ? new LuxonAdapter().date(value) : null
          );
        }}
      />
    </FormControl>
  );
};

export default DateInput;
