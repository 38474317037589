import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useNotificationContext } from '../../context/notification-context';

import NotificationWrapper from './notification-wrapper';

export type VariantType = 'error' | 'info' | 'success' | 'warning';

const AUTO_HIDE_DURATION = 2000;

const Notification: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const { notificationText, notificationType, clearNotification } =
    useNotificationContext();

  useEffect(() => {
    if (notificationText && notificationType) {
      setOpen(true);
    }
  }, [notificationText, notificationType]);

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
      TransitionProps={{
        onExited: (): void => {
          clearNotification();
        }
      }}
    >
      <div>
        <NotificationWrapper
          onClose={handleClose}
          variant={notificationType ? notificationType : 'error'}
          message={notificationText ? notificationText : ''}
        />
      </div>
    </Snackbar>
  );
};

export default Notification;
