import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  title: string;
  number: number;
  buttonText: string;
  path: string;
}

const DashboardStat: React.FunctionComponent<Props> = ({
  title,
  number,
  buttonText,
  path
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title={t(title)}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h2" variant="h3" color="textPrimary">
            {number}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="outlined"
          onClick={(): void => {
            navigate(path);
          }}
        >
          {t(buttonText)}
        </Button>
      </CardActions>
    </Card>
  );
};

export default DashboardStat;
