import React from 'react';

import useUpdateCurrentUserPasswordMutation from '../../hooks/useUpdateCurrentUserPasswordMutation';
import PageTitle from '../navigation/page-title';
import UpdateCurrentUserPasswordForm, {
  FormValues
} from './update-current-user-password-form';

const UpdateCurrentUserPassword: React.FunctionComponent = () => {
  const { loading, updateCurrentUserPassword } =
    useUpdateCurrentUserPasswordMutation();

  const handleSubmit = (values: FormValues): void => {
    updateCurrentUserPassword(values);
  };

  return (
    <>
      <PageTitle title="Update password" />
      <UpdateCurrentUserPasswordForm
        onSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
};

export default UpdateCurrentUserPassword;
