import React from 'react';
import { get } from 'lodash';
import Grid from '@mui/material/Grid';

import PageTitle from '../navigation/page-title';
import useCompanyStatsQuery from '../../hooks/useCompanyStatsQuery';
import DashboardStat from './dashboard-stat';

const AdminDashboard: React.FunctionComponent = () => {
  const { companyStats } = useCompanyStatsQuery();

  const companyCount = get(companyStats, 'companyCount');
  const userCount = get(companyStats, 'userCount');

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <PageTitle title="Dashboard" />
      </Grid>
      {companyCount ? (
        <Grid item xs={12} md={4} lg={3}>
          <DashboardStat
            title="Number of companies"
            number={companyCount}
            buttonText="Companies list"
            path="/companies"
          />
        </Grid>
      ) : null}
      {userCount ? (
        <Grid item xs={12} md={4} lg={3}>
          <DashboardStat
            title="Number of users"
            number={userCount}
            buttonText="Users list"
            path="/employees"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default AdminDashboard;
