import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';

const TOKEN_AUTH_MUTATION = loader('../graphql/tokenAuth.graphql');

interface TokenAuthProps {
  username: string;
  password: string;
  otp?: string;
}

interface TokenAuthResponse {
  token: string;
  require2fa: boolean;
}

interface Response {
  tokenAuth: (values: TokenAuthProps) => Promise<TokenAuthResponse>;
  loading?: boolean;
  error?: Error;
}

const useTokenAuthMutation = (): Response => {
  const { showNotification } = useNotificationContext();

  const [mutation, { loading, error }] = useMutation(TOKEN_AUTH_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    error,
    tokenAuth: async (values: TokenAuthProps): Promise<TokenAuthResponse> => {
      const response = await mutation({
        variables: omitBy(values, (value) => !value)
      });

      return {
        token: get(response, 'data.tokenAuth.token'),
        require2fa: get(response, 'data.tokenAuth.require2fa')
      };
    }
  };
};

export default useTokenAuthMutation;
