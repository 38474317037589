import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import { ValidationSchemaEditForm } from './utils';

export type FormValues = {
  minimum: string;
  cost: string;
};

interface Props {
  onSubmit: (T: FormValues) => void;
  validationSchema: ValidationSchemaEditForm;
  initialValues: FormValues;
}

const PriceRangeEditForm: React.FunctionComponent<Props> = ({
  onSubmit,
  validationSchema,
  initialValues
}: Props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions): void => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props): React.ReactNode => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <Field name="minimum" label="Minimum" component={TextInput} />
              </Grid>
              <Grid item xs={12}>
                <Field name="cost" label="Cost" component={TextInput} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <SubmitButton buttonText="Save" />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default PriceRangeEditForm;
