import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import {
  BankAccountTypes,
  ErolUserIdTypes,
  ErolUserSexTypes,
  ErolUserCompanyDepartmentTypes,
  ErolUserMaritalStatusTypes
} from '../definitions.d';

const UPDATE_USER_MUTATION = loader('../graphql/updateEmployee.graphql');
const USER_QUERY = loader('../graphql/user.graphql');

export interface UpdateEmployeeProps {
  availableAmount?: number;
  bankAccountNumber?: string;
  bankAccountType?: BankAccountTypes;
  bankUuid?: string;
  birthDate?: string;
  buildingNumber?: string;
  cityUuid?: string;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  companyUuid?: string;
  countryUuid?: string;
  email?: string;
  familyDependents?: number;
  firstName?: string;
  idType?: ErolUserIdTypes;
  lastName?: string;
  mainStreet?: string;
  maritalStatus?: ErolUserMaritalStatusTypes;
  neighborhoodUuid?: string;
  phone?: string;
  salary?: number;
  secondaryStreet?: string;
  sexType?: ErolUserSexTypes;
  stateUuid?: string;
  taxId?: string;
  uuid: string;
  zipCode?: string;
}

interface Response {
  updateEmployee: (values: UpdateEmployeeProps) => void;
  loading?: boolean;
  error?: Error;
}

const useUpdateEmployeeMutation = (): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [mutation, { loading, error }] = useMutation(UPDATE_USER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    onCompleted: () => {
      showNotification(t('Employee updated successfully'), 'success');
    }
  });

  return {
    loading,
    error,
    updateEmployee: (values: UpdateEmployeeProps): void => {
      mutation({
        variables: omitBy(values, (value) => !value),
        refetchQueries: [
          {
            query: USER_QUERY,
            variables: { uuid: values.uuid }
          }
        ]
      });
    }
  };
};

export default useUpdateEmployeeMutation;
