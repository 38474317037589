import React from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { formatDate, convertToDollars } from '../../utils/formatters';
import useMyAdvancesQuery, {
  AdvanceResponse
} from '../../hooks/useMyAdvancesQuery';
import SimpleTable, { ColumnProps, RowProps } from '../tables/simple-table';
import PageTitle from '../navigation/page-title';
import QueryAdvancesFilterForm, {
  FormProps
} from './query-advances-filter-form';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'uuid', label: 'Identifier' },
  { id: 'requestDate', label: 'Request date' },
  { id: 'status', label: 'Status' },
  { id: 'advanceAmount', label: 'Requested amount', align: 'right' },
  { id: 'effectiveTransferAmount', label: 'Total amount', align: 'right' },
  { id: 'bankAccountNumber', label: 'Bank account number', align: 'center' },
  { id: 'bankName', label: 'Bank' }
];

const QueryAdvances: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { getAdvances, advances, loading } = useMyAdvancesQuery();

  const onSubmit = (values: FormProps): void => {
    getAdvances({
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate)
    });
  };

  const formatAdvances = (items: AdvanceResponse[]): RowProps[] => {
    return items.map((advance) => {
      const requestDate = get(advance, 'requestDate');

      return {
        uuid: get(advance, 'identifier'),
        bankName: get(advance, 'bank.name'),
        bankAccountNumber: get(advance, 'bankAccountNumber'),
        status: t(get(advance, 'status') as string),
        requestDate: requestDate ? formatDate(requestDate) : '',
        effectiveTransferAmount: `$${convertToDollars(
          get(advance, 'effectiveTransferAmount', 0)
        )}`,
        advanceAmount: `$${convertToDollars(get(advance, 'advanceAmount', 0))}`
      };
    });
  };

  return (
    <>
      <PageTitle title="Search advances" />
      <QueryAdvancesFilterForm onSubmit={onSubmit} loading={loading} />
      {advances && (
        <SimpleTable
          columns={TABLE_COLUMNS}
          isLoading={loading}
          rows={formatAdvances(advances)}
        />
      )}
    </>
  );
};

export default QueryAdvances;
