import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useNotificationContext } from '../context/notification-context';
import { isEmpty } from 'lodash';

const COMPANY_TERMS_AND_CONDITIONS_QUERY = loader(
  '../graphql/companyTermsAndConditions.graphql'
);

export interface CompanyTermsAndConditionsType {
  id: string;
  uuid: string;
  text: string;
  createdAt: string;
  updatedAt: string;
}

interface CompanyTermsAndConditionsQueryResponse {
  companyTermsAndConditions: CompanyTermsAndConditionsType | null;
  loading?: boolean;
  error?: Error;
}

const useCompanyTermsAndConditionsQuery = (
  companyUuid: string
): CompanyTermsAndConditionsQueryResponse => {
  const { showNotification } = useNotificationContext();

  const { loading, error, data } = useQuery(
    COMPANY_TERMS_AND_CONDITIONS_QUERY,
    {
      variables: { companyUuid },
      skip: isEmpty(companyUuid)
    }
  );

  return {
    companyTermsAndConditions: data?.companyTermsAndConditions || null,
    loading,
    error
  };
};

export default useCompanyTermsAndConditionsQuery;
