import React from 'react';
import Button from '@mui/material/Button';

interface Props {
  title: string;
  handleShowDetails: () => void;
}

const AdvanceDetailsButton: React.FunctionComponent<Props> = ({
  title,
  handleShowDetails
}: Props) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleShowDetails();
  };
  return (
    <Button color="primary" onClick={handleClick}>
      {title}
    </Button>
  );
};

export default AdvanceDetailsButton;
