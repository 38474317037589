import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { ErolUserRoles } from '../definitions.d';

const UPDATE_MANAGER_MUTATION = loader('../graphql/updateManager.graphql');

export interface UpdateManagerProps {
  apiOnly?: boolean;
  email?: string;
  companyUuids?: string[] | null;
  firstName?: string;
  idType?: string;
  lastName?: string;
  notifyAdvances?: boolean;
  phone?: string;
  salary?: number;
  sexType?: string;
  taxId?: string;
  userRole?: ErolUserRoles;
}

interface Response {
  updateManager: (values: UpdateManagerProps) => void;
  loading?: boolean;
  error?: Error;
}

const useUpdateManagerMutation = (userUuid: string): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation(UPDATE_MANAGER_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    onCompleted: () => {
      showNotification(t('Manager updated successfully'), 'success');
      navigate('/managers');
    }
  });

  return {
    loading,
    error,
    updateManager: (values: UpdateManagerProps): void => {
      const user = omitBy(
        values,
        (value) => typeof value === 'undefined' || value === null
      ) as UpdateManagerProps;
      mutation({
        variables: { ...user, uuid: userUuid }
      });
    }
  };
};

export default useUpdateManagerMutation;
