import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { LocationType, LocationTypes } from '../definitions.d';

const LOCATIONS_QUERY = loader('../graphql/locations.graphql');

interface LocationsQueryResponse {
  locations?: LocationType[];
  loading?: boolean;
  error?: Error;
}

const useLocationsQuery = (
  locationType: LocationTypes
): LocationsQueryResponse => {
  const { showNotification } = useNotificationContext();

  const { data, loading, error } = useQuery(LOCATIONS_QUERY, {
    variables: { locationType },
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });

  return { locations: get(data, 'locations'), loading, error };
};

export default useLocationsQuery;
