import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useNotificationContext } from '../context/notification-context';
import { useNavigate } from 'react-router-dom';

// Cargar la definición de la mutación desde un archivo .graphql
const ACCEPT_TERMS_AND_CONDITIONS_MUTATION = loader(
  '../graphql/acceptTermsAndCondition.graphql'
);

interface AcceptTermsAndConditionsProps {
  termsAndConditionsVersion: string;
}

interface AcceptTermsAndConditionsResponse {
  acceptTermsAndConditions: (version: string) => void;
  loading: boolean;
  error: Error | undefined;
}

const useAcceptTermsAndConditions = (): AcceptTermsAndConditionsResponse => {
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation(
    ACCEPT_TERMS_AND_CONDITIONS_MUTATION,
    {
      onError: (e) => {
        // Mostrar notificación en caso de error
        showNotification(e.message, 'error');
      },
      onCompleted: () => {
        // Mostrar notificación de éxito y redirigir si es necesario
        showNotification(
          'Terms and Conditions accepted successfully',
          'success'
        );
      }
    }
  );

  const acceptTermsAndConditions = (version: string) => {
    mutation({
      variables: { termsAndConditionsVersion: version }
    });
  };

  return {
    acceptTermsAndConditions,
    loading,
    error
  };
};

export default useAcceptTermsAndConditions;
