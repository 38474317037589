import React from 'react';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { Formik, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import useUserPasswordResetByEmailMutation from '../../hooks/useUserPasswordResetByEmailMutation';
import { REQUIRED_FIELD_TEXT, EMAIL_FIELD_TEXT } from '../../config/constants';
import ForgotPasswordWrapper from './forgot-password-wrapper';

const VALIDATION_SCHEMA = yup.object({
  email: yup.string().email(EMAIL_FIELD_TEXT).required(REQUIRED_FIELD_TEXT)
});

const INITIAL_VALUES = {
  email: ''
};

interface Props {
  email: string;
}

const LoginForm: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { sendEmail, loading } = useUserPasswordResetByEmailMutation(true);

  const onReturn = (): void => {
    navigate('/login');
  };

  const onSubmit = (values: Props): void => {
    sendEmail(values);
  };

  return (
    <ForgotPasswordWrapper
      title="Recover password"
      subtitle="Please enter the email you used to subscribe to E-rol, we will send an email with a link you can use to change your password"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }): void => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }): React.ReactNode => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="email" label="Email" component={TextInput} />
                </Grid>
                <Grid item xs={6}>
                  <SubmitButton isLoading={loading} buttonText="Send email" />
                </Grid>
                <Grid item xs={6}>
                  <SimpleButton
                    title="Go Back"
                    inheritColor={true}
                    buttonId="return-button"
                    onClick={onReturn}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </ForgotPasswordWrapper>
  );
};

export default LoginForm;
