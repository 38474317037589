import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get, omitBy } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import {
  UserType,
  BankAccountTypes,
  ErolUserIdTypes,
  ErolUserSexTypes,
  ErolUserCompanyDepartmentTypes,
  ErolUserMaritalStatusTypes
} from '../definitions.d';
import { useState } from 'react';

const CREATE_EMPLOYEE_MUTATION = loader('../graphql/createEmployee.graphql');
const EMPLOYEES_QUERY = loader('../graphql/employees.graphql');

export interface CreateEmployeeProps {
  availableAmount?: number;
  bankAccountNumber?: string;
  bankAccountType?: BankAccountTypes;
  bankUuid?: string;
  birthDate?: string;
  buildingNumber: string;
  cityUuid: string;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  companyUuid: string;
  countryUuid: string;
  email: string;
  familyDependents?: number;
  firstName: string;
  idType: ErolUserIdTypes;
  lastName: string;
  mainStreet: string;
  maritalStatus?: ErolUserMaritalStatusTypes;
  neighborhoodUuid?: string;
  phone: string;
  salary?: number;
  secondaryStreet?: string;
  sexType: ErolUserSexTypes;
  stateUuid: string;
  taxId: string;
  username: string;
  zipCode?: string;
}

interface Response {
  mutation: (values: CreateEmployeeProps) => void;
  loading?: boolean;
  fieldErrors: FieldErrors;
}

export interface FieldErrors {
  [key: string]: string;
}

const useCreateEmployeeMutation = (): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const [createEmployee, { loading }] = useMutation(CREATE_EMPLOYEE_MUTATION, {
    onError: (e) => {
      showNotification(e.message, 'error');

      const newFieldErrors: FieldErrors = {};

      e.graphQLErrors.forEach((graphQLError) => {
        const errors = graphQLError.extensions?.field_errors as FieldErrors[];
        if (errors && errors.length > 0) {
          errors.forEach((fieldError) => {
            const fieldErrorKey = fieldError?.field as string;
            newFieldErrors[fieldErrorKey] = fieldError?.message;
          });
        }
      });
      setFieldErrors(newFieldErrors);
    },
    onCompleted: () => {
      showNotification(t('Employee created successfully'), 'success');
      navigate('/employees');
    }
  });

  return {
    loading,
    fieldErrors,
    mutation: (values: CreateEmployeeProps): void => {
      createEmployee({
        variables: omitBy(values, (value) => !value),
        update(cache, { data }) {
          const createdEmployee: UserType = get(data, 'createEmployee.user');
          let cachedValues;
          try {
            cachedValues = cache.readQuery({ query: EMPLOYEES_QUERY });
          } catch {
            cachedValues = {};
          }
          const cachedEmployees: UserType[] = get(
            cachedValues,
            'employees'
          ) as unknown as UserType[];
          if (cachedEmployees) {
            cache.writeQuery({
              query: EMPLOYEES_QUERY,
              data: {
                employees: cachedEmployees.concat(createdEmployee)
              }
            });
          }
        }
      });
    }
  };
};

export default useCreateEmployeeMutation;
