import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, Typography, Button, List, ListItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import useBanksQuery from '../../hooks/useBanksQuery';
import useCompanyBanksQuery from '../../hooks/useCompanyBanksQuery';
import useManageCompanyBankMutation, {
  CompanyBankTypeInput
} from '../../hooks/useManageCompanyBankMutation';
import PageTitle from '../navigation/page-title';
import LoadingNotification from '../notifications/loading-notification';
import { formatBanks } from '../../utils/formatters';
import { CompanyBankType } from '../../definitions.d';
import AlertDialog from '../dialogs/alert-dialog';
import CreateCompanyBank from './create-company-bank';
import EditCompanyBank, {
  SelectCompanyBankState,
  SelectCompanyBankActions
} from './edit-company-bank';
import { FormValues } from './company-bank-form';

const useStyles = makeStyles({
  headerText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '12px'
  },
  buttonsContainer: {
    marginTop: '20px'
  },
  button: {
    width: '100%'
  }
});

const formatCompanyBanks = (
  companyBanks: CompanyBankType[]
): CompanyBankTypeInput[] =>
  companyBanks.map((companyBank) => ({
    uuid: get(companyBank, 'uuid'),
    bankUuid: get(companyBank, 'bank.uuid'),
    code: get(companyBank, 'code')
  }));

const ManageCompanyBanks: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [selectedState, setSelectedState] =
    useState<SelectCompanyBankState | null>(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const companyUuid = pathname.replace('/companies/', '').replace('/banks', '');

  const { banks } = useBanksQuery();
  const { companyBanks, hasApiConnection } = useCompanyBanksQuery(companyUuid);

  const { manageCompanyBank, loading } =
    useManageCompanyBankMutation(companyUuid);

  const formattedBanks = formatBanks(banks);

  const toggleShowAddNew = (): void => {
    setShowAddNew(!showAddNew);
    setSelectedState(null);
  };

  const handleClose = (): void => setSelectedState(null);

  const handleSelect = (
    uuid: string,
    action: SelectCompanyBankActions
  ): void => {
    setSelectedState({ uuid, action });
    setShowAddNew(false);
  };

  const createCompanyBank = async (values: FormValues): Promise<void> => {
    if (companyBanks) {
      await manageCompanyBank([
        ...formatCompanyBanks(companyBanks),
        {
          bankUuid: get(values, 'bank.value')!,
          code: get(values, 'code')
        }
      ]);
    }
    setShowAddNew(false);
  };

  const deleteCompanyBank = async (): Promise<void> => {
    if (selectedState && companyBanks) {
      const formattedCompanyBanks = formatCompanyBanks(companyBanks);
      await manageCompanyBank(
        formattedCompanyBanks.map((companyBank) => {
          if (companyBank.uuid === selectedState.uuid) {
            return {
              uuid: selectedState.uuid,
              bankUuid: null,
              code: get(companyBank, 'code')
            };
          }
          return companyBank;
        })
      );
    }
    setSelectedState(null);
  };

  const updateCompanyBank = (values: FormValues): void => {
    if (selectedState && companyBanks) {
      const formattedCompanyBanks = formatCompanyBanks(companyBanks);
      manageCompanyBank(
        formattedCompanyBanks.map((companyBank) => {
          if (companyBank.uuid === selectedState.uuid) {
            return {
              uuid: selectedState.uuid,
              bankUuid: get(values, 'bank.value')!,
              code: get(values, 'code')
            };
          }
          return companyBank;
        })
      );
    }
    setSelectedState(null);
  };

  useEffect(() => {
    if (typeof hasApiConnection !== 'undefined' && hasApiConnection === false) {
      navigate(`/companies/${companyUuid}/edit`);
    }
  }, [companyUuid, hasApiConnection, history]);

  if (!banks || !companyBanks) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Banks lookup" />
      <Paper>
        <List>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={5}>
                <Typography className={classes.headerText} align="center">
                  {t('Bank')}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={5}>
                <Typography className={classes.headerText} align="center">
                  {t('Code')}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {companyBanks.map((companyBank) => (
            <ListItem key={companyBank.uuid}>
              <Grid container spacing={3}>
                <EditCompanyBank
                  companyBank={companyBank}
                  onClose={handleClose}
                  banks={formattedBanks}
                  selectedState={selectedState}
                  loading={loading}
                  onSelect={handleSelect}
                  onUpdate={updateCompanyBank}
                />
              </Grid>
            </ListItem>
          ))}
          {companyBanks.length ? null : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center">
                  {t('No results were found')}
                </Typography>
              </Grid>
            </Grid>
          )}
          <ListItem>
            <Grid container spacing={3}>
              {showAddNew ? (
                <CreateCompanyBank
                  onCreate={createCompanyBank}
                  onClose={toggleShowAddNew}
                  banks={formattedBanks}
                  loading={loading}
                />
              ) : null}
            </Grid>
          </ListItem>
        </List>
      </Paper>
      <Grid container spacing={3} className={classes.buttonsContainer}>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={(): void => navigate(`/companies/${companyUuid}/edit`)}
            className={classes.button}
          >
            {t('Return')}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={toggleShowAddNew}
            disabled={showAddNew || !!selectedState}
            className={classes.button}
          >
            {t('Add bank')}
          </Button>
        </Grid>
      </Grid>
      <AlertDialog
        open={
          selectedState
            ? selectedState.action === SelectCompanyBankActions.DELETE
            : false
        }
        title={t('Remove bank')}
        content={t('Are you sure you want to remove the bank')}
        handleClose={handleClose}
        handleConfirm={deleteCompanyBank}
      />
    </>
  );
};

export default ManageCompanyBanks;
