import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useUpdatePriceRangesGroupMutation from '../../hooks/useUpdatePriceRangesGroupMutation';
import { useNotificationContext } from '../../context/notification-context';
import { usePriceRangesContext } from './price-ranges-context';
import PriceRangesGroupFooter from './price-ranges-group-footer';
import { concatPriceRangesToSave } from './utils';

const PriceRangesGroupFooterCreate: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { priceRanges, priceRangesToRemove, priceRangesGroup } =
    usePriceRangesContext();

  const { showNotification } = useNotificationContext();

  const location = useLocation();
  const editIndex = location.pathname.indexOf('/price-ranges');
  const companyUuid = location.pathname
    .slice(0, editIndex)
    .replace('/companies/', '');

  const { updatePriceRangesGroup } =
    useUpdatePriceRangesGroupMutation(companyUuid);

  const handleSavePriceRange = (): void => {
    try {
      const priceRangesToSave = concatPriceRangesToSave(
        priceRanges,
        priceRangesToRemove
      );
      if (priceRangesToSave.length) {
        updatePriceRangesGroup({
          ...priceRangesGroup,
          priceRanges: priceRangesToSave
        });
      }
    } catch (error: any) {
      showNotification(
        t('priceRangesGroupCreateError', { message: error.message }),
        'error'
      );
    }
  };

  return (
    <PriceRangesGroupFooter
      handleSavePriceRange={handleSavePriceRange}
      companyUuid={companyUuid}
    />
  );
};

export default PriceRangesGroupFooterCreate;
