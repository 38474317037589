import React from 'react';
import Grid from '@mui/material/Grid';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { find, get } from 'lodash';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import SelectInput from '../forms/select-input';
import SelectInputWithTranslation from '../forms/select-input-with-translation';
import SimpleButton from '../forms/simple-button';
import SubmitButton from '../forms/submit-button';
import DateInput from '../forms/date-input';
import {
  BANK_ACCOUNT_ITEMS,
  COMPANY_DEPARTMENT_ITEMS,
  DECIMAL_VALUE_VALIDATION_MESSAGE,
  IDTYPE_ITEMS,
  MARITAL_STATUS_ITEMS,
  PHONE_NUMBER_VALIDATION_MESSAGE,
  REGULAR_EXPRESSION_DECIMAL,
  REGULAR_EXPRESSION_PHONE_NUMBER,
  REQUIRED_FIELD_TEXT,
  SEX_TYPE_ITEMS
} from '../../config/constants';
import {
  BankAccountTypes,
  CompanyType,
  ErolUserCompanyDepartmentTypes,
  ErolUserIdTypes,
  ErolUserMaritalStatusTypes,
  ErolUserRoles,
  ErolUserSexTypes,
  SelectItemType
} from '../../definitions.d';
import AutocompleteInput from '../forms/autocomplete-input';
import LocationAutocompleteInput from '../forms/location-autocomplete-input';
import FormSectionTitle from '../forms/form-section-title';
import { formatCompanies } from '../../utils/formatters';
import { useUserInfoContext } from '../../context/user-info-context';

export interface FormValues {
  username: string;
  availableAmount?: string;
  bankAccountNumber: string;
  bankAccountType: BankAccountTypes;
  bankUuid: string;
  birthDate?: string;
  buildingNumber: string;
  city: SelectItemType | null;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  companyUuid: string;
  country: SelectItemType | null;
  email: string;
  familyDependents?: string;
  firstName: string;
  idType?: ErolUserIdTypes;
  lastName: string;
  mainStreet: string;
  maritalStatus: ErolUserMaritalStatusTypes | null;
  neighborhood?: SelectItemType | null;
  phone: string;
  salary?: string;
  secondaryStreet?: string;
  sexType?: ErolUserSexTypes;
  state: SelectItemType | null;
  taxId?: string;
  zipCode?: string;
  nextAdvanceDate?: string;
  informativeMessage?: string;
}

const validationSchema = yup.object({
  availableAmount: yup
    .string()
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  bankAccountNumber: yup.string().required(REQUIRED_FIELD_TEXT),
  bankAccountType: yup.string().required(REQUIRED_FIELD_TEXT),
  bankUuid: yup.string().required(REQUIRED_FIELD_TEXT),
  companyUuid: yup.string().required(REQUIRED_FIELD_TEXT),
  email: yup.string().email().required(REQUIRED_FIELD_TEXT),
  firstName: yup.string().required(REQUIRED_FIELD_TEXT),
  idType: yup
    .string()
    .oneOf([ErolUserIdTypes.CEDULA, ErolUserIdTypes.PASSPORT])
    .required(REQUIRED_FIELD_TEXT),
  lastName: yup.string().required(REQUIRED_FIELD_TEXT),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_PHONE_NUMBER, PHONE_NUMBER_VALIDATION_MESSAGE),
  salary: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE),
  sexType: yup
    .string()
    .oneOf([ErolUserSexTypes.MALE, ErolUserSexTypes.FEMALE])
    .required(REQUIRED_FIELD_TEXT),
  taxId: yup.string().required(REQUIRED_FIELD_TEXT),
  country: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  state: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  city: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  neighborhood: yup
    .object({
      value: yup.string(),
      label: yup.string()
    })
    .nullable(),
  mainStreet: yup.string().required(REQUIRED_FIELD_TEXT),
  secondaryStreet: yup.string().nullable(),
  buildingNumber: yup.string().required(REQUIRED_FIELD_TEXT),
  zipCode: yup.string().nullable(),
  username: yup.string().required(REQUIRED_FIELD_TEXT),
  companyDepartment: yup
    .string()
    .oneOf(Object.keys(ErolUserCompanyDepartmentTypes)),
  familyDependents: yup.number(),
  maritalStatus: yup
    .string()
    .oneOf(Object.keys(ErolUserMaritalStatusTypes))
    .nullable(),
  birthDate: yup.string().nullable()
});

interface Props {
  initialValues: FormValues;
  isLoading?: boolean;
  onSubmit: (values: FormValues) => void;
  onPasswordReset: (email: string) => void;
  companies: CompanyType[];
  banks: SelectItemType[];
  locations: SelectItemType[];
}

const EmployeeCreateForm: React.FunctionComponent<Props> = ({
  initialValues,
  isLoading,
  onSubmit,
  onPasswordReset,
  companies,
  banks,
  locations
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userRole } = useUserInfoContext();

  const companiesWithRestrictions = [
    '553ec079-3f72-47af-a2f1-272a423246ef',
    'bc75f5c8-9e60-415e-bde0-7fbe3a0fd428',
    '0eb9b14a-0fb2-4f61-9d20-7261c5694116'
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions): void => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {(props): React.ReactNode => {
        const companySelected = props.values.companyUuid
          ? find(companies, ['uuid', props.values.companyUuid])
          : undefined;

        const companyHasApiConnection = companySelected
          ? get(companySelected, 'hasApiConnection', false)
          : false;

        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="username"
                  label="Username"
                  disabled={true}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="email" label="Email" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="idType"
                  label="Identification type"
                  component={SelectInputWithTranslation}
                  items={IDTYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="taxId"
                  label="ID Card Number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  label="First name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  label="Last name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="phone" label="Phone" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="sexType"
                  label="Gender"
                  component={SelectInputWithTranslation}
                  items={SEX_TYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankUuid"
                  label="Bank"
                  component={SelectInput}
                  items={banks}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankAccountNumber"
                  label="Bank account number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankAccountType"
                  label="Bank account type"
                  component={SelectInputWithTranslation}
                  items={BANK_ACCOUNT_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyUuid"
                  label="Company"
                  component={SelectInput}
                  items={formatCompanies(companies)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyDepartment"
                  label="Company department"
                  component={SelectInputWithTranslation}
                  items={COMPANY_DEPARTMENT_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="salary"
                  label="Salary"
                  component={TextInput}
                  disabled={
                    companiesWithRestrictions.includes(
                      props.values.companyUuid
                    ) && userRole !== ErolUserRoles.PLATFORM_MANAGER
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="availableAmount"
                  label="Available amount"
                  component={TextInput}
                  disabled={
                    !companyHasApiConnection ||
                    (companiesWithRestrictions.includes(
                      props.values.companyUuid
                    ) &&
                      userRole !== ErolUserRoles.PLATFORM_MANAGER)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="familyDependents"
                  label="Family dependents"
                  component={TextInput}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="maritalStatus"
                  label="Marital status"
                  component={SelectInputWithTranslation}
                  items={MARITAL_STATUS_ITEMS}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={6}>
                <Field
                  name="birthDate"
                  label="Birth date"
                  component={DateInput}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={6}>
                <Field
                  name="nextAdvanceDate"
                  label="Next advance date"
                  component={TextInput}
                  disabled={true}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} />
              <FormSectionTitle text="Workplace location" />
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  label="Country"
                  component={AutocompleteInput}
                  items={locations}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="state"
                  label="State"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  label="City"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="neighborhood"
                  label="Neighborhood"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="mainStreet"
                  label="Main street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="secondaryStreet"
                  label="Secondary street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="buildingNumber"
                  label="Building number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="zipCode" label="Zip code" component={TextInput} />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={4} sm={4}>
                <SubmitButton isLoading={isLoading} buttonText="Save" />
              </Grid>
              <Grid item xs={4} sm={4}>
                <SimpleButton
                  title="Reset password"
                  buttonId="password-reset-button"
                  isLoading={isLoading}
                  isDisabled={props.values.email !== initialValues.email}
                  onClick={(): void => {
                    return onPasswordReset(props.values.email);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <SimpleButton
                  title="Go Back"
                  inheritColor={true}
                  buttonId="return-button"
                  onClick={(): void => {
                    navigate('/employees');
                  }}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default EmployeeCreateForm;
