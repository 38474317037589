import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resourcesEn from './locales/translations.en.json';
import resourcesEs from './locales/translations.es.json';

const resources = {
  en: {
    translation: resourcesEn
  },
  es: {
    translation: resourcesEs
  }
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'es',
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
