import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useCreatePriceRangesGroupMutation from '../../hooks/useCreatePriceRangesGroupMutation';
import { useNotificationContext } from '../../context/notification-context';
import { usePriceRangesContext } from './price-ranges-context';
import PriceRangesGroupFooter from './price-ranges-group-footer';
import { concatPriceRangesToSave } from './utils';

const PriceRangesGroupFooterCreate: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { priceRanges, priceRangesToRemove, priceRangesGroup } =
    usePriceRangesContext();

  const { showNotification } = useNotificationContext();

  const location = useLocation();
  const companyUuid = location.pathname
    .replace('/companies/', '')
    .replace('/price-ranges/create', '');

  const { createPriceRangesGroup } =
    useCreatePriceRangesGroupMutation(companyUuid);

  const handleSavePriceRange = (): void => {
    try {
      const priceRangesToSave = concatPriceRangesToSave(
        priceRanges,
        priceRangesToRemove
      );
      if (priceRangesToSave.length) {
        createPriceRangesGroup({
          ...priceRangesGroup,
          priceRanges: priceRangesToSave
        });
      }
    } catch (error: any) {
      showNotification(
        t('priceRangesGroupCreateError', { message: error.message }),
        'error'
      );
    }
  };

  return (
    <PriceRangesGroupFooter
      handleSavePriceRange={handleSavePriceRange}
      companyUuid={companyUuid}
    />
  );
};

export default PriceRangesGroupFooterCreate;
