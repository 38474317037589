import React from 'react';
import { Grid } from '@mui/material';
import { Formik, Field, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';

import { SelectItemType } from '../../definitions.d';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import TextInput from '../forms/text-input';
import AutocompleteInput from '../forms/autocomplete-input';
import CreateCompanyBankButtons from './create-company-bank-buttons';

export type FormValues = {
  bank: SelectItemType | null;
  code: string;
};

const validationSchema = yup.object({
  bank: yup.object({
    value: yup.string().required(),
    label: yup.string().required()
  }),
  code: yup.string().required(REQUIRED_FIELD_TEXT)
});

interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  banks: SelectItemType[];
  onClose: () => void;
  disabled: boolean;
  loading?: boolean;
}

const CompanyBankForm: React.FunctionComponent<Props> = ({
  initialValues,
  onSubmit,
  banks,
  onClose,
  disabled,
  loading
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(
        values: FormValues,
        actions: FormikHelpers<FormValues>
      ): void => {
        onSubmit(values);
        actions.setSubmitting(false);
        setTimeout((): void => actions.resetForm(), 250);
      }}
      enableReinitialize
    >
      {(props): React.ReactNode => {
        return (
          <>
            <Grid item xs={6} sm={5}>
              <Field
                id={`autocomplete-${uuid()}`}
                name="bank"
                label=""
                component={AutocompleteInput}
                items={banks}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <Field
                name="code"
                label=""
                component={TextInput}
                disabled={disabled}
              />
            </Grid>
            {disabled ? null : (
              <Grid item xs={12} sm={2}>
                <CreateCompanyBankButtons
                  onConfirm={props.handleSubmit}
                  loading={loading}
                  onClose={(): void => {
                    props.resetForm();
                    onClose();
                  }}
                />
              </Grid>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default CompanyBankForm;
