import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import {
  EmployeeCompanyType,
  SelectItemType,
  UserEdge
} from '../../definitions.d';
import useEmployeesPagedQuery, {
  EmployeesQueryProps
} from '../../hooks/useEmployeesPagedQuery';
import CreateButton from '../forms/create-button';
import PageTitle from '../navigation/page-title';
import useEmployeeCompaniesQuery from '../../hooks/useEmployeeCompaniesQuery';
import useDeleteUserMutation from '../../hooks/useDeleteUserMutation';
import useSuspendUserMutation from '../../hooks/useSuspendUserMutation';
import useResumeUserMutation from '../../hooks/useResumeUserMutation';
import { useUserInfoContext } from '../../context/user-info-context';
import { useNotificationContext } from '../../context/notification-context';
import LoadingNotification from '../notifications/loading-notification';
import PaginatedTable, {
  ColumnProps,
  RowProps
} from '../tables/paginated-table';
import EmployeesListFilterForm, {
  FormProps
} from './employees-list-filter-form';

export type ColumnTypes =
  | 'username'
  | 'fullName'
  | 'company'
  | 'email'
  | 'taxId'
  | 'confirmed'
  | 'isSuspended';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'username', label: 'Username' },
  { id: 'fullName', label: 'Full Name' },
  { id: 'company', label: 'Company' },
  { id: 'email', label: 'Email' },
  { id: 'taxId', label: 'ID Card' },
  { id: 'confirmed', label: 'Active', align: 'center' },
  { id: 'isSuspended', label: 'Suspended', align: 'center' }
];

const formatEmployeeCompanies = (
  companies: EmployeeCompanyType[]
): SelectItemType[] => {
  return companies.map((company) => ({
    value: get(company, 'uuid'),
    label: get(company, 'commercialName')
  }));
};

export const filterResults = (
  employees: UserEdge[],
  columns: ColumnProps[]
): RowProps[] => {
  return employees.map((employee) => {
    const { node: user } = employee;
    return columns.reduce(
      (filteredColumns, column) => {
        const id = get(column, 'id') as ColumnTypes;
        switch (id) {
          case 'fullName':
            filteredColumns[id] = `${user.firstName} ${user.lastName}`;
            break;
          case 'company':
            if (user.company) {
              filteredColumns[id] = `${user.company.commercialName}`;
            } else {
              filteredColumns[id] = 'N/A';
            }
            break;
          case 'confirmed':
            filteredColumns[id] = user.confirmed ? (
              <CheckIcon color="primary" />
            ) : (
              <NotInterestedIcon color="action" />
            );
            break;
          case 'isSuspended':
            filteredColumns[id] = user.isSuspended ? (
              <CheckIcon color="error" />
            ) : null;
            break;
          default:
            filteredColumns[id] = user[id];
            break;
        }

        return filteredColumns;
      },
      { uuid: user.uuid } as RowProps
    );
  });
};

const EmployeesList: React.FunctionComponent = () => {
  const [filters, setFilters] = useState<EmployeesQueryProps>({
    companyUuid: null,
    search: null
  });
  const [resetPage, setResetPage] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const { employeeCompanies } = useEmployeeCompaniesQuery();
  const { userInfo } = useUserInfoContext();

  const { employeesPaged, loading, fetchMore } = useEmployeesPagedQuery({
    companyUuid: get(filters, 'companyUuid'),
    search: get(filters, 'search')
  });
  const totalCount = get(employeesPaged, 'totalCount');
  const pageInfo = get(employeesPaged, 'pageInfo');
  const employees = get(employeesPaged, 'edges');

  const deleteUserMutation = useDeleteUserMutation();

  const handleDeleteUser = (uuid: string): void => {
    const userUuid = get(userInfo, 'uuid');
    if (uuid === userUuid) {
      showNotification(t('User could not be deleted'), 'warning');
    } else {
      deleteUserMutation(uuid, 'employeesPaged');
    }
  };

  const suspendUserMutation = useSuspendUserMutation();

  const handleSuspendUser = (uuid: string): void => {
    const userUuid = get(userInfo, 'uuid');
    if (uuid === userUuid) {
      showNotification(t('User could not be suspended'), 'warning');
    } else {
      suspendUserMutation(uuid, 'employeesPaged');
    }
  };

  const resumeUserMutation = useResumeUserMutation();

  const handleResumeUser = (uuid: string): void => {
    const userUuid = get(userInfo, 'uuid');
    if (uuid === userUuid) {
      showNotification(t('User could not be resumed'), 'warning');
    } else {
      resumeUserMutation(uuid, 'employeesPaged');
    }
  };

  const onFilter = (values: FormProps) => {
    setResetPage(true);
    setFilters({
      companyUuid: (get(values, 'company.value') || null) as unknown as string,
      search: (get(values, 'search') || null) as unknown as string
    });
  };

  useEffect(() => {
    if (resetPage && loading) {
      setResetPage(false);
    }
  }, [loading, resetPage]);

  if (!employeeCompanies || !employeesPaged) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Employees" />
      <CreateButton
        onClick={(): void => {
          navigate('/employees/create');
        }}
      />
      <EmployeesListFilterForm
        companies={formatEmployeeCompanies(employeeCompanies)}
        onSubmit={onFilter}
        loading={loading}
      />
      <PaginatedTable
        columns={TABLE_COLUMNS}
        isLoading={loading}
        rows={filterResults(employees, TABLE_COLUMNS)}
        count={totalCount}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        handleEdit={(uuid: string): void => {
          navigate(`/employees/${uuid}/edit`);
        }}
        handleDelete={handleDeleteUser}
        handleSuspend={handleSuspendUser}
        handleResume={handleResumeUser}
        resetPage={resetPage}
      />
    </>
  );
};

export default EmployeesList;
