import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Create, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  onEdit: () => void;
  onRemove: () => void;
  disabled?: boolean;
}

const EditCompanyBankButtons: React.FunctionComponent<Props> = ({
  onEdit,
  onRemove,
  disabled
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Update')}>
        <span>
          <IconButton
            aria-label="edit"
            onClick={onEdit}
            disabled={disabled}
            data-testid="edit-button"
            size="large"
          >
            <Create />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('Delete')}>
        <span>
          <IconButton
            aria-label="delete"
            onClick={onRemove}
            disabled={disabled}
            data-testid="remove-button"
            size="large"
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default EditCompanyBankButtons;
