import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';

import { useRequestContext } from '../../context/request/request-context';
import { RequestStep, RequestType } from '../../context/request/reducer';
import LoadingNotification from '../notifications/loading-notification';
import useAdvanceAvailabilityQuery from '../../hooks/useAdvanceAvailabilityQuery';
import useRequestAdvanceMutation from '../../hooks/useRequestAdvanceMutation';
import CostDetails from './cost-details';
import AcceptConditionsForm from './accept-conditions-form';
import TermsOfServiceDialog from './terms-of-service-dialog';
import RequestedIndicator from './requested-indicator';
import { AdvanceAvailabilityType } from '../../definitions';

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  indicationsText: {
    marginTop: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0)
  }
}));

interface Props {
  onUpdateRouterHistory: (step: string, type: string) => void;
}

const RequestDetails: React.FunctionComponent<Props> = ({
  onUpdateRouterHistory
}: Props) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const classes = useStyles();

  const { state } = useRequestContext();
  const { amount } = state;

  const {
    advanceAvailability,
    loading: loadingAdvanceAvailability,
    error: errorAdvanceAvailability
  } = useAdvanceAvailabilityQuery(amount);

  const handleSuccessAdvance = () => setShowSuccess(true);

  const { requestAdvance, loading: loadingRequestAdvance } =
    useRequestAdvanceMutation({ onCompleted: handleSuccessAdvance });

  const [openTermsOfService, setOpenTermsOfService] = useState<boolean>(false);

  const handleTermsOfServiceDialog = (): void => {
    setOpenTermsOfService(!openTermsOfService);
  };

  const handleSubmit = async () => {
    await requestAdvance({
      amount: get(state, 'amount'),
      bankAccountNumber: get(
        state,
        'bankAccountInformation.bankAccountNumber',
        0
      ).toString(),
      bankAccountType: get(state, 'bankAccountInformation.bankAccountType'),
      bankUuid: get(state, 'bankAccountInformation.bankUuid'),
      reason: get(state, 'reason')
    });
  };

  useEffect(() => {
    if (errorAdvanceAvailability) {
      onUpdateRouterHistory(RequestStep.AMOUNT, RequestType.DEFAULT);
    }
  }, [errorAdvanceAvailability, onUpdateRouterHistory]);

  if (loadingAdvanceAvailability) {
    return <LoadingNotification />;
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {showSuccess ? (
          <RequestedIndicator />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('Request details')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <CostDetails
                amount={amount ? amount : 0}
                advanceAvailability={
                  advanceAvailability
                    ? advanceAvailability
                    : ({} as AdvanceAvailabilityType)
                }
              />
              <div className={classes.buttonContainer}>
                <Button color="primary" onClick={handleTermsOfServiceDialog}>
                  {t('Terms of service')}
                </Button>
              </div>
              <Typography align="center">
                {t(
                  'By clicking below, you are agreeing to our terms of service'
                )}
              </Typography>
              <div className={classes.buttonContainer}>
                <AcceptConditionsForm
                  loading={loadingRequestAdvance}
                  handleSubmit={handleSubmit}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <TermsOfServiceDialog
        open={openTermsOfService}
        handleClose={handleTermsOfServiceDialog}
      />
    </>
  );
};

export default RequestDetails;
