import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { get } from 'lodash';

import LoadingNotification from '../notifications/loading-notification';
import PageTitle from '../navigation/page-title';
import useBanksQuery from '../../hooks/useBanksQuery';
import { convertToDollars } from '../../utils/formatters';
import useProcessCashManagementFileMutation from '../../hooks/useProcessCashManagementFileMutation';
import SimpleTable, { ColumnProps, RowProps } from '../tables/simple-table';
import { AdvanceType } from '../../definitions.d';
import ProcessCashManagementFilterForm, {
  FormProps
} from './process-cash-management-filter-form';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'uuid', label: 'uuid' },
  { id: 'companyName', label: 'Company' },
  { id: 'userName', label: 'User' },
  { id: 'status', label: 'Status' },
  { id: 'totalAmount', label: 'Total amount', align: 'right' }
];

const ProcessCashManagement: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { banks } = useBanksQuery(true);
  const { advances, processCashManagementFile, loading } =
    useProcessCashManagementFileMutation();

  const handleProcessCashManagementFile = (values: FormProps): void => {
    processCashManagementFile({
      bankUuid: get(values, 'bank.value')! as unknown as string,
      file: get(values, 'file')
    });
  };

  const formatAdvances = (items: AdvanceType[]): RowProps[] => {
    return items.map((advance) => {
      const firstName = get(advance, 'erolUser.firstName');
      const lastName = get(advance, 'erolUser.lastName');

      return {
        uuid: get(advance, 'uuid').slice(-6),
        companyName: get(advance, 'erolUser.company.commercialName'),
        userName: `${firstName} ${lastName}`,
        status: t(get(advance, 'status') as string),
        totalAmount: `$${convertToDollars(get(advance, 'advanceAmount', 0))}`
      };
    });
  };

  if (!banks) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Process CM file" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <ProcessCashManagementFilterForm
            banks={banks}
            onSubmit={handleProcessCashManagementFile}
          />
        </Grid>
      </Grid>
      {advances && (
        <SimpleTable
          columns={TABLE_COLUMNS}
          isLoading={loading}
          rows={formatAdvances(advances)}
        />
      )}
    </>
  );
};

export default ProcessCashManagement;
