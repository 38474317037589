import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { PriceRangesGroupType } from '../definitions.d';

const PRICE_RANGES_GROUP_QUERY = loader('../graphql/priceRangesGroup.graphql');

interface PriceRangesGroupQueryResponse {
  priceRangesGroup?: PriceRangesGroupType;
  loading?: boolean;
  error?: Error;
}

const usePriceRangesGroupQuery = (
  uuid: string
): PriceRangesGroupQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(PRICE_RANGES_GROUP_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });
  return { priceRangesGroup: get(data, 'priceRangesGroup'), loading, error };
};

export default usePriceRangesGroupQuery;
