import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../navigation/page-title';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '20px'
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px'
  },
  button: {
    marginTop: '20px',
    width: '141px'
  }
}));

const ActionContainer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <PageTitle title={t('What you want to do today?')} />
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={(): void => {
            navigate('/request-advance');
          }}
        >
          {t('Request')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={(): void => {
            navigate('/my-advances');
          }}
        >
          {t('Search')}
        </Button>
      </div>
    </div>
  );
};

export default ActionContainer;
