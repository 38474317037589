import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '../context/notification-context';
import { PriceRangesGroupType } from '../definitions.d';

const UPDATE_PRICE_RANGES_GROUP_MUTATION = loader(
  '../graphql/updatePriceRangesGroup.graphql'
);

interface Response {
  updatePriceRangesGroup: (priceRangesGroup: PriceRangesGroupType) => void;
  loading?: boolean;
  error?: Error;
}

const useUpdatePriceRangesGroupMutation = (companyUuid: string): Response => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation(
    UPDATE_PRICE_RANGES_GROUP_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      onCompleted: () => {
        showNotification(
          t('Price ranges group updated successfully'),
          'success'
        );
        navigate(`/companies/${companyUuid}/price-ranges`);
      }
    }
  );

  return {
    loading,
    error,
    updatePriceRangesGroup: (priceRangesGroup: PriceRangesGroupType): void => {
      mutation({
        variables: { ...priceRangesGroup, companyUuid }
      });
    }
  };
};

export default useUpdatePriceRangesGroupMutation;
