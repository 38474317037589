import { orderBy, get } from 'lodash';
import * as yup from 'yup';

import {
  REQUIRED_FIELD_TEXT,
  REGULAR_EXPRESSION_DECIMAL
} from '../../config/constants';
import { PriceRangeType } from '../../definitions.d';
import { convertToDollars, convertToCents } from '../../utils/formatters';
import { ExtendedPriceRangeType } from './reducer';

const DECIMAL_VALUE_VALIDATION_MESSAGE = 'Only decimal values are allowed';
const MINIMUM_VALUE_VALIDATION_MESSAGE = 'Minimum value not valid';
const MAXIMUM_VALUE_VALIDATION_MESSAGE = 'Maximum value not valid';
const COST_VALUE_VALIDATION_MESSAGE = 'Cost value not valid';

export const orderPriceRanges = (
  priceRanges: ExtendedPriceRangeType[]
): ExtendedPriceRangeType[] => {
  return orderBy(priceRanges, 'rangeStart');
};

export const formatPriceRanges = (
  priceRanges: ExtendedPriceRangeType[]
): ExtendedPriceRangeType[] => {
  const numberOfItems = priceRanges.length;
  const orderedPriceRanges = orderPriceRanges(priceRanges);

  return orderedPriceRanges.map((range, key) => {
    const nextRange =
      key < numberOfItems - 1 ? orderedPriceRanges[key + 1] : undefined;

    return {
      ...range,
      rangeEnd: nextRange ? nextRange.rangeStart - 1 : range.rangeEnd,
      isNew: !!range.isNew
    };
  });
};

export const addPriceRange = (
  priceRanges: ExtendedPriceRangeType[],
  newPriceRange: ExtendedPriceRangeType
): ExtendedPriceRangeType[] => {
  const updatedPriceRanges = priceRanges.concat(newPriceRange);
  return formatPriceRanges(updatedPriceRanges);
};

export const removePriceRange = (
  priceRanges: ExtendedPriceRangeType[],
  priceRangeToRemove: ExtendedPriceRangeType
): ExtendedPriceRangeType[] => {
  const updatedPriceRanges = priceRanges.filter(
    (range) => range.uuid !== priceRangeToRemove.uuid
  );
  return formatPriceRanges(updatedPriceRanges);
};

export const editPriceRange = (
  priceRanges: ExtendedPriceRangeType[],
  priceRangeToUpdate: ExtendedPriceRangeType
): ExtendedPriceRangeType[] => {
  const updatedPriceRanges = priceRanges.map((range) => {
    if (range.uuid === priceRangeToUpdate.uuid) {
      return priceRangeToUpdate;
    }
    return range;
  });
  return formatPriceRanges(updatedPriceRanges);
};

export type ValidationSchemaCreateForm = yup.ObjectSchema<{
  maximum: string;
  minimum: string;
  cost: string;
}>;

export const validationSchemaForNewPriceRange = (
  minimumValue: number | null
): ValidationSchemaCreateForm =>
  yup
    .object({
      minimum: yup
        .string()
        .required(REQUIRED_FIELD_TEXT)
        .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
        .test('compare values', MINIMUM_VALUE_VALIDATION_MESSAGE, (value) => {
          const valueToInteger = convertToCents(value as string);
          return minimumValue === null
            ? valueToInteger >= 0
            : valueToInteger > minimumValue + 1;
        }),
      maximum: yup
        .string()
        .required(REQUIRED_FIELD_TEXT)
        .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
        .when(
          'minimum',
          (minimum: string, schema: ValidationSchemaCreateForm) => {
            return schema.test(
              'compare values',
              MAXIMUM_VALUE_VALIDATION_MESSAGE,
              (value) =>
                convertToCents(value as unknown as string) >
                convertToCents(minimum)
            );
          }
        ),
      cost: yup
        .string()
        .required(REQUIRED_FIELD_TEXT)
        .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
        .when(
          'maximum',
          (maximum: string, schema: ValidationSchemaCreateForm) => {
            return schema.test(
              'compare values',
              COST_VALUE_VALIDATION_MESSAGE,
              (value) =>
                convertToCents(value as unknown as string) <
                convertToCents(maximum)
            );
          }
        )
    })
    .defined();

export type ValidationSchemaEditForm = yup.ObjectSchema<{
  minimum: string;
  cost: string;
}>;

export const validationSchemaForEditPriceRange = (
  minimumValue: number | null,
  maximumValue: number
): ValidationSchemaEditForm =>
  yup
    .object({
      minimum: yup
        .string()
        .required(REQUIRED_FIELD_TEXT)
        .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
        .test('compare values', MINIMUM_VALUE_VALIDATION_MESSAGE, (value) => {
          const valueToInteger = convertToCents(value as string);
          return minimumValue === null
            ? valueToInteger >= 0 && valueToInteger < maximumValue - 1
            : valueToInteger > minimumValue + 1 &&
                valueToInteger < maximumValue - 1;
        }),
      cost: yup
        .string()
        .required(REQUIRED_FIELD_TEXT)
        .matches(REGULAR_EXPRESSION_DECIMAL, DECIMAL_VALUE_VALIDATION_MESSAGE)
        .test('compare values', COST_VALUE_VALIDATION_MESSAGE, (value) => {
          return convertToCents(value as string) < maximumValue - 1;
        })
    })
    .defined();

interface ValidationValues {
  minimumValue: number | null;
  maximumValue?: number;
}

export const getMinimumValueForCreatePriceRange = (
  priceRanges: ExtendedPriceRangeType[]
): ValidationValues => {
  const length = priceRanges.length;
  return { minimumValue: length ? priceRanges[length - 1].rangeStart : null };
};

export const getValuesForEditPriceRange = (
  priceRanges: ExtendedPriceRangeType[],
  uuid: string
): ValidationValues => {
  const length = priceRanges.length;

  if (length === 1) return { minimumValue: null };

  let minimumValue = null,
    maximumValue;
  for (let key = 0; key < length; key++) {
    const range = priceRanges[key];
    if (range.uuid === uuid) {
      const previousRange = key ? priceRanges[key - 1] : {};
      const nextRange = key + 1 < length ? priceRanges[key + 1] : {};
      minimumValue = get(previousRange, 'rangeStart', null);
      maximumValue = get(nextRange, 'rangeStart');
    }
  }
  return { maximumValue, minimumValue };
};

export const validatePriceRanges = (
  priceRanges: ExtendedPriceRangeType[]
): boolean => {
  priceRanges.forEach((range, key) => {
    const { minimumValue, maximumValue } = getValuesForEditPriceRange(
      priceRanges,
      get(range, 'uuid') as string
    );

    const isValid = maximumValue
      ? validationSchemaForEditPriceRange(
          minimumValue,
          maximumValue
        ).isValidSync({
          minimum: convertToDollars(range.rangeStart),
          cost: convertToDollars(get(range, 'price') as number)
        })
      : validationSchemaForNewPriceRange(minimumValue).isValidSync({
          minimum: convertToDollars(range.rangeStart),
          maximum: convertToDollars(range.rangeEnd),
          cost: convertToDollars(get(range, 'price') as number)
        });

    if (!isValid) throw new Error(`${key + 1}`);
  });

  return true;
};

export const formatPriceRangesToSave = (
  priceRanges: ExtendedPriceRangeType[],
  isToRemove = false
): PriceRangeType[] => {
  return isToRemove
    ? priceRanges
        .filter((range) => !range.isNew)
        .map(
          (range: ExtendedPriceRangeType): PriceRangeType => ({
            rangeStart: range.rangeStart,
            rangeEnd: range.rangeEnd,
            uuid: range.uuid
          })
        )
    : priceRanges.map(
        (range: ExtendedPriceRangeType): PriceRangeType => ({
          rangeStart: range.rangeStart,
          rangeEnd: range.rangeEnd,
          price: range.price,
          uuid: range.isNew ? undefined : range.uuid
        })
      );
};

export const concatPriceRangesToSave = (
  priceRanges: ExtendedPriceRangeType[],
  priceRangesToRemove: ExtendedPriceRangeType[]
): PriceRangeType[] => {
  validatePriceRanges(priceRanges);
  return formatPriceRangesToSave(priceRanges).concat(
    formatPriceRangesToSave(priceRangesToRemove, true)
  );
};
