import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { IndustrySegmentType } from '../definitions.d';

const INDUSTRY_SEGMENTS_QUERY = loader('../graphql/industrySegments.graphql');

interface IndustrySegmentsQueryResponse {
  industrySegments?: IndustrySegmentType[];
  loading?: boolean;
  error?: Error;
}

const useIndustrySegmentsQuery = (): IndustrySegmentsQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(INDUSTRY_SEGMENTS_QUERY, {
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });
  return { industrySegments: get(data, 'industrySegments'), loading, error };
};

export default useIndustrySegmentsQuery;
