import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { AdvanceType, BankAccountTypes } from '../definitions.d';

const MY_ADVANCES_QUERY = loader('../graphql/myAdvances.graphql');

export interface AdvanceResponse extends AdvanceType {
  bankAccountType: BankAccountTypes;
  bankAccountNumber: string;
  bank: {
    uuid: string;
    name: string;
  };
}

interface Props {
  startDate: string;
  endDate: string;
}

interface MyAdvancesQueryResponse {
  advances?: AdvanceResponse[];
  loading?: boolean;
  error?: Error;
  getAdvances: (values: Props) => void;
}

const useMyAdvancesQuery = (): MyAdvancesQueryResponse => {
  const { showNotification } = useNotificationContext();
  const [query, { data, loading, error }] = useLazyQuery(MY_ADVANCES_QUERY, {
    onError: (e) => {
      showNotification(e.message, 'error');
    },
    fetchPolicy: 'network-only'
  });
  return {
    getAdvances: (values: Props): void => {
      query({ variables: values });
    },
    advances: get(data, 'myAdvances'),
    loading,
    error
  };
};

export default useMyAdvancesQuery;
