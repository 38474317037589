import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { CompanyBankType } from '../definitions.d';

const COMPANY_BANKS_QUERY = loader('../graphql/companyBanks.graphql');

interface CompanyBanksQueryResponse {
  companyBanks?: CompanyBankType[];
  hasApiConnection?: boolean;
  loading?: boolean;
  error?: Error;
}

const useCompanyBanksQuery = (uuid: string): CompanyBanksQueryResponse => {
  const { showNotification } = useNotificationContext();
  const { data, loading, error } = useQuery(COMPANY_BANKS_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
    onError: (e) => {
      showNotification(e.message, 'error');
    }
  });
  return {
    companyBanks: get(data, 'company.companyBanks'),
    hasApiConnection: get(data, 'company.hasApiConnection'),
    loading,
    error
  };
};

export default useCompanyBanksQuery;
