import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import {
  ErolUserSexTypes,
  SelectItemType,
  ErolUserMaritalStatusTypes,
  ErolUserCompanyDepartmentTypes
} from '../../definitions.d';
import SelectInputWithTranslation from '../forms/select-input-with-translation';
import AutocompleteInput from '../forms/autocomplete-input';
import LocationAutocompleteInput from '../forms/location-autocomplete-input';
import FormSectionTitle from '../forms/form-section-title';
import DateInput from '../forms/date-input';
import {
  REQUIRED_FIELD_TEXT,
  SEX_TYPE_ITEMS,
  MARITAL_STATUS_ITEMS,
  COMPANY_DEPARTMENT_ITEMS
} from '../../config/constants';

export type FormValues = {
  birthDate?: string;
  buildingNumber?: string;
  city?: SelectItemType | null;
  companyDepartment?: ErolUserCompanyDepartmentTypes;
  country?: SelectItemType | null;
  firstName: string;
  lastName: string;
  mainStreet?: string;
  maritalStatus?: ErolUserMaritalStatusTypes;
  neighborhood?: SelectItemType | null;
  secondaryStreet?: string;
  sexType?: ErolUserSexTypes;
  state?: SelectItemType | null;
  zipCode?: string;
};

const VALIDATION_SCHEMA = yup.object({
  birthDate: yup.string(),
  buildingNumber: yup.string(),
  city: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required()
    })
    .nullable(),
  companyDepartment: yup
    .string()
    .oneOf(Object.keys(ErolUserCompanyDepartmentTypes)),
  country: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required()
    })
    .nullable(),
  firstName: yup.string().required(REQUIRED_FIELD_TEXT),
  lastName: yup.string().required(REQUIRED_FIELD_TEXT),
  mainStreet: yup.string(),
  maritalStatus: yup.string().oneOf(Object.keys(ErolUserMaritalStatusTypes)),
  neighborhood: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required()
    })
    .nullable(),
  secondaryStreet: yup.string().nullable(),
  sexType: yup.string().oneOf(Object.keys(ErolUserSexTypes)),
  state: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required()
    })
    .nullable(),
  zipCode: yup.string().nullable()
});

interface Props {
  initialValues: FormValues;
  isLoading?: boolean;
  onSubmit: (T: FormValues) => void;
  locations: SelectItemType[];
}

const UserProfileForm: React.FunctionComponent<Props> = ({
  initialValues,
  isLoading,
  onSubmit,
  locations
}: Props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(props): React.ReactNode => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  label="First Name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  label="Last Name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="sexType"
                  label="Gender"
                  component={SelectInputWithTranslation}
                  items={SEX_TYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="maritalStatus"
                  label="Marital status"
                  component={SelectInputWithTranslation}
                  items={MARITAL_STATUS_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="birthDate"
                  label="Birth date"
                  component={DateInput}
                />
              </Grid>
              <FormSectionTitle text="Workplace location" />
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  label="Country"
                  component={AutocompleteInput}
                  items={locations}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="state"
                  label="State"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  label="City"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="neighborhood"
                  label="Neighborhood"
                  component={LocationAutocompleteInput}
                  parentLocation={props.values.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="mainStreet"
                  label="Main street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="secondaryStreet"
                  label="Secondary street"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="buildingNumber"
                  label="Building number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="zipCode" label="Zip code" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyDepartment"
                  label="Company department"
                  component={SelectInputWithTranslation}
                  items={COMPANY_DEPARTMENT_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={6} sm={3}>
                <SubmitButton isLoading={isLoading} buttonText="Save" />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default UserProfileForm;
