import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { usePriceRangesContext } from './price-ranges-context';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(1)
  },
  formControl: {
    width: '100%'
  },
  checkbox: {
    marginTop: theme.spacing(2)
  }
}));

const PriceRangesGroupInformation: React.FunctionComponent = () => {
  const { priceRangesGroup, addPriceRangesGroup } = usePriceRangesContext();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.formContainer}>
      <Grid item xs={4}>
        <FormControl className={classes.formControl}>
          <InputLabel id="start-day-label">{t('Start day')}</InputLabel>
          <Select
            labelId="start-day-label"
            id="start-day-select"
            value={priceRangesGroup.startDay}
            onChange={(event): void => {
              const startDay = get(event, 'target.value') as number;
              addPriceRangesGroup({
                ...priceRangesGroup,
                startDay
              });
            }}
          >
            {Array.from(new Array(31), (val, index) => index + 1).map(
              (item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl className={classes.formControl}>
          <InputLabel id="end-day-label">{t('End day')}</InputLabel>
          <Select
            labelId="end-day-label"
            id="end-day-select"
            value={priceRangesGroup.endDay}
            onChange={(event): void => {
              const endDay = get(event, 'target.value') as number;
              addPriceRangesGroup({
                ...priceRangesGroup,
                endDay
              });
            }}
          >
            {Array.from(new Array(31), (val, index) => index + 1).map(
              (item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={priceRangesGroup.chargedNextMonth}
                onChange={(event): void => {
                  const chargedNextMonth = get(
                    event,
                    'target.checked'
                  ) as boolean;
                  addPriceRangesGroup({
                    ...priceRangesGroup,
                    chargedNextMonth
                  });
                }}
                value="chargedNextMonth"
              />
            }
            label={t('Charged next month')}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PriceRangesGroupInformation;
