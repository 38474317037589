import React, { useState } from 'react';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PaginatedTable, {
  ColumnProps,
  RowProps
} from '../tables/paginated-table';
import { UserEdge } from '../../definitions.d';
import useManagersPagedQuery from '../../hooks/useManagersPagedQuery';
import CreateButton from '../forms/create-button';
import PageTitle from '../navigation/page-title';
import useDeleteUserMutation from '../../hooks/useDeleteUserMutation';
import { useUserInfoContext } from '../../context/user-info-context';
import { useNotificationContext } from '../../context/notification-context';
import LoadingNotification from '../notifications/loading-notification';
import ManagersListActions from './managers-list-actions';

type ColumnTypes = 'username' | 'fullName' | 'email' | 'taxId' | 'confirmed';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'username', label: 'Username' },
  { id: 'fullName', label: 'Full Name' },
  { id: 'email', label: 'Email' },
  { id: 'taxId', label: 'ID Card' },
  { id: 'confirmed', label: 'Active', align: 'center' }
];

export const filterResults = (
  managers: UserEdge[],
  columns: ColumnProps[]
): RowProps[] => {
  return managers.map((manager) => {
    const { node: user } = manager;
    return columns.reduce(
      (filteredColumns, column) => {
        const id = get(column, 'id') as ColumnTypes;
        switch (id) {
          case 'fullName':
            filteredColumns[id] = `${user.firstName} ${user.lastName}`;
            break;
          case 'confirmed':
            filteredColumns[id] = user.confirmed ? (
              <CheckIcon color="primary" />
            ) : (
              <NotInterestedIcon color="action" />
            );
            break;
          default:
            filteredColumns[id] = user[id];
            break;
        }

        return filteredColumns;
      },
      { uuid: user.uuid } as RowProps
    );
  });
};

const EmployeesList: React.FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const { userInfo } = useUserInfoContext();

  const { managersPaged, loading, fetchMore } =
    useManagersPagedQuery(searchTerm);
  const totalCount = get(managersPaged, 'totalCount');
  const pageInfo = get(managersPaged, 'pageInfo');
  const managers = get(managersPaged, 'edges');

  const deleteUserMutation = useDeleteUserMutation();

  const handleDeleteUser = (uuid: string): void => {
    const userUuid = get(userInfo, 'uuid');
    if (uuid === userUuid) {
      showNotification(t('User could not be deleted'), 'warning');
    } else {
      deleteUserMutation(uuid, 'managers');
    }
  };

  const handleSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  if (!managersPaged) {
    return (
      <>
        <LoadingNotification />
        <ManagersListActions navigate={navigate} handleSearch={handleSearch} />
      </>
    );
  }

  return (
    <>
      <PageTitle title="Managers" />
      <ManagersListActions navigate={navigate} handleSearch={handleSearch} />
      <PaginatedTable
        columns={TABLE_COLUMNS}
        isLoading={loading}
        rows={filterResults(managers, TABLE_COLUMNS)}
        count={totalCount}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        handleEdit={(uuid: string): void => {
          navigate(`/managers/${uuid}/edit`);
        }}
        handleDelete={handleDeleteUser}
      />
    </>
  );
};

export default EmployeesList;
