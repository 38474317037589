import React from 'react';
import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';

import TextInput from '../forms/text-input';
import SubmitButton from '../forms/submit-button';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';

const VALIDATION_SCHEMA = yup.object().shape({
  reason: yup.string().required(REQUIRED_FIELD_TEXT)
});

const INITIAL_VALUES = {
  reason: ''
};

export interface FormProps {
  reason: string;
}

interface Props {
  handleSubmit: (values: FormProps) => void;
  loading?: boolean;
}

const ReasonCustomForm: React.FunctionComponent<Props> = ({
  handleSubmit,
  loading
}: Props) => {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        handleSubmit(values);
      }}
    >
      {() => (
        <Form>
          <Field
            name="reason"
            label="Reason"
            variant="outlined"
            component={TextInput}
            size="small"
          />
          <SubmitButton
            isLoading={loading}
            buttonText="Continue"
            size="small"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReasonCustomForm;
