import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNavigationDrawerContext } from '../../context/navigation-drawer-context';
import { MenuItem } from './menu-items-list';

const useStyles = makeStyles({
  itemText: {
    fontSize: '12px'
  },
  icon: {
    minWidth: '40px'
  }
});

type Props = Omit<MenuItem, 'items'>;

const SimpleMenuItem: React.FunctionComponent<Props> = ({
  label,
  icon,
  url
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { toggleDrawer } = useNavigationDrawerContext();

  return (
    <ListItem
      button
      onClick={(): void => {
        navigate(url as string);
        toggleDrawer();
      }}
    >
      <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography className={classes.itemText}>{t(label)}</Typography>
        }
      />
    </ListItem>
  );
};

export default SimpleMenuItem;
