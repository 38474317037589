import React from 'react';
import { get } from 'lodash';
import { Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { useUserInfoContext } from '../../context/user-info-context';
import { formatDate } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: 'bold'
  },
  paragraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const TermsOfService: React.FunctionComponent = () => {
  const classes = useStyles();

  const { userInfo } = useUserInfoContext();

  const firstName = get(userInfo, 'firstName');
  const lastName = get(userInfo, 'lastName');
  const taxId = get(userInfo, 'taxId');
  const companyLegalName = get(userInfo, 'company.legalName');
  const companyCommercialName = get(userInfo, 'company.commercialName');
  const companyMainStreet = get(userInfo, 'company.mainStreet');
  const companySecondaryStreet = get(userInfo, 'company.secondaryStreet');
  const companyBuildingNumber = get(userInfo, 'company.buildingNumber');
  const companyCity = get(userInfo, 'company.city.name');

  const fullName = `${firstName} ${lastName}`;
  const date = formatDate(new Date(Date.now()).toISOString());

  return (
    <>
      <Typography
        align="center"
        component="h3"
        className={clsx(classes.boldText, classes.paragraph)}
      >
        ADHESIÓN A CONTRATO MARCO DE FACTORING DE DERECHOS DE COBRO DE NÓMINA Y
        AUTORIZACIÓN DE DESCUENTO
      </Typography>
      <Typography align="right">Quito, {date}</Typography>
      <br />
      <Typography>Señores</Typography>
      <Typography>{companyCommercialName}</Typography>
      <Typography>
        {`${companyMainStreet}${
          companyBuildingNumber ? ` ${companyBuildingNumber}` : ''
        }${companySecondaryStreet ? ` y ${companySecondaryStreet}` : ''}`}
      </Typography>
      <Typography>{companyCity}</Typography>
      <br />
      <Typography>Señores</Typography>
      <Typography>E-Rol S.A.S</Typography>
      <Typography>Interoceánica L-79 y Miravalle 2</Typography>
      <Typography>Quito</Typography>
      <br />
      <Typography>Estimados señores:</Typography>
      <div className={classes.paragraph}>
        <Typography>
          Yo, <span className={classes.boldText}>{fullName}</span> con Cédula de
          Identidad No. <span className={classes.boldText}>{taxId}</span>, en mi
          condición trabajador de la empresa{' '}
          <span className={classes.boldText}>{companyCommercialName}</span>, por
          medio del este documento, libre, voluntaria, expresa, incondicional e
          irrevocablemente declaro:
        </Typography>
      </div>
      <div className={classes.paragraph}>
        <Typography align="justify">
          1. Que me adhiero al{' '}
          <span className={classes.boldText}>
            Contrato Marco de Factoring de Derechos de Cobro De Nómina
          </span>{' '}
          suscrito el {date} entre {companyLegalName} y E-Rol S.A.S. Al hacerlo,
          declaro asimismo que he leído y conozco plenamente el contenido total
          de tal Contrato, el cual lo acepto incondicionalmente y me comprometo
          a cumplirlo y respectarlo en mi calidad de{' '}
          <span className={classes.boldText}>colaborador</span> según la
          referencia que el mismo hace de mi persona.
        </Typography>
      </div>
      <div className={classes.paragraph}>
        <Typography align="justify">
          2. Que, en mi condición trabajador de{' '}
          <span className={classes.boldText}>{companyLegalName}</span>, autorizo
          a mi empleador,{' '}
          <span className={classes.boldText}>{companyLegalName}</span>, a
          deducir de mis haberes percibidos en relación de dependencia de{' '}
          <span className={classes.boldText}>{companyLegalName}</span>, esto es,
          remuneración, bonificaciones, participación en utilidades, liquidación
          por terminación de servicios y demás, los valores de los{' '}
          <span className={classes.boldText}>Derechos de Cobro de Nómina</span>{' '}
          que yo haya sido cedido a E-Rol S.A.S., incluyendo el correspondiente
          costo de la operación (descuento) cargado a mí por E-Rol S.A.S., y
          entregarlos a éste según lo previsto en el{' '}
          <span className={classes.boldText}>
            Contrato Marco de Factoring de Derechos de Cobro de Nómina
          </span>
          .
        </Typography>
      </div>

      <div className={classes.paragraph}>
        <Typography>Atentamente,</Typography>
        <br />
        <br />
        <Typography>{fullName}</Typography>
        <br />
        <Typography align="center">
          <Link href="www.e-rol.com.ec" target="_blank">
            www.e-rol.com.ec
          </Link>
        </Typography>
      </div>
    </>
  );
};

export default TermsOfService;
