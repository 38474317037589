import { useEffect, useCallback } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getToken } from '../utils/auth-client';
import { UserType, ErolUserRoles } from '../definitions.d';
import { GAP_TIME } from '../config/constants';
import { useNotificationContext } from '../context/notification-context';
import useRefreshTokenMutation from './useRefreshTokenMutation';
import useInterval from './useInterval';

export const hasToRefreshToken = (
  sessionTimeout = 1,
  tokenTimeout = 1
): boolean => {
  const now = Date.now();
  const expiryTime = tokenTimeout * 1000;
  return sessionTimeout * 1000 - GAP_TIME < expiryTime - now;
};

interface Props {
  userInfo: UserType | null;
  isActive: boolean;
}

const useRefreshSessionToken = ({ userInfo, isActive }: Props): void => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();
  const { t } = useTranslation();

  const sessionTimeout = get(userInfo, 'sessionTimeout', 0);
  const userRole = get(userInfo, 'userRole');

  const intervalTime =
    userRole && userRole !== ErolUserRoles.PLATFORM_MANAGER
      ? sessionTimeout * 1000 - GAP_TIME * 2
      : 0;

  const { refreshToken } = useRefreshTokenMutation();

  const handleRefreshToken = useCallback(() => {
    if (isActive) {
      refreshToken();
    } else {
      showNotification(
        t('Your session has expired due to inactivity'),
        'warning'
      );
      navigate('/logout');
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(() => {
    handleRefreshToken();
  }, intervalTime);

  useEffect(() => {
    if (intervalTime) {
      const token = getToken();
      if (token) {
        const { exp: tokenTimeout } = jwtDecode<JwtPayload>(token);
        if (hasToRefreshToken(sessionTimeout, tokenTimeout)) {
          handleRefreshToken();
        }
      }
    }
  }, [handleRefreshToken, intervalTime, sessionTimeout, userRole]);
};

export default useRefreshSessionToken;
