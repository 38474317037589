import React, { createContext, useState, useCallback, useContext } from 'react';

import { cleanErrorMessage } from '../utils/formatters';
import { VariantType } from '../components/notifications/notification';

export interface NotificationContextProps {
  notificationText?: string;
  notificationType?: VariantType;
  showNotification: (text: string, type: VariantType) => void;
  clearNotification: () => void;
}

export interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps
);

export const NotificationProvider: React.FunctionComponent<
  NotificationProviderProps
> = ({ children }: NotificationProviderProps) => {
  const [notificationText, setNotificationText] = useState<string>();
  const [notificationType, setNotificationType] = useState<VariantType>();

  const showNotification = useCallback((text: string, type: VariantType) => {
    setNotificationText(cleanErrorMessage(text));
    setNotificationType(type);
  }, []);

  const clearNotification = useCallback(() => {
    setNotificationText('');
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notificationText,
        notificationType,
        showNotification,
        clearNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): NotificationContextProps =>
  useContext(NotificationContext);
