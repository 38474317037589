import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox
} from '@mui/material';
import { get } from 'lodash';

import { AdvanceType } from '../../definitions.d';
import { formatDate, convertToDollars } from '../../utils/formatters';
import TableHead from './table-head';
import TableToolbar from './table-toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

interface Props {
  advances: AdvanceType[];
  onCreateFile: (advances: string[]) => void;
}

const CustomTable: React.FunctionComponent<Props> = ({
  advances,
  onCreateFile
}: Props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      const newSelecteds = advances.map((advance) => advance.uuid);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    uuid: string
  ): void => {
    const selectedIndex = selected.indexOf(uuid);
    let newSelected = [] as string[];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (uuid: string): boolean => selected.indexOf(uuid) !== -1;

  const handleCreateFile = (): void => {
    onCreateFile(selected);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={selected.length}
          onCreateFile={handleCreateFile}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={advances.length}
            />
            <TableBody>
              {advances.map((advance) => {
                const uuid = get(advance, 'uuid');
                const isItemSelected = isSelected(uuid);
                const labelId = `enhanced-table-checkbox-${uuid}`;

                const firstName = get(advance, 'erolUser.firstName');
                const lastName = get(advance, 'erolUser.lastName');

                return (
                  <TableRow
                    hover
                    onClick={(event): void => {
                      handleClick(event, uuid);
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={advance.uuid}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {`${firstName} ${lastName}`}
                    </TableCell>
                    <TableCell align="right">
                      {formatDate(get(advance, 'requestDate'))}
                    </TableCell>
                    <TableCell align="right">
                      {`$${convertToDollars(get(advance, 'advanceAmount'))}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CustomTable;
