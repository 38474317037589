import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, List, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNavigationDrawerContext } from '../../context/navigation-drawer-context';
import SidebarMenu from './sidebar-menu';

export const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px',
    ...theme.mixins.toolbar
  }
}));

const NavigationDrawer: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openDrawer, toggleDrawer } = useNavigationDrawerContext();

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
      open={openDrawer}
      onClose={toggleDrawer}
    >
      <div className={classes.toolbarIcon}>
        <Typography>{t('Main menu')}</Typography>
      </div>
      <Divider />
      <List>
        <SidebarMenu />
      </List>
    </Drawer>
  );
};

export default NavigationDrawer;
