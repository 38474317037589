import React, { ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { SelectProps } from 'formik-mui';
import { get } from 'lodash';

import { SelectItemType } from '../../definitions.d';
import useStyles from './styles';

interface SelectInputProps extends SelectProps {
  label: string;
  items: SelectItemType[];
}

const AutocompleteInput: React.FunctionComponent<SelectInputProps> = (
  props: SelectInputProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const touched = get(props, `form.touched.${props.field.name}`, false);
  const error = !!get(props, `form.errors.${props.field.name}`);

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id={props.id ? props.id : `autocomplete-${props.field.name}-input`}
        onChange={(
          event: ChangeEvent<unknown>,
          value: SelectItemType | null
        ): void => {
          props.form.setFieldValue(props.field.name, value);
        }}
        options={props.items}
        getOptionLabel={(option: SelectItemType): string => option.label}
        isOptionEqualToValue={(
          option: SelectItemType,
          value: SelectItemType
        ): boolean => option.value === value.value}
        value={get(props, 'field.value')}
        disabled={props.disabled}
        renderInput={(params: any): React.ReactNode => (
          <TextField
            {...params}
            label={props.label ? t(props.label) : props.label}
            fullWidth
            variant="outlined"
            size="small"
            error={(error && touched) as boolean}
          />
        )}
      />
    </FormControl>
  );
};

export default AutocompleteInput;
