import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { useNotificationContext } from '../context/notification-context';
import { UserType } from '../definitions.d';

const BULK_CREATE_USER_MUTATION = loader('../graphql/bulkCreateUser.graphql');

export interface BulkCreateUserProps {
  companyUuid: string;
  file: File | null;
}

export interface BulkOperationsUserRejectProps {
  rowNumber: number;
  rowData: string[];
  rowReason: string;
}

interface BulkCreateUserResponse {
  createUsers: (values: BulkCreateUserProps) => void;
  users?: UserType[];
  loading?: boolean;
  error?: Error;
  ignored?: BulkOperationsUserRejectProps[];
  failed?: BulkOperationsUserRejectProps[];
}

const useBulkCreateUserMutation = (): BulkCreateUserResponse => {
  const { showNotification } = useNotificationContext();

  const [mutation, { data, loading, error }] = useMutation(
    BULK_CREATE_USER_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      }
    }
  );

  return {
    createUsers: (values: BulkCreateUserProps): void => {
      mutation({ variables: values });
    },
    users: get(data, 'bulkCreateUser.statistics.created'),
    ignored: get(data, 'bulkCreateUser.statistics.ignored'),
    failed: get(data, 'bulkCreateUser.statistics.failed'),
    error,
    loading
  };
};

export default useBulkCreateUserMutation;
