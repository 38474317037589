import React, { useEffect, useState } from 'react';

import { useUserInfoContext } from '../../context/user-info-context';
import { ErolUserRoles } from '../../definitions.d';
import LoadingNotification from '../notifications/loading-notification';
import EmployeeDashboard from './employee-dashboard';
import AdminDashboard from './admin-dashboard';

import { get } from 'lodash';
import useAcceptTermsAndConditions from '../../hooks/useAcceptTermsAndConditions';
import TermsAndConditionsDialog from '../dialogs/terms-and-condition-dialog';
import TermsAndConditionsRejectDialog from '../dialogs/terms-and-conditions-rejected-dialog';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FunctionComponent = () => {
  const { userRole, termsAndConditions, userInfo } = useUserInfoContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const navigate = useNavigate();
  const { acceptTermsAndConditions, loading, error } =
    useAcceptTermsAndConditions();

  useEffect(() => {
    if (termsAndConditions) {
      const termsAndConditionsUpdatedAt = new Date(
        termsAndConditions.updatedAt
      );
      const userInfoAcceptedOn = get(userInfo, 'acceptance.acceptedOn', null);
      if (
        !userInfoAcceptedOn ||
        termsAndConditionsUpdatedAt > userInfoAcceptedOn
      ) {
        setModalOpen(true);
      }
    }
  }, [termsAndConditions, userInfo]);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleAcceptTermsAndConditions = () => {
    const termsAndConditionsUuid = get(termsAndConditions, 'uuid', null);
    if (termsAndConditionsUuid) {
      acceptTermsAndConditions(termsAndConditionsUuid);
      if (!error) {
        setModalOpen(false);
      }
    }
  };
  const handleRejectTermsAndConditions = () => {
    setModalOpen(false);
    setRejectModalOpen(true);
  };

  const handleTermsAndConditionsRejectDialog = () => {
    setRejectModalOpen(false);
    logout();
  };

  const logout = () => {
    navigate('/logout');
  };

  if (get(userInfo, 'userRole') === ErolUserRoles.EMPLOYEE) {
    return (
      <>
        <EmployeeDashboard />
        <TermsAndConditionsDialog
          open={modalOpen}
          onClose={handleCloseModal}
          termsText={termsAndConditions?.text || ''}
          onAccept={handleAcceptTermsAndConditions}
          onReject={handleRejectTermsAndConditions}
        />
        <TermsAndConditionsRejectDialog
          onClose={handleTermsAndConditionsRejectDialog}
          open={rejectModalOpen}
        />
      </>
    );
  } else if (
    get(userInfo, 'userRole') === ErolUserRoles.PLATFORM_MANAGER ||
    get(userInfo, 'userRole') === ErolUserRoles.COMPANY_MANAGER
  ) {
    return (
      <>
        <AdminDashboard />
        <TermsAndConditionsDialog
          open={modalOpen}
          onClose={handleCloseModal}
          termsText={termsAndConditions?.text || ''}
          onAccept={handleAcceptTermsAndConditions}
          onReject={handleRejectTermsAndConditions}
          isLoading={loading}
        />
        <TermsAndConditionsRejectDialog
          onClose={handleTermsAndConditionsRejectDialog}
          open={rejectModalOpen}
        />
      </>
    );
  }

  return <LoadingNotification />;
};

export default Dashboard;
