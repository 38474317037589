import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase'
  }
}));

const PriceRangesHeader: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} className={classes.paper}>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Grid container spacing={1} className={classes.paper}>
          <Grid item xs={4}>
            <Typography component="p" className={classes.title}>
              {t('Minimum')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="p" className={classes.title}>
              {t('Maximum')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="p" className={classes.title}>
              {t('Price')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography component="p" className={classes.title}>
          {t('Action')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PriceRangesHeader;
