import { LOCALSTORAGE_AUTH_KEY } from '../config/constants';

export const saveToken = (token: string): void => {
  if (!token) throw new Error('Invalid token');
  window.localStorage.setItem(LOCALSTORAGE_AUTH_KEY, token);
};

export const getToken = (): string | null =>
  window.localStorage.getItem(LOCALSTORAGE_AUTH_KEY);

export const removeToken = (): void =>
  window.localStorage.removeItem(LOCALSTORAGE_AUTH_KEY);
