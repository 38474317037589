import React from 'react';

import { useUserInfoContext } from '../../context/user-info-context';
import { ErolUserRoles } from '../../definitions.d';
import PlatformManagerSidebarMenu from './platform-manager-sidebar-menu';
import CompanyManagerSidebarMenu from './company-manager-sidebar-menu';
import EmployeeSidebarMenu from './employee-sidebar-menu';

const SidebarMenu: React.FunctionComponent = () => {
  const { userRole } = useUserInfoContext();

  switch (userRole) {
    case ErolUserRoles.PLATFORM_MANAGER:
      return <PlatformManagerSidebarMenu />;

    case ErolUserRoles.COMPANY_MANAGER:
      return <CompanyManagerSidebarMenu />;

    default:
      return <EmployeeSidebarMenu />;
  }
};

export default SidebarMenu;
