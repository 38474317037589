import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  noResults: {
    width: '100%',
    textAlign: 'center',
    padding: '20px'
  }
});

const LoadingNotification: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.noResults}>
      <CircularProgress />
      <Box component="h4">{t('Loading')}</Box>
    </Box>
  );
};

export default LoadingNotification;
