import React, { createContext, useContext, useReducer } from 'react';

import { reducer, INITIAL_STATE, State, ActionTypes } from './reducer';

export interface RequestContextProps {
  state: State;
  dispatch: React.Dispatch<ActionTypes>;
}

export interface RequestProviderProps {
  children: React.ReactNode;
}

export const RequestContext = createContext<RequestContextProps>(
  {} as RequestContextProps
);

export const RequestContextProvider: React.FunctionComponent<
  RequestProviderProps
> = ({ children }: RequestProviderProps) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <RequestContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export const useRequestContext = (): RequestContextProps =>
  useContext(RequestContext);
