import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useNotificationContext } from '../context/notification-context';

const DELETE_PRICE_RANGES_GROUP_MUTATION = loader(
  '../graphql/deletePriceRangesGroup.graphql'
);
const PRICE_RANGES_GROUP_QUERY = loader('../graphql/priceRangesGroups.graphql');

const useDeletePriceRangesGroupMutation = (
  companyUuid: string
): ((uuid?: string) => void) => {
  const { t } = useTranslation();
  const { showNotification } = useNotificationContext();

  const [deletePriceRangesGroup] = useMutation(
    DELETE_PRICE_RANGES_GROUP_MUTATION,
    {
      onError: (e) => {
        showNotification(e.message, 'error');
      },
      onCompleted: (): void => {
        showNotification(
          t('Price ranges group deleted successfully'),
          'success'
        );
      }
    }
  );

  return (uuid?: string): void => {
    deletePriceRangesGroup({
      variables: { uuid },
      refetchQueries: [
        {
          query: PRICE_RANGES_GROUP_QUERY,
          variables: { uuid: companyUuid }
        }
      ],
      awaitRefetchQueries: true
    });
  };
};

export default useDeletePriceRangesGroupMutation;
