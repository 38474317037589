import React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h2" component="h1" gutterBottom>
        {t('Page not found')}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {t(
          "We're sorry, the page you requested could not be found. Please go back to the dashboad."
        )}
      </Typography>
      <Button
        variant="contained"
        onClick={(): void => {
          navigate('/dashboard');
        }}
      >
        {t('Visit dashboard')}
      </Button>
    </>
  );
};

export default NotFound;
