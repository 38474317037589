import React from 'react';
import { get } from 'lodash';

import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import LoadingNotification from '../notifications/loading-notification';
import useCreateManagerMutation, {
  CreateManagerProps
} from '../../hooks/useCreateManagerMutation';
import PageTitle from '../navigation/page-title';
import { formatCompanies } from '../../utils/formatters';
import ManagerCreateForm, { FormValues } from './manager-create-form';

const formatFormValues = (values: FormValues): CreateManagerProps => {
  const apiOnly = get(values, 'apiOnly');
  const notifyAdvances = get(values, 'notifyAdvances');

  return {
    apiOnly,
    email: get(values, 'email'),
    companyUuids: (get(values, 'companyUuids') || []).map(
      (company) => company.value as string
    ),
    firstName: get(values, 'firstName'),
    idType: get(values, 'idType'),
    lastName: get(values, 'lastName'),
    notifyAdvances: apiOnly ? false : notifyAdvances,
    phone: get(values, 'phone'),
    sexType: get(values, 'sexType'),
    taxId: get(values, 'taxId'),
    userRole: get(values, 'userRole'),
    username: get(values, 'username')
  };
};

const ManagerCreate: React.FunctionComponent = () => {
  const { companies } = useCompaniesQuery();
  const { mutation: createManagerMutation, loading } =
    useCreateManagerMutation();

  if (!companies) {
    return <LoadingNotification />;
  }

  return (
    <>
      <PageTitle title="Create manager" />
      <ManagerCreateForm
        isLoading={loading}
        companies={formatCompanies(companies)}
        onSubmit={(values: FormValues): void => {
          createManagerMutation(formatFormValues(values));
        }}
      />
    </>
  );
};

export default ManagerCreate;
