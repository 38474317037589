import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    paddingTop: '15px'
  },
  text: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px'
  }
}));

interface Props {
  icon: React.ReactNode;
  text: string;
}

const ReasonItem: React.FunctionComponent<Props> = ({ icon, text }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {icon}
      <Typography className={classes.text}>{t(text)}</Typography>
    </div>
  );
};

export default ReasonItem;
