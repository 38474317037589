import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlayCircleFilled } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import useQueryParameters from '../../hooks/useQueryParameters';
import { RequestStep, RequestType } from '../../context/request/reducer';
import Amount from './amount';
import Reason from './reason';
import Bank from './bank';
import RequestDetails from './request-details';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1
  },
  iconContainer: {
    display: 'inline-block',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.primary.main,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '36px'
    }
  }
}));

const isValidStep = (step: string | null) =>
  !!step && Object.keys(RequestStep).indexOf(step) !== -1;

const isValidType = (type: string | null) =>
  !!type && Object.keys(RequestType).indexOf(type) !== -1;

const manageEntry = (
  step: string | null,
  type: string | null
): string[] | null => {
  const newStep =
    !step || !isValidStep(step) ? RequestStep.AMOUNT : (step as string);
  const newType =
    !type || !isValidType(type) ? RequestType.DEFAULT : (type as string);

  if (step !== newStep || type !== newType) {
    return [newStep, newType];
  }

  return null;
};

const RequestAdvance: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParameters = useQueryParameters();

  const step = queryParameters.get('step');
  const type = queryParameters.get('type');

  const entries = manageEntry(step, type);

  useEffect(() => {
    if (entries) {
      const [newStep, newType] = entries;
      const to = location;
      to.search = `?step=${newStep}&type=${newType}`;
      navigate(to, { replace: true });
    }
  }, [entries, navigate, location]);

  useEffect(() => {
    document.title = `${t('Request advance')} | E-ROL`;
  }, [t]);

  const onUpdateRouterHistory = (newStep: string, newType: string): void => {
    const to = location;
    to.search = `?step=${newStep}&type=${newType}`;
    navigate(to, { replace: false });
  };

  const showStep = () => {
    switch (step) {
      case RequestStep.REASON:
        return (
          <Reason type={type} onUpdateRouterHistory={onUpdateRouterHistory} />
        );

      case RequestStep.BANK:
        return <Bank onUpdateRouterHistory={onUpdateRouterHistory} />;

      case RequestStep.SUMMARY:
        return <RequestDetails onUpdateRouterHistory={onUpdateRouterHistory} />;

      default:
        return (
          <Amount type={type} onUpdateRouterHistory={onUpdateRouterHistory} />
        );
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Grid item xs={10} sm={9} md={8}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.container}
        >
          <Grid item xs={12} sm={8} md={12}>
            <div
              onClick={(): void => {
                history.back();
              }}
              className={classes.iconContainer}
            >
              <PlayCircleFilled className={classes.icon} />
            </div>
          </Grid>
          {showStep()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestAdvance;
