import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import useAuth from '../../hooks/useAuth';
import { useUserInfoContext } from '../../context/user-info-context';
import logoNormal from '../../assets/images/logo.svg';
import logoBlack from '../../assets/images/logoBlack.svg';
import bellRingingIcon from '../../assets/images/belRingingIcon.svg';
import { useNavigationDrawerContext } from '../../context/navigation-drawer-context';
import { UserType } from '../../definitions';
import userIcon from '../../assets/images/userIcon.png';
const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.07);',
    flexDirection: 'row',
    height: '64px',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  appBarWithoutInfo: {
    display: 'flex',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.00);',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '56px'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('md')]: {
      width: '0'
    },
    [theme.breakpoints.up('md')]: {
      width: '0'
    },
    position: 'relative',
    color: 'black',

    '&::after': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: -1,
      transformOrigin: 'bottom left',
      transform: 'skew(-45deg, 0deg)'
    }
  },
  logo: {
    alignSelf: 'center',
    width: '81px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4)
    }
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    maxHeight: '24px'
  },
  userInfo: {
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  userIcon: {
    width: '16px',
    height: '16px'
  },
  ringBellIcon: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(1)
  }
}));

function getLogo(isAuthenticated: boolean, userInfo: UserType | null) {
  if (isAuthenticated && userInfo) {
    return logoBlack;
  }
  return logoNormal;
}

const ApplicationBar: React.FunctionComponent = () => {
  const classes = useStyles();

  const { isAuthenticated } = useAuth();
  const { userInfo } = useUserInfoContext();
  const logo = getLogo(isAuthenticated, userInfo);
  const { toggleDrawer } = useNavigationDrawerContext();
  const appWithInfoClassName =
    isAuthenticated && userInfo ? classes.appBar : classes.appBarWithoutInfo;
  return (
    <AppBar color="inherit" classes={{ root: appWithInfoClassName }}>
      {isAuthenticated && userInfo ? (
        <Toolbar
          classes={{
            root: classes.toolbar
          }}
        >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            data-testid="open-navigation-menu"
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      ) : null}
      {isAuthenticated && userInfo ? (
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="Logo" />
          {isAuthenticated && userInfo ? (
            <div className={classes.userInfoContainer}>
              <img
                src={userIcon}
                alt="user icon"
                className={classes.userIcon}
              />
              <span className={classes.userInfo}>
                {userInfo?.firstName} {userInfo?.lastName}
              </span>
              <img
                src={bellRingingIcon}
                alt="bell ringing icon"
                className={classes.ringBellIcon}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </AppBar>
  );
};

export default ApplicationBar;
