import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import useCompaniesQuery from '../../hooks/useCompaniesQuery';
import { formatDate, convertToDollars } from '../../utils/formatters';
import LoadingNotification from '../notifications/loading-notification';
import useAdvancesQuery from '../../hooks/useAdvancesQuery';
import SimpleTable, { ColumnProps, RowProps } from '../tables/simple-table';
import { AdvanceType, BankAccountTypes, PageInfo } from '../../definitions.d';
import { useNotificationContext } from '../../context/notification-context';
import PageTitle from '../navigation/page-title';
import useDownloadAdvancesReportMutation from '../../hooks/useDownloadAdvancesReportMutation';
import AdvancesFilterForm, { FormProps } from './advances-filter-form';
import AdvanceDetailsButton from './advance-details-button';
import AdvanceDetailsDialog from './advance-details-dialog';
import PaginatedTable from '../tables/paginated-table';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveAlt';

export const TABLE_COLUMNS: ColumnProps[] = [
  { id: 'identifier', label: 'Identifier' },
  { id: 'status', label: 'Status' },
  { id: 'requestDate', label: 'Request date' },
  { id: 'userName', label: 'User' },
  { id: 'taxId', label: 'Tax Id', align: 'right' },
  { id: 'originAccount', label: 'Origin account' },
  { id: 'destinationAccount', label: 'Destination account' },
  { id: 'requestedAmount', label: 'Requested amount', align: 'right' },
  { id: 'totalCost', label: 'Total cost', align: 'right' },
  {
    id: 'effectiveTransferAmount',
    label: 'Efective transfer amount',
    align: 'right'
  }
];

interface BankInformation {
  bankName: string;
  accountNumber: string;
  accountType: BankAccountTypes;
}

const formatBankInformation = ({
  bankName,
  accountNumber,
  accountType
}: BankInformation) => `${bankName}|${accountNumber}|${accountType}`;

const AdvancesList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<FormProps | null>(null);
  const [selectedAdvance, setSelectedAdvance] = useState<AdvanceType>();
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const { showNotification } = useNotificationContext();
  const { companies } = useCompaniesQuery();
  const { getAdvances, advances, loading, error } = useAdvancesQuery();

  useEffect(() => {
    if (error) {
      showNotification(error.message, 'error');
    }
  }, [error, showNotification]);

  const onSubmit = (values: FormProps): void => {
    setFormValues(values);

    const statuses = get(values, 'statuses') || [];
    const companyUuids = get(values, 'companies') || [];

    getAdvances({
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      statuses: statuses.length
        ? (statuses.map((status) => status.value) as string[])
        : null,
      companyUuids: companyUuids.length
        ? (companyUuids.map((uuid) => uuid.value) as string[])
        : null
    });
  };

  const handleShowDetails = (advance: AdvanceType) => {
    setSelectedAdvance(advance);
    setOpenDetails(true);
  };

  const formatAdvances = (items: AdvanceType[]): RowProps[] => {
    return items.map((advance) => {
      const status = get(advance, 'status', '') as string;
      const requestDate = get(advance, 'requestDate');

      const firstName = get(advance, 'erolUser.firstName');
      const lastName = get(advance, 'erolUser.lastName');
      const taxId = get(advance, 'erolUser.taxId');

      const originBankAccount = get(advance, 'originBankAccount');
      const originAccount = originBankAccount
        ? {
            bankName: get(advance, 'originBankAccount.bank.name'),
            accountNumber: get(advance, 'originBankAccount.accountNumber'),
            accountType: get(advance, 'originBankAccount.accountType')
          }
        : null;

      const destinationAccount = {
        bankName: get(advance, 'bank.name'),
        accountNumber: get(advance, 'bankAccountNumber'),
        accountType: get(advance, 'bankAccountType')
      };

      const requestedAmount = get(advance, 'advanceAmount');

      const totalCost =
        get(advance, 'platformFee', 0) +
        get(advance, 'serviceFee', 0) +
        get(advance, 'tax', 0) +
        get(advance, 'price', 0) +
        get(advance, 'transferFee', 0);

      const effectiveTransferAmount = get(advance, 'effectiveTransferAmount');

      return {
        uuid: get(advance, 'uuid'),
        identifier: (
          <AdvanceDetailsButton
            title={get(advance, 'identifier')}
            handleShowDetails={() => handleShowDetails(advance)}
          />
        ),
        status: t(status),
        requestDate: requestDate ? formatDate(requestDate) : '',
        userName: `${firstName} ${lastName}`,
        taxId,
        originAccount: originAccount
          ? formatBankInformation(originAccount)
          : null,
        destinationAccount: formatBankInformation(destinationAccount),
        requestedAmount: `$${convertToDollars(requestedAmount)}`,
        totalCost: `$${convertToDollars(totalCost)}`,
        effectiveTransferAmount: `$${convertToDollars(effectiveTransferAmount)}`
      };
    });
  };

  const { createReport, loading: loadingCreateInvoice } =
    useDownloadAdvancesReportMutation();

  const handleDownload = (): void => {
    if (formValues) {
      const statuses = get(formValues, 'statuses') || [];
      const companyUuids = get(formValues, 'companies') || [];

      createReport({
        startDate: formatDate(formValues.startDate),
        endDate: formatDate(formValues.endDate),
        statuses: statuses.length
          ? (statuses.map((status) => status.value) as string[])
          : null,
        companyUuids: companyUuids.length
          ? (companyUuids.map((uuid) => uuid.value) as string[])
          : null
      });
    }
  };

  if (!companies || loadingCreateInvoice) {
    return <LoadingNotification />;
  }

  function filterResults(items: any) {
    return items.map((advance: any) => {
      const status = get(advance, 'status', '') as string;
      const requestDate = get(advance, 'requestDate');

      const firstName = get(advance, 'erolUser.firstName');
      const lastName = get(advance, 'erolUser.lastName');
      const taxId = get(advance, 'erolUser.taxId');

      const originBankAccount = get(advance, 'originBankAccount');
      const originAccount = originBankAccount
        ? {
            bankName: get(advance, 'originBankAccount.bank.name'),
            accountNumber: get(advance, 'originBankAccount.accountNumber'),
            accountType: get(advance, 'originBankAccount.accountType')
          }
        : null;

      const destinationAccount = {
        bankName: get(advance, 'bank.name'),
        accountNumber: get(advance, 'bankAccountNumber'),
        accountType: get(advance, 'bankAccountType')
      };

      const requestedAmount = get(advance, 'advanceAmount');

      const totalCost =
        get(advance, 'platformFee', 0) +
        get(advance, 'serviceFee', 0) +
        get(advance, 'tax', 0) +
        get(advance, 'price', 0) +
        get(advance, 'transferFee', 0);

      const effectiveTransferAmount = get(advance, 'effectiveTransferAmount');

      return {
        uuid: get(advance, 'uuid'),
        identifier: (
          <AdvanceDetailsButton
            title={get(advance, 'identifier')}
            handleShowDetails={() => handleShowDetails(advance)}
          />
        ),
        status: t(status),
        requestDate: requestDate ? formatDate(requestDate) : '',
        userName: `${firstName} ${lastName}`,
        taxId,
        originAccount: originAccount
          ? formatBankInformation(originAccount)
          : null,
        destinationAccount: formatBankInformation(destinationAccount),
        requestedAmount: `$${convertToDollars(requestedAmount)}`,
        totalCost: `$${convertToDollars(totalCost)}`,
        effectiveTransferAmount: `$${convertToDollars(effectiveTransferAmount)}`
      };
    });
  }

  return (
    <>
      <PageTitle title="Advances" />
      <AdvancesFilterForm
        companies={companies}
        onSubmit={onSubmit}
        loading={loading}
        handleDownload={handleDownload}
        downloadDisabled={isEmpty(advances)}
      />
      {advances && (
        <SimpleTable
          columns={TABLE_COLUMNS}
          isLoading={loading}
          rows={formatAdvances(advances)}
        />
      )}
      <AdvanceDetailsDialog
        open={openDetails}
        advance={selectedAdvance}
        handleClose={() => setOpenDetails(false)}
      />
    </>
  );
};

export default AdvancesList;
