import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '8.489px',
    background: 'var(--Color, #FFF)',
    boxShadow: '0px 15px 35px 0px rgba(0, 0, 0, 0.15)',
    width: '120px',
    height: '53px',
    flexShrink: 0
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white !important'
  }
}));

interface Props {
  children: React.ReactNode;
  selected?: boolean;
  handleClick: () => void;
}

const SelectionContainer: React.FunctionComponent<Props> = ({
  children,
  selected,
  handleClick
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.container,
        selected ? classes.selected : undefined
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default SelectionContainer;
