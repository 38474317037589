import React from 'react';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';

import TextInput from '../forms/text-input';
import PasswordInput from '../forms/password-input';
import SubmitButton from '../forms/submit-button';
import { REQUIRED_FIELD_TEXT } from '../../config/constants';
import loginImage from '../../assets/images/logo.svg';
export interface FormValues {
  username: string;
  password: string;
  otp?: string;
}

const INITIAL_VALUES = { username: '', password: '', otp: '' };

const VALIDATION_SCHEMA = yup.object({
  username: yup.string().required(REQUIRED_FIELD_TEXT),
  password: yup.string().required(REQUIRED_FIELD_TEXT),
  otp: yup.string()
});

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    margin: '20px 0 10px',
    fontSize: '1.5rem'
  },
  imageWelcome: {
    width: '40%'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  }
}));

interface Props {
  isLoading: boolean;
  requireOtp?: boolean;
  onSubmit: (values: FormValues) => void;
}

const LoginForm: React.FunctionComponent<Props> = ({
  isLoading,
  requireOtp,
  onSubmit
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.imageContainer}>
        <img src={loginImage} alt="login" className={classes.imageWelcome} />
      </div>

      <Typography component="h5" className={classes.message}>
        {t(requireOtp ? 'Enter OTP' : 'Enter credentials')}
      </Typography>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values: FormValues, actions): void => {
          onSubmit(values);
        }}
      >
        {(props): React.ReactNode => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    name="username"
                    label="Username"
                    component={TextInput}
                    disabled={requireOtp}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    name="password"
                    label="Password"
                    component={PasswordInput}
                    disabled={requireOtp}
                  />
                </Grid>
                {requireOtp ? (
                  <Grid item xs={12}>
                    <Field
                      variant="outlined"
                      name="otp"
                      label="OTP"
                      component={TextInput}
                      disabled={!requireOtp}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <SubmitButton isLoading={isLoading} buttonText="Sign In" />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
