import React from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import useStyles from '../forms/styles';
import TextInput from '../forms/text-input';
import AutocompleteInput from '../forms/autocomplete-multiple-input';
import SelectInputWithTranslation from '../forms/select-input-with-translation';
import SubmitButton from '../forms/submit-button';
import SimpleButton from '../forms/simple-button';
import CheckboxInput from '../forms/checkbox-input';
import {
  REQUIRED_FIELD_TEXT,
  IDTYPE_ITEMS,
  USERROLE_ITEMS,
  SEX_TYPE_ITEMS,
  REGULAR_EXPRESSION_PHONE_NUMBER,
  PHONE_NUMBER_VALIDATION_MESSAGE
} from '../../config/constants';
import {
  SelectItemType,
  ErolUserIdTypes,
  ErolUserSexTypes,
  ErolUserRoles
} from '../../definitions.d';

const validationSchema = yup.object({
  username: yup.string().required(REQUIRED_FIELD_TEXT),
  apiOnly: yup.boolean(),
  email: yup.string().email().required(REQUIRED_FIELD_TEXT),
  firstName: yup.string().required(REQUIRED_FIELD_TEXT),
  idType: yup.string().oneOf(Object.keys(ErolUserIdTypes)),
  lastName: yup.string().required(REQUIRED_FIELD_TEXT),
  notifyAdvances: yup.boolean(),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .matches(REGULAR_EXPRESSION_PHONE_NUMBER, PHONE_NUMBER_VALIDATION_MESSAGE),
  taxId: yup.string().when('idType', {
    is: (val) => !!val,
    then: yup.string().required(REQUIRED_FIELD_TEXT)
  }),
  userRole: yup
    .string()
    .oneOf(Object.keys(ErolUserRoles))
    .required(REQUIRED_FIELD_TEXT),
  companyUuids: yup.mixed().when('userRole', {
    is: (val) => val === ErolUserRoles.COMPANY_MANAGER,
    then: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          label: yup.string()
        })
      )
      .required(REQUIRED_FIELD_TEXT)
  }),
  sexType: yup.string().oneOf(Object.keys(ErolUserSexTypes))
});

export interface FormValues {
  username: string;
  apiOnly?: boolean;
  email: string;
  firstName?: string;
  idType?: string;
  lastName?: string;
  notifyAdvances?: boolean;
  phone?: string;
  taxId?: string;
  userRole?: string;
  companyUuids?: SelectItemType[];
  sexType?: string;
}

interface Props {
  initialValues: FormValues;
  isLoading?: boolean;
  onSubmit: (values: FormValues) => void;
  companies: SelectItemType[];
}

const ManagerUpdateForm: React.FunctionComponent<Props> = ({
  initialValues,
  isLoading,
  onSubmit,
  companies
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions): void => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {(props): React.ReactNode => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="username"
                  label="Username"
                  component={TextInput}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="email" label="Email" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="idType"
                  label="Identification type"
                  component={SelectInputWithTranslation}
                  items={IDTYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="taxId"
                  label="ID Card Number"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  label="First name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  label="Last name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="userRole"
                  label="User role"
                  component={SelectInputWithTranslation}
                  items={USERROLE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="companyUuids"
                  label="Companies"
                  component={AutocompleteInput}
                  items={companies}
                  disabled={
                    props.values.userRole === ErolUserRoles.PLATFORM_MANAGER
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="phone" label="Phone" component={TextInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="sexType"
                  label="Gender"
                  component={SelectInputWithTranslation}
                  items={SEX_TYPE_ITEMS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="apiOnly"
                  label="API only"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="notifyAdvances"
                  label="Notify advances"
                  type="checkbox"
                  component={CheckboxInput}
                  disabled={props.values.apiOnly}
                />
              </Grid>
              <Grid item xs>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6} sm={3}>
                    <SubmitButton isLoading={isLoading} buttonText="Save" />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SimpleButton
                      title="Go Back"
                      inheritColor={true}
                      buttonId="return-button"
                      onClick={(): void => {
                        navigate('/managers');
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ManagerUpdateForm;
