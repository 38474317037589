import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import { convertToDollars } from '../../utils/formatters';
import { AdvanceAvailabilityType } from '../../definitions.d';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold'
  }
}));

interface Props {
  amount: number;
  advanceAvailability: AdvanceAvailabilityType;
}

const CostDetails: React.FunctionComponent<Props> = ({
  amount,
  advanceAvailability
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const serviceFee = get(advanceAvailability, 'serviceFee', 0);
  const platformFee = get(advanceAvailability, 'platformFee', 0);
  const transferFee = get(advanceAvailability, 'transferFee', 0);
  const tax = get(advanceAvailability, 'tax', 0);
  const price = get(advanceAvailability, 'price', 0);
  const effectiveTransferAmount = get(
    advanceAvailability,
    'effectiveTransferAmount',
    0
  );

  const subtotal = serviceFee + platformFee + transferFee;

  const rows = [
    {
      label: 'Requested amount',
      value: convertToDollars(amount)
    },
    {
      label: 'Service fee',
      value: convertToDollars(serviceFee)
    },
    {
      label: 'Platform fee',
      value: convertToDollars(platformFee)
    },
    {
      label: 'Transfer fee',
      value: convertToDollars(transferFee)
    },
    { label: 'Subtotal', value: convertToDollars(subtotal) },
    { label: 'IVA', value: convertToDollars(tax) },
    { label: 'Discount', value: convertToDollars(price) },
    {
      label: 'Effective transfer amount',
      value: convertToDollars(effectiveTransferAmount)
    }
  ];

  return (
    <TableContainer>
      <Table size="small" aria-label="cost details table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" scope="row" className={classes.label}>
                {t(row.label)}
              </TableCell>
              <TableCell align="right">${row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CostDetails;
