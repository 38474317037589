import React from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';

import {
  END_DATE_VALIDATION_MESSAGE,
  REQUIRED_FIELD_TEXT
} from '../../config/constants';
import useStyles from '../forms/styles';
import DateInput from '../forms/date-input-employee';
import SubmitButton from '../forms/submit-button';

const VALIDATION_SCHEMA = yup.object({
  startDate: yup.date().required(REQUIRED_FIELD_TEXT),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), END_DATE_VALIDATION_MESSAGE)
    .required(REQUIRED_FIELD_TEXT)
});

export interface FormProps {
  startDate: string;
  endDate: string;
}

interface Props {
  onSubmit: (values: FormProps) => void;
  loading?: boolean;
}

const QueryAdvancesFilterForm: React.FunctionComponent<Props> = ({
  onSubmit,
  loading
}: Props) => {
  const classes = useStyles();

  const initialValues = {
    startDate: new Date(Date.now()).toISOString(),
    endDate: new Date(Date.now()).toISOString()
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }): void => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit }): React.ReactNode => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item xs={9} sm={5} md={3}>
                <Grid
                  container
                  spacing={1}
                  className={classes.formContainer}
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item xs>
                    <Field
                      name="startDate"
                      label="Start date"
                      component={DateInput}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="endDate"
                      label="End date"
                      component={DateInput}
                    />
                  </Grid>
                  <Grid item xs>
                    <SubmitButton isLoading={loading} buttonText="Search" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default QueryAdvancesFilterForm;
